import { useSelector } from 'react-redux';
import EditPricingDetails from '../../EditPricingDetails';
import { RootState } from '../../../../../redux/rootReducer';

export const DealPricingConversionDetails = () => {
    const viewDealDetails = useSelector((state: RootState) => state.auth.dealViewPricingDetails);
    const dealID = useSelector((state: RootState) => state.auth.dealId);

    return <EditPricingDetails mode="dealView" viewDealDetails={viewDealDetails} dealId={dealID} />;
};
