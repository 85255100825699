import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    FETCH_PARTNER_CURRENCY_PREFERENCES,
    UPDATE_PARTNER_TARGET_CURRENCY_PREFERENCES,
    UPDATE_PARTNER_CURRENCY_CONVERSION_RATE,
    FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES,
    UPDATE_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE,
    FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE,
    FETCH_PARTNER_DETAILS_BY_ID,
} from '../../../../queries/PartnerQueries';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import jwtDecode from 'jwt-decode';
import Loader from '../../../../utils/loader';
import NormalLoader from '../../../../utils/normalLoader';
import { Buttons } from '../../../ui/atoms/Button';
import Toast from '../../../ui/atoms/Toast';
import UnsaveChangesWarning from '../../../templates/modals/UnsaveChangesWarning';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import SuccessMessageModal from '../../../templates/modals/SuccessMessageModal';
import DataTableAlter from '../../../ui/organisms/DataTableAlter';
import SearchBar from '../../../ui/molecules/SearchBar';
import EditableCell from '../../../ui/molecules/EditableCell';
import ConfirmationModalWithMessage from '../../../templates/modals/ConfirmationModalWithMessage';
import {
    GLOBAL_CURRENCY_NOT_SET_PARTNER_LEVEL_ERROR,
    LOCAL_CURRENCY_CONVERSION_RATE_FETCH_ERROR_MESSAGE,
    LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_ERROR_MESSAGE,
    LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_SUCCESS_MESSAGE,
    PARTNER_ACTIVATED_SUCCESSFULLY,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
    PARTNER_ADD_SUCCESS_BODY,
    SUCCESS_TITLE,
    PARTNER_CURRENCY_ADD_PATH,
    PARTNER_CURRENCY_PREFERENCE_EDIT_CANCEL,
    PARTNER_EDIT_SUCCESS,
    PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_TITLE,
} from '../../../../constants/partner';
import {
    CONFIRMATION_DELETE_CONVERSION_RATE_MESSAGE,
    CONFIRMATION_DELETE_CONVERSION_RATE_TITLE,
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE,
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE,
    CURRENCY_CONVERSION_RESET_FAILED,
    CURRENCY_CONVERSION_RESET_SUCCESS,
    CONFIRMATION_UPDATE_PARTNER_PREFERENCE_MESSAGE,
    CONFIRMATION_UPDATE_PARTNER_PREFERENCE_TITLE,
} from '../../../../constants/currency';
import { currencyAndCountryCodes } from '../../../../utils/currencies';
import PartnerCurrenctPreferenceDeleteConfirmationModal from '../../../templates/modals/ConfirmationModalWithTable';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import { ERROR } from '../../../../constants/supplier';
import ResetCurrencyRateConfirmationModal from '../../../templates/modals/ResetCurrencyRateConfirmationModal';
import { RESET_OVERRIDE_CONVERSION_RATE } from '../../../../queries/CurrencyConversionQueries';
import {
    FETCH_ADMIN_ONE_PROFILE_ID,
    UPDATE_ADMIN_PROFILE,
} from '../../../../queries/AdminProfileQueries';
import { getCookie } from '../../../../utils/cookiesService';
import GlobalCurrencyUpdateModal from '../../../templates/modals/GlobalCurrencyUpdateModal';

export default function AddPartnerCurrencyPreferences() {
    const history = useHistory();

    const partnerId = localStorage.getItem('partnerId');
    const partnerUUID = localStorage.getItem('partnerUUID');
    const selectedPartner = useSelector((state: RootState) => state.auth.partner);

    const [targetCurrencies, setTargetCurrencies] = useState([]);
    const [selectedTargetCurrencies, setSelectedTargetCurrencies] = useState([]);
    const [initiallySelectedTargetCurrencies, setInitiallySelectedTargetCurrencies] = useState([]);
    const [partnerCurrencyPreferences, setPartnerCurrencyPreferences] = useState([]);
    const [searchedPartnerCurrencyPreferences, setSearchedPartnerCurrencyPreferences] = useState(
        [],
    );

    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);

    const [showToast, setShowToast] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState(false);
    const [showAddNewPartnerSuccess, setShowAddNewPartnerSuccess] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isPartnerCurrencyError, setIsPartnerCurrencyError] = useState(false);
    const [isLocalCurrencyError, setIsLocalCurrencyError] = useState(false);
    const [selectedGlobalCurrency, setSelectedGlobalCurrency] = useState(null);
    const [globalCurrencyUpdateModal, setGlobalCurrencyUpdateModal] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [updatedRowId, setUpdatedRowId] = useState('');
    const [updatedConversionRate, setUpdatedConverisonRate] = useState('');
    const [deletedRowId, setDeletedRowId] = useState('');
    const [deletingPartnerCurrencyPreferences, setDeletingPartnerCurrencyPreferences] = useState(
        [],
    );
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState<boolean>(false);
    const [resetIndex, setResetIndex] = useState<number>(null);
    const [warningModalBody, setWarningModalBody] = useState<string>(null);
    const [warningModalTitle, setWarningModalTitle] = useState<string>(null);

    const [isLocalCurrencyFormDirty, setIsLocalCurrencyFormDirty] = useState(false);
    const [fetchedLocalCurrencyRate, setFetchedLocalCurrencyRate] = useState({
        localCurrency: null,
        conversionRate: null,
    });
    const [localCurrencyFormValues, setLocalCurrencyFormValues] = useState({
        localCurrency: undefined,
        conversionRate: undefined,
    });

    const [partnerData, setPartnerData] = useState(null);

    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwtDecode(token) : null;

    const { data: loggedInUserData } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
        },
    });

    useQuery(FETCH_PARTNER_DETAILS_BY_ID, {
        variables: { partnerId: selectedPartner?.partner?.id || partnerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setPartnerData(data?.partner);
        },
    });

    useEffect(() => {
        if (processFinishedClicked && window.location.pathname != PARTNER_CURRENCY_ADD_PATH) {
            history.push('/partners');
        }
    }, [history, processFinishedClicked]);

    const [
        fetchPartnerTargetCurrencyPreferences,
        { loading: partnerSelectedTargetCurrenciesLoading },
    ] = useLazyQuery(FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES, {
        variables: { partnerId: selectedPartner?.partner?.id || partnerId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        context: { clientName: ClientsEnum.STORE },
        onCompleted(data) {
            const selectedTargetCurrencyKeyValuePairs = data?.partnerTargetCurrencyPreferences?.map(
                (pcp, index) => ({
                    index: index,
                    key: pcp,
                }),
            );
            setInitiallySelectedTargetCurrencies(selectedTargetCurrencyKeyValuePairs);
            setSelectedTargetCurrencies(selectedTargetCurrencyKeyValuePairs);
        },
        onError() {
            setShowToast(true);
            setIsError(true);
            setMessage('Something went wrong while fetching partner target currency preferences');
        },
    });

    useEffect(() => {
        fetchPartnerTargetCurrencyPreferences();
    }, [fetchPartnerTargetCurrencyPreferences]);

    const [fetchPartnerCurrencyPreferences, { loading: partnerCurrencyPreferencesLoading }] =
        useLazyQuery(FETCH_PARTNER_CURRENCY_PREFERENCES, {
            variables: { partnerId: selectedPartner?.partner?.id || partnerId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            context: { clientName: ClientsEnum.STORE },
            onCompleted(data) {
                const sortedPartnerCurrencyPreferences = data?.partnerCurrencyPreferences
                    ?.filter((pcp) => pcp.sourceCurrency)
                    .sort(function (a, b) {
                        const x1 = a.sourceCurrency.toLowerCase();
                        const y1 = b.sourceCurrency.toLowerCase();
                        const x2 = a.targetCurrency.toLowerCase();
                        const y2 = b.targetCurrency.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        } else if (x1 > y1) {
                            return 1;
                        } else {
                            return x2 < y2 ? -1 : 1;
                        }
                    });
                setPartnerCurrencyPreferences(sortedPartnerCurrencyPreferences);
                setSearchedPartnerCurrencyPreferences(sortedPartnerCurrencyPreferences);
                setTargetCurrencies(
                    currencyAndCountryCodes
                        .map((ccc, index) => ({
                            index: index,
                            key: ccc.currency,
                            icon: <i className={`${ccc.country?.toLowerCase()}`} />,
                        }))
                        .sort(function (a, b) {
                            if (a.key < b.key) return -1;
                            else return 1;
                        }),
                );
            },
            onError() {
                setShowToast(true);
                setIsError(true);
                setMessage(
                    'Something went wrong while fetching overrided partner currency conversion rates',
                );
            },
        });

    const [updatePartner, { loading: updatePartnerLoading }] = useMutation(UPDATE_ADMIN_PROFILE, {
        onCompleted() {
            if (window.location.pathname == PARTNER_CURRENCY_ADD_PATH) {
                setShowToast(true);
                setMessage(PARTNER_ACTIVATED_SUCCESSFULLY);
                setTimeout(() => {
                    // history.push('/partner/agreement-details-add');
                    history.push('/partner/payment-channel/marked-up-percentage-add');
                    setShowToast(false);
                }, 2000);
            } else {
                setShowAddNewPartnerSuccess(true);
            }
        },
        onError(err) {
            setShowToast(true);
            setIsError(true);
            setMessage('Something went wrong while updating partner');
            console.log(err);
        },
    });

    useEffect(() => {
        fetchPartnerCurrencyPreferences();
    }, [fetchPartnerCurrencyPreferences]);

    const searchSuggestions = [
        ...new Set([
            ...partnerCurrencyPreferences?.map((currency) => currency?.sourceCurrency),
            ...partnerCurrencyPreferences?.map((currency) => currency?.targetCurrency),
        ]),
    ];

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };
    useEffect(() => {
        if (!searchTerm || searchTerm?.length === 0) {
            setSearchedPartnerCurrencyPreferences(partnerCurrencyPreferences);
            setSearchClicked(false);
        } else {
            const arr = partnerCurrencyPreferences?.filter(
                (currency) =>
                    currency.targetCurrency.includes(searchTerm) ||
                    currency.sourceCurrency.includes(searchTerm) ||
                    currency.sourceCurrency.toUpperCase().includes(searchTerm.toUpperCase()),
            );
            setSearchedPartnerCurrencyPreferences(arr);
            setSearchClicked(true);
        }
    }, [partnerCurrencyPreferences, searchTerm]);

    const [
        updatePartnerTargetCurrencyPreferences,
        { loading: partnerTargetCurrencyPreferencesUpdateMutationLoading },
    ] = useMutation(UPDATE_PARTNER_TARGET_CURRENCY_PREFERENCES, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [
            FETCH_PARTNER_CURRENCY_PREFERENCES,
            FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES,
        ],
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true,
        onCompleted(res) {
            if (
                res?.savePartnerCurrencyPreferences?.every((val) => !val.sourceCurrency) &&
                res?.savePartnerCurrencyPreferences?.length !== 0
            ) {
                setShowToast(true);
                setIsError(true);
                setMessage(
                    'Partner target currency preferences updated successfully. But there are no items for the partner to map with target currencies',
                );
            } else {
                setShowToast(true);
                setIsError(false);
                setMessage('Partner target currency preferences saved successfully.');
            }
            setIsDirty(false);
            setShowUpdateConfirmationModal(false);
        },
        onError() {
            setShowToast(true);
            setIsError(true);
            setMessage('Something went wrong while updating partner target currency preferences');
        },
    });

    const handleWarningOkayClick = () => {
        fetchPartnerCurrencyPreferences();
    };

    const validateGlobalCurrency = useCallback(
        (rowId): boolean => {
            if (searchedPartnerCurrencyPreferences?.length > 0) {
                const existingPreference = searchedPartnerCurrencyPreferences?.find(
                    (preference) => preference?.id === rowId,
                );

                if (
                    !existingPreference?.conversionRate ||
                    existingPreference?.conversionRate === undefined ||
                    existingPreference?.conversionRate === null
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        [searchedPartnerCurrencyPreferences],
    );

    const updateConversionRate = useCallback(
        (id, value) => {
            if (validateGlobalCurrency(id)) {
                setUpdatedRowId(id);
                setUpdatedConverisonRate(value);
                setShowConfirmationModal(true);
            } else {
                setWarningModalBody(GLOBAL_CURRENCY_NOT_SET_PARTNER_LEVEL_ERROR);
                setWarningModalTitle(ERROR);
                setShowWarningModal(true);
            }
        },
        [validateGlobalCurrency],
    );

    const handleUpdateCurrencyConversionRate = () => {
        updatePartnerCurrencyConversionRate({
            variables: {
                partnerId: selectedPartner?.partner?.id || partnerId,
                currencyPreference: {
                    id: updatedRowId,
                    conversionRate: updatedConversionRate,
                },
            },
        });
        setUpdatedRowId('');
        setUpdatedConverisonRate('');
        setShowConfirmationModal(false);
    };

    const [
        updatePartnerCurrencyConversionRate,
        { loading: partnerCurrencyConversionRateUpdateMutationLoading },
    ] = useMutation(UPDATE_PARTNER_CURRENCY_CONVERSION_RATE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'no-cache',
        refetchQueries: [FETCH_PARTNER_CURRENCY_PREFERENCES],
        awaitRefetchQueries: true,
        onCompleted: () => {
            setMessage(`Currency conversion rate for partner successfully overrided`);
            setShowToast(true);
        },
        onError: () => {
            setMessage(
                `Something went wrong when updating the partner overrided currency conversion rate`,
            );
            setIsError(true);
            setShowToast(true);
        },
    });

    const isCurrencyOverridden = (updatedList) => {
        if (updatedList.length == initiallySelectedTargetCurrencies.length) {
            let counter = 0;
            initiallySelectedTargetCurrencies.forEach(
                (initiallySelectedTargetCurrency) =>
                    updatedList.filter(
                        (updatedItem) => updatedItem.key == initiallySelectedTargetCurrency.key,
                    ).length == 1 && counter++,
            );

            if (counter == initiallySelectedTargetCurrencies.length) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const [resetOverride, { loading: resetOverrideLoading, error: resetOverrideError }] =
        useMutation(RESET_OVERRIDE_CONVERSION_RATE, {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            refetchQueries: [FETCH_PARTNER_CURRENCY_PREFERENCES],
            onCompleted: () => {
                setShowToast(true);
                setMessage(CURRENCY_CONVERSION_RESET_SUCCESS + 'Partner ID ' + partnerUUID);
            },
            onError: () => {
                setShowToast(true);
                setIsError(true);
                setMessage(CURRENCY_CONVERSION_RESET_FAILED + resetOverrideError);
            },
        });

    const resetCurrencyConversion = useCallback(
        (index) => {
            setShowResetConfirmationModal(true);
            setResetIndex(index);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [partnerCurrencyPreferences],
    );

    const handleResetSubmit = () => {
        const overridedRecord = searchedPartnerCurrencyPreferences[resetIndex];

        resetOverride({
            variables: {
                partnerId: partnerUUID,
                conversion: {
                    sourceCurrency: overridedRecord?.sourceCurrency,
                    targetCurrency: overridedRecord?.targetCurrency,
                    resetLevelItem: false,
                },
            },
        });
        setShowResetConfirmationModal(false);
    };

    const handleSavePartnerPreferenceSubmit = () => {
        if (!fetchedLocalCurrencyRate.conversionRate || !fetchedLocalCurrencyRate.localCurrency) {
            setIsLocalCurrencyError(true);
        } else if (
            !initiallySelectedTargetCurrencies ||
            initiallySelectedTargetCurrencies.length == 0
        ) {
            setIsPartnerCurrencyError(true);
        } else {
            updatePartner({
                variables: {
                    userId: partnerData?.users[0]?.userId,
                    userName: partnerData?.users[0]?.userName,
                    user: {
                        firstName: loggedInUserData?.user?.firstName,
                        lastName: loggedInUserData?.user?.lastName,
                        contact: {
                            primaryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                            recoveryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                            countryCode: partnerData?.users[0]?.contact?.countryCode,
                            phoneNumber: partnerData?.users[0]?.contact?.phoneNumber,
                        },
                        partner: {
                            partnerId:
                                partnerData?.partnerId || selectedPartner?.partner?.partnerId,
                            partnerName: partnerData?.partnerName,
                            partnerUrl: partnerData?.partnerUrl,
                            creditLimit: partnerData?.creditLimit,
                            createdBy: loggedInUserData?.user?.userId,
                            modifiedBy: 0,
                            isActive: partnerData?.isActive,
                            partnerAgreements: partnerData?.partnerAgreements?.map((agreement) => ({
                                id: agreement?.id,
                                url: agreement?.url,
                                isActive: agreement?.isActive,
                                fileName: agreement?.fileName,
                                fileSize: agreement?.fileSize,
                                fileType: agreement?.fileType,
                            })),
                            // markedUpPercentageForCurrency:
                            //     partnerData?.markedUpPercentageForCurrency,
                            // markedUpPercentage: partnerData?.markedUpPercentage,
                        },
                    },
                },
            });
        }
    };

    const [
        updatePartnerLocalCurrencyConversionRate,
        { loading: updatePartnerLocalCurrencyConversionRateLoading },
    ] = useMutation(UPDATE_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE],
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true,
        onCompleted: () => {
            setShowToast(true);
            setIsError(false);
            setMessage(LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_SUCCESS_MESSAGE);
            setIsLocalCurrencyError(false);
            // If partner was inactive before, now we can set it as active
            if (!partnerData?.isActive) {
                setPartnerData({ ...partnerData, isActive: true });
            }
        },
        onError() {
            setShowToast(true);
            setIsError(true);
            setMessage(LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_ERROR_MESSAGE);
        },
    });

    const { loading: fetchPartnerLocalCurrencyConversionRateLoading } = useQuery(
        FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE,
        {
            variables: { partnerId: selectedPartner?.partner?.id || partnerId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            context: { clientName: ClientsEnum.STORE },
            onCompleted(data) {
                const result = data.partnerLocalCurrencyConversionRate[0];
                if (result) {
                    setFetchedLocalCurrencyRate({
                        localCurrency: result.localCurrency,
                        conversionRate: result.conversionRate,
                    });

                    // set initial form values
                    if (localCurrencyFormValues.localCurrency === undefined) {
                        setLocalCurrencyFormValues({
                            localCurrency: result.localCurrency,
                            conversionRate: result.conversionRate,
                        });
                    }
                }
            },
            onError() {
                setShowToast(true);
                setIsError(true);
                setMessage(LOCAL_CURRENCY_CONVERSION_RATE_FETCH_ERROR_MESSAGE);
            },
        },
    );

    const handleLocalCurrencyFormChange = (event: React.FormEvent<HTMLInputElement>) => {
        const fieldName = event.currentTarget.name;
        if (fieldName === 'localCurrency') {
            const value = event.currentTarget.value;
            setLocalCurrencyFormValues({ ...localCurrencyFormValues, [fieldName]: value });
            setIsLocalCurrencyFormDirty(true);
        } else if (fieldName === 'conversionRate') {
            setLocalCurrencyFormValues({
                ...localCurrencyFormValues,
                [fieldName]: event.currentTarget.value,
            });
            setIsLocalCurrencyFormDirty(true);
            setIsLocalCurrencyError(false);
        }
    };

    /** If local currency conversion rate is valid, returns true */
    const validateLocalCurrencyConversionRate = () => {
        // check for falsy values
        if (!localCurrencyFormValues.conversionRate) {
            return false;
        }

        // check for non-numerical values
        if (isNaN(localCurrencyFormValues.conversionRate)) {
            return false;
        }

        // check for 0 or negative values
        const numberValue = parseFloat(localCurrencyFormValues.conversionRate);
        if (numberValue <= 0) {
            return false;
        }

        return true;
    };

    const handleLocalCurrencyFormSubmit = () => {
        if (!validateLocalCurrencyConversionRate()) {
            setIsLocalCurrencyError(true);
            return;
        }

        updatePartnerLocalCurrencyConversionRate({
            variables: {
                partnerId: selectedPartner?.partner?.id || partnerId,
                localCurrencyConversion: {
                    localCurrency: localCurrencyFormValues.localCurrency,
                    conversionRate: localCurrencyFormValues.conversionRate,
                },
            },
        });
        setIsLocalCurrencyFormDirty(false);
        setIsLocalCurrencyError(false);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function editConversionRate({ row, value }) {
                    return (
                        <div key={row?.original?.id} className="flex gap-3">
                            <EditableCell
                                id={`${row?.original?.sourceCurrency}-to-${row?.original?.targetCurrency}-convertion-rate`}
                                initialValue={value}
                                row={row}
                                updateValue={updateConversionRate}
                                key={row?.original?.id}
                                isDisabled={
                                    isDirty ||
                                    row?.original?.sourceCurrency === row?.original?.targetCurrency
                                }
                                isOverride={row?.original?.overridePartner}
                                setSelectedGlobalCurrency={setSelectedGlobalCurrency}
                            />
                            {row?.original?.overridePartner == true && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="currency_conversion_reset"
                                        onClick={() => resetCurrencyConversion(row?.index)}
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
        ],
        [isDirty, resetCurrencyConversion, updateConversionRate],
    );

    const modalColumns = React.useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
        ],
        [],
    );

    if (partnerCurrencyPreferencesLoading || partnerSelectedTargetCurrenciesLoading)
        return <Loader />;

    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={isError}
                    width="w-10/12"
                    margin="-mt-10 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <Formik
                initialValues={{
                    selectedTargetCurrencies: selectedTargetCurrencies,
                    newPartnerCurrencyPreferences: '',
                }}
                enableReinitialize
                onSubmit={({ selectedTargetCurrencies, newPartnerCurrencyPreferences }) => {
                    const updatedCurrencies = selectedTargetCurrencies.map((stc) => stc.key);
                    if (newPartnerCurrencyPreferences) {
                        updatedCurrencies.push(
                            newPartnerCurrencyPreferences.toUpperCase().replace(/ +/g, '').trim(),
                        );
                    }
                    updatePartnerTargetCurrencyPreferences({
                        variables: {
                            partnerId: selectedPartner?.partner?.id || partnerId,
                            currencies: updatedCurrencies,
                        },
                    });

                    // If only 1 target currency is there in the list,
                    // set it as the local currency
                    if (updatedCurrencies.length === 1) {
                        setLocalCurrencyFormValues({
                            ...localCurrencyFormValues,
                            localCurrency: updatedCurrencies[0],
                        });
                        setIsLocalCurrencyError(true);
                        setIsLocalCurrencyFormDirty(true);
                    }

                    setIsDirty(false);
                    setShowDeleteConfirmationModal(false);
                    setDeletingPartnerCurrencyPreferences([]);
                }}
            >
                {({ handleSubmit }) => (
                    <>
                        <ConfirmationModalWithMessage
                            showConfirmationModal={showUpdateConfirmationModal}
                            setShowConfirmationModal={setShowUpdateConfirmationModal}
                            message={CONFIRMATION_UPDATE_PARTNER_PREFERENCE_MESSAGE}
                            messageTitle={CONFIRMATION_UPDATE_PARTNER_PREFERENCE_TITLE}
                            setYesBtnClick={() => handleSubmit()}
                            setNoBtnClick={() => setShowUpdateConfirmationModal(false)}
                            isProcessing={partnerTargetCurrencyPreferencesUpdateMutationLoading}
                        />
                        <div className="flex flex-col  justify-evens px-4">
                            <div className="grid grid-cols-4">
                                <div className="col-start-1 col-end-4">
                                    <div className="text-lg font-poppins font-bold pt-0 px-8">
                                        Currency Preferences
                                    </div>
                                    <div className="flex order-1 flex-wrap flex-col relative mb-2 mt-3 px-8 w-full">
                                        <div className="text-gray-500 w-44 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                            Select Target Currencies
                                        </div>
                                        <div className="z-20">
                                            <Multiselect
                                                displayValue="key"
                                                style={{
                                                    chips: {
                                                        background: '#c084fc',
                                                    },
                                                    multiselectContainer: {
                                                        color: 'black',
                                                    },
                                                    searchBox: {
                                                        border: '1px solid black',
                                                        borderRadius: '10px',
                                                        padding: '12px',
                                                    },
                                                }}
                                                selectedValues={selectedTargetCurrencies}
                                                options={targetCurrencies}
                                                avoidHighlightFirstOption={true}
                                                onSelect={(selectedList) => {
                                                    if (
                                                        selectedList.length > 0 &&
                                                        isPartnerCurrencyError
                                                    ) {
                                                        setIsPartnerCurrencyError(false);
                                                    }
                                                    setSelectedTargetCurrencies(selectedList);
                                                    setDeletingPartnerCurrencyPreferences(
                                                        partnerCurrencyPreferences.filter(
                                                            (x) =>
                                                                !selectedList
                                                                    .map((x) => x.key)
                                                                    .includes(x.targetCurrency),
                                                        ),
                                                    );
                                                    if (isCurrencyOverridden(selectedList)) {
                                                        setIsDirty(true);
                                                    } else {
                                                        setIsDirty(false);
                                                    }
                                                }}
                                                onRemove={(selectedList) => {
                                                    if (selectedList.length == 0) {
                                                        setIsPartnerCurrencyError(true);
                                                    }
                                                    setSelectedTargetCurrencies(selectedList);
                                                    setDeletingPartnerCurrencyPreferences(
                                                        partnerCurrencyPreferences.filter(
                                                            (x) =>
                                                                !selectedList
                                                                    .map((x) => x.key)
                                                                    .includes(x.targetCurrency),
                                                        ),
                                                    );
                                                    if (isCurrencyOverridden(selectedList)) {
                                                        setIsDirty(true);
                                                    } else {
                                                        setIsDirty(false);
                                                    }

                                                    // If the currently selected local currency is removed
                                                    // update the conversion equation accordingly
                                                    const isCurrentLocalCurrencyRemoved =
                                                        !selectedList
                                                            .map((x) => x.key)
                                                            .includes(
                                                                localCurrencyFormValues.localCurrency,
                                                            );
                                                    if (isCurrentLocalCurrencyRemoved) {
                                                        setLocalCurrencyFormValues({
                                                            ...localCurrencyFormValues,
                                                            localCurrency: null,
                                                        });
                                                        setIsLocalCurrencyError(true);
                                                    }
                                                }}
                                                showCheckbox
                                            />
                                        </div>
                                    </div>
                                    {isPartnerCurrencyError && (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm my-3 ml-8">
                                            Partner must have at least one currency preference
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={`flex flex-row py-3 w-full justify-begin z-50 pt-4 px-12`}>
                            <Buttons
                                name={
                                    partnerTargetCurrencyPreferencesUpdateMutationLoading ? (
                                        <NormalLoader />
                                    ) : (
                                        'Update'
                                    )
                                }
                                type="submit"
                                buttonType="primary"
                                id="updateTargetCurrencies"
                                size="e-small"
                                disabled={!isDirty || isPartnerCurrencyError}
                                onclick={() => {
                                    if (deletingPartnerCurrencyPreferences.length > 0) {
                                        setShowDeleteConfirmationModal(true);
                                    } else {
                                        if (window.location.pathname == PARTNER_CURRENCY_ADD_PATH) {
                                            handleSubmit();
                                        } else {
                                            setShowUpdateConfirmationModal(true);
                                        }
                                    }
                                }}
                            />
                        </div>
                        <PartnerCurrenctPreferenceDeleteConfirmationModal
                            showConfirmationModal={showDeleteConfirmationModal}
                            setShowConfirmationModal={setShowDeleteConfirmationModal}
                            messageTitle={'Update target currency preferences?'}
                            message={
                                'This action causes the following currency conversion rates to be removed'
                            }
                            setYesBtnClick={() => handleSubmit()}
                            setNoBtnClick={() => {
                                setShowDeleteConfirmationModal(false);
                            }}
                            columns={modalColumns}
                            data={deletingPartnerCurrencyPreferences}
                            isProcessing={partnerTargetCurrencyPreferencesUpdateMutationLoading}
                        />
                    </>
                )}
            </Formik>

            <div className="flex flex-col justify-evens px-4">
                {fetchPartnerLocalCurrencyConversionRateLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="flex py-3 px-8">
                            <div>Select local currency for the partner</div>
                        </div>
                        <div className="max-w-max pr-4">
                            {selectedTargetCurrencies.map((currency, index) => (
                                <div key={currency.key} className="flex px-8">
                                    <div>
                                        <input
                                            id={`local-currency-option-${index + 1}`}
                                            type="radio"
                                            name="localCurrency"
                                            value={currency.key}
                                            onChange={handleLocalCurrencyFormChange}
                                            checked={
                                                localCurrencyFormValues.localCurrency ===
                                                currency.key
                                            }
                                            disabled={isDirty}
                                        />
                                        <i
                                            className={`${currencyAndCountryCodes
                                                .find((x) => x.currency === currency.key)
                                                ?.country.toLowerCase()} flag px-5 pt-1`}
                                        ></i>
                                        <span className="ml-2">{currency.key}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex pt-4 px-8 items-baseline">
                            <span>{localCurrencyFormValues.localCurrency} 1 = </span>
                            <input
                                id={`local-currency-to-points-conversion-rate`}
                                name="conversionRate"
                                type="text"
                                className="p-2 border-2 focus:border-purple-400 outline-none rounded-lg mx-1 text-right"
                                size={3}
                                onChange={handleLocalCurrencyFormChange}
                                value={localCurrencyFormValues.conversionRate}
                                disabled={isDirty}
                            />
                            <span>PTS</span>
                        </div>
                        {isLocalCurrencyError && (
                            <div className="text-red-500 text-xs mt-1 md:text-sm my-3 ml-8">
                                Partner must select a local currency and set the conversion rate
                            </div>
                        )}
                        <div className="flex pt-4 px-8 w-full">
                            <Buttons
                                name={
                                    updatePartnerLocalCurrencyConversionRateLoading ? (
                                        <NormalLoader />
                                    ) : (
                                        'Update'
                                    )
                                }
                                id="updateLocalCurrency"
                                buttonType="primary"
                                disabled={!isLocalCurrencyFormDirty || isDirty}
                                size="e-small"
                                onclick={handleLocalCurrencyFormSubmit}
                            />
                        </div>
                    </>
                )}
            </div>

            <div
                className={`${
                    window.location.pathname != PARTNER_CURRENCY_ADD_PATH &&
                    'border-2 border-gray-300'
                } rounded-lg mr-4 ml-8 mb-20 mt-10`}
            >
                {window.location.pathname != PARTNER_CURRENCY_ADD_PATH && (
                    <>
                        <div className="flex p-4">
                            <div className="flex w-full">
                                <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                                    <SearchBar
                                        id="target-currency-search"
                                        options={searchSuggestions}
                                        handleChange={(e, newVal, reason) => {
                                            handleChange(e, newVal, reason);
                                        }}
                                        placeHolder={'Search by currency'}
                                    />
                                </div>
                            </div>
                        </div>
                        {partnerCurrencyPreferencesLoading ? (
                            <div className="w-full">
                                <div className="m-auto flex flex-col ">
                                    <div className="m-auto p-10">
                                        <Loader />
                                    </div>
                                    <div className="p-10 bg-purple-100"></div>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full">
                                <>
                                    {searchedPartnerCurrencyPreferences?.length ? (
                                        <DataTableAlter
                                            columns={columns}
                                            data={searchedPartnerCurrencyPreferences}
                                            hidePagination={true}
                                            currentPage={currentPage}
                                            pageSizes={pageSize}
                                            setCurrentPage={setCurrentPage}
                                            setDefaultPageSize={setPageSize}
                                        />
                                    ) : searchClicked &&
                                      !searchedPartnerCurrencyPreferences?.length ? (
                                        <div className="m-auto flex flex-col ">
                                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                                {columns?.map((column, i) => {
                                                    return (
                                                        <div
                                                            className="text-black font-poppins font-semibold flex ml-12"
                                                            key={i}
                                                        >
                                                            {column?.Header}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="m-auto p-10 flex">
                                                Your search
                                                <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                                did not match any results
                                            </div>
                                            <div className="p-10 bg-purple-100"></div>
                                        </div>
                                    ) : (
                                        <div className="m-auto flex flex-col ">
                                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                {columns?.map((column, i) => {
                                                    return (
                                                        <div
                                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                            key={i}
                                                        >
                                                            {column?.Header}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="m-auto p-10">
                                                {selectedTargetCurrencies?.length
                                                    ? 'There are no applicable currency conversion rates, as the partner has no products that would cause the source currencies to be missing'
                                                    : 'No data found.'}
                                            </div>
                                            <div className="p-10 bg-purple-100"></div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                    </>
                )}
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                    <Buttons
                        name={
                            partnerCurrencyConversionRateUpdateMutationLoading ||
                            resetOverrideLoading ||
                            updatePartnerLoading ? (
                                <NormalLoader />
                            ) : (
                                'Save'
                            )
                        }
                        type="button"
                        buttonType="primary"
                        id="save-button"
                        size="e-small"
                        disabled={
                            isDirty ||
                            isPartnerCurrencyError ||
                            isLocalCurrencyError ||
                            selectedTargetCurrencies.length == 0
                        }
                        onclick={handleSavePartnerPreferenceSubmit}
                    />
                </div>
                <UnsaveChangesWarning
                    contentText={PARTNER_CURRENCY_PREFERENCE_EDIT_CANCEL}
                    messageTitle={PARTNER_ADD_EXIT_CONFIRM_TITLE}
                    when={isDirty || window.onbeforeunload ? true : false}
                    navigate={(path) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (isDirty) {
                            return true;
                        }
                        return false;
                    }}
                    displayIcon={true}
                    displayIconName={'clarity_error-standard-line-svg.svg'}
                />
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    warningModalBody={warningModalBody}
                    warningModalTitle={warningModalTitle}
                    setProcessFinishedClicked={handleWarningOkayClick}
                    isGlobalCurrencyUpdateEnabled={true}
                    setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                />
                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    messageTitle={
                        deletedRowId
                            ? CONFIRMATION_DELETE_CONVERSION_RATE_TITLE
                            : CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE
                    }
                    message={
                        deletedRowId
                            ? CONFIRMATION_DELETE_CONVERSION_RATE_MESSAGE
                            : CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE
                    }
                    setYesBtnClick={() => handleUpdateCurrencyConversionRate()}
                    setNoBtnClick={() => {
                        if (deletedRowId) {
                            setDeletedRowId('');
                            setUpdatedConverisonRate('');
                        } else {
                            setUpdatedRowId('');
                            setUpdatedConverisonRate('');
                        }
                        setShowConfirmationModal(false);
                    }}
                    isProcessing={partnerCurrencyConversionRateUpdateMutationLoading}
                />
                <ResetCurrencyRateConfirmationModal
                    setShowConfirmationModal={setShowResetConfirmationModal}
                    showConfirmationModal={showResetConfirmationModal}
                    handleResetSubmit={handleResetSubmit}
                    overrideItem={false}
                />
                <SuccessMessageModal
                    showSuccessModal={showAddNewPartnerSuccess}
                    setShowSuccessModal={setShowAddNewPartnerSuccess}
                    successModalTitle={SUCCESS_TITLE}
                    successModalBody={
                        window.location.pathname == PARTNER_CURRENCY_ADD_PATH
                            ? PARTNER_ADD_SUCCESS_BODY
                            : PARTNER_EDIT_SUCCESS
                    }
                    setProcessFinishedClicked={setProcessFinishedClicked}
                />
                <GlobalCurrencyUpdateModal
                    messageTitle={'Global Currency Conversion'}
                    globalCurrencyUpdateModal={globalCurrencyUpdateModal}
                    setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                    data={selectedGlobalCurrency}
                    callAfterProcessFinished={() => {
                        setShowWarningModal(false);
                        setGlobalCurrencyUpdateModal(false);
                        handleWarningOkayClick();
                    }}
                />
                {window.location.pathname == PARTNER_CURRENCY_ADD_PATH && (
                    <UnsaveChangesWarning
                        contentText={PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_MESSAGE}
                        messageTitle={PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_TITLE}
                        when={
                            isDirty ||
                            isPartnerCurrencyError ||
                            isLocalCurrencyError ||
                            selectedTargetCurrencies.length == 0 ||
                            window.onbeforeunload
                                ? true
                                : false
                        }
                        navigate={(path) => history.push(path)}
                        shouldBlockNavigation={() => {
                            if (
                                isDirty ||
                                isPartnerCurrencyError ||
                                isLocalCurrencyError ||
                                selectedTargetCurrencies.length == 0
                            ) {
                                return true;
                            }
                            return false;
                        }}
                        displayIcon={true}
                        displayIconName={'clarity_error-standard-line-svg.svg'}
                        onlyOkButton={true}
                    />
                )}
            </div>
        </>
    );
}
