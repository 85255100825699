import { useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useState } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_SUPPLIER_CONFIGURATIONS } from '../../../queries/SupplierQueries';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import NormalLoader from '../../../utils/normalLoader';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { API, CSV } from '../../../constants/supplier';
import CronJobSchedulerView from '../settings/scheduler/CronJobSchedulerView';
import { validateJobDetails } from '../../../helpers/JobSchedulerHelpers.helpers';
import {
    ADD_PAYMENT_CHANNEL,
    FETCH_DISTINCT_PAYMENT_METHODS,
    FETCH_PAYMENT_CHANNELS,
    GET_PAYMENT_CHANNEL_BY_ID,
} from '../../../queries/PaymentChannelQueries';
import {
    ERROR_ADD_PAYMENT_CHANNEL,
    POINTS,
    SUCCESS_ADD_PAYMENT_CHANNEL,
    SUCCESS_EDIT_PAYMENT_CHANNEL,
} from '../../../constants/paymentChannels';
import Toast from '../../ui/atoms/Toast';
import AddPaymentMethodModal from '../../templates/modals/AddPaymentMethodModal';
import { ActionType } from '../../../enums/PaymentChannel';
import { savePaymentChannelId, savePaymentChannelName } from '../../../redux/rootActions';
import { useDispatch } from 'react-redux';

interface PropType {
    /**
     * Mode of the form, determines if it is in Add, Edit, or View mode.
     * Value for this prop can be denoted from the enum by importing ActionType enum type in Parent component.
     * ex: ActionType.Edit
     */
    mode: ActionType;
}
export default function PaymentChannelFormInput(props: PropType) {
    const history = useHistory();
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [selectedSupplierType, setSelectedSupplierType] = useState<string>(CSV);
    const [newJobInfo, setNewJobInfo] = useState(null);
    const [schedulerEnabled, setSchedulerEnabled] = useState(false);
    const [schedulerInputInvalid, setSchedulerInputInvalid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<string>('PSP');
    const [showToast, setShowToast] = useState(false);
    const [descriptionLong, setDescriptionLong] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [uniqueNameError, setUniqueNameError] = useState(false);
    const [uniqueIdError, setUniqueIdError] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
    const parameters = useParams();
    const paymentChannelId = parameters['id'];
    const dispatch = useDispatch();

    useQuery(FETCH_SUPPLIER_CONFIGURATIONS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });

    useQuery(FETCH_DISTINCT_PAYMENT_METHODS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setPaymentMethods([...data?.distinctPaymentMethods, 'POINTS']);
        },
    });

    const { data: paymentChannelQueryData } = useQuery(GET_PAYMENT_CHANNEL_BY_ID, {
        variables: { paymentChannelId: paymentChannelId },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            dispatch(savePaymentChannelName(paymentChannelQueryData?.paymentChannel?.channelName));
            dispatch(savePaymentChannelId(paymentChannelQueryData?.paymentChannel?.id));
            setPaymentMethod(paymentChannelQueryData?.paymentChannel?.paymentMethod);
        },
    });

    const [savePaymentChannel, { loading: queryLoading }] = useMutation(ADD_PAYMENT_CHANNEL, {
        refetchQueries: [FETCH_PAYMENT_CHANNELS],
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setMessage(
                props.mode == ActionType.Add
                    ? SUCCESS_ADD_PAYMENT_CHANNEL
                    : SUCCESS_EDIT_PAYMENT_CHANNEL,
            );
            setShowToast(true);
            setTimeout(() => {
                history.push('/payment-channels');
            }, 2000);
        },
        onError: (error: any) => {
            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1036) {
                    if (graphQLErrors[0].extensions.errorMessage.includes('channel ID')) {
                        setUniqueIdError(true);
                    } else if (graphQLErrors[0].extensions.errorMessage.includes('channel name')) {
                        setUniqueNameError(true);
                    }
                }
            } else {
                setMessage(ERROR_ADD_PAYMENT_CHANNEL);
                setShowToast(true);
                setError(true);
            }
        },
    });

    const validationSchema = Yup.object({
        paymentId: Yup.string().trim().required('Payment ID is required'),
        name: Yup.string().trim().required('Payment name is required'),
        markedUpValue: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .min(1, 'Marked-up percentage should be greater than 0')
            .max(100, 'Marked-up percentage must be less than or equal to 100')
            .required('Marked-up percentage is required'),
    });

    const checkSchedulerInputValid = () => {
        let inputValid = true;

        if (schedulerEnabled) {
            inputValid = validateJobDetails(newJobInfo);

            if (!inputValid) {
                setSchedulerInputInvalid(true);
            } else {
                setSchedulerInputInvalid(false);
            }
        }

        return inputValid;
    };

    const handleSchedulerEnabled = (value) => {
        setSchedulerEnabled(value);
    };

    const handleAddNewJobInfo = (value) => {
        setNewJobInfo(value);
        checkSchedulerInputValid();
    };

    const handleAddNewPaymentMethod = (value) => {
        setPaymentMethods((prev) => [...prev, value]);
        setPaymentMethod(value);
        setShowPaymentModal(false);
    };

    const paymentChannel = paymentChannelQueryData?.paymentChannel;

    return (
        <>
            <Formik
                initialValues={{
                    paymentId:
                        props.mode == ActionType.Add
                            ? ''
                            : ActionType.Edit || ActionType.View
                            ? paymentChannel?.channelId
                            : null,
                    name:
                        props.mode == ActionType.Add
                            ? ''
                            : ActionType.Edit
                            ? paymentChannel?.channelName
                            : null,
                    description:
                        props.mode == ActionType.Add
                            ? ''
                            : ActionType.Edit || ActionType.View
                            ? paymentChannel?.description
                            : null,
                    markedUpValue:
                        props.mode == ActionType.Add
                            ? ''
                            : ActionType.Edit || ActionType.View
                            ? paymentChannel?.markedUpPercentage
                            : null,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({ paymentId, name, markedUpValue }) => {
                    setError(false);
                    setUniqueIdError(false);
                    setUniqueNameError(false);

                    savePaymentChannel({
                        variables: {
                            paymentChannel: {
                                id: paymentChannelId,
                                channelId: paymentId,
                                channelName: name,
                                description: descriptionLong,
                                paymentMethod: paymentMethod,
                                markedUpPercentage: markedUpValue,
                            },
                        },
                    });
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <>
                        {showToast && (
                            <Toast
                                setShowToast={setShowToast}
                                message={message}
                                width="w-10/12"
                                margin="ml-1"
                                error={error}
                            />
                        )}
                        <div className="h-full flex flex-col justify-between pl-9">
                            <div className="flex items-start justify-between p-5 ">
                                <div className="text-lg font-poppins font-bold z-30">
                                    {props.mode == ActionType.View
                                        ? 'View '
                                        : props.mode == ActionType.Edit
                                        ? 'Edit '
                                        : 'Add New'}{' '}
                                    Payment Channel
                                </div>
                            </div>

                            <div className="-mt-5 flex flex-col">
                                <div className=" flex-wrap flex-col px-4 grid grid-cols-6 gap-y-5 gap-x-9 mt-10">
                                    <div className="flex flex-wrap col-start-1 col-end-3 flex-col lg:mb-5">
                                        <InputField
                                            placeHolder="Enter ID"
                                            name="Channel ID"
                                            id="paymentId"
                                            labelWidth="w-28"
                                            onChange={(event) => {
                                                if (uniqueIdError === true) {
                                                    setUniqueIdError(false);
                                                }
                                                handleChange(event);
                                            }}
                                            value={values.paymentId}
                                            readonly={
                                                props.mode == ActionType.View ||
                                                (props.mode == ActionType.Edit &&
                                                    values.paymentId == POINTS)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {errors.paymentId && touched.paymentId ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.paymentId}
                                            </div>
                                        ) : null}
                                        {uniqueIdError ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                Channel ID already exists
                                            </div>
                                        ) : null}
                                        {(errors.paymentId && touched.paymentId) || uniqueIdError
                                            ? window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: 'smooth',
                                              })
                                            : null}
                                    </div>
                                    <div className="flex flex-wrap flex-col lg:mb-5 col-start-3 col-end-5">
                                        <InputField
                                            placeHolder="Enter Channel Name"
                                            name="Channel Name"
                                            id="name"
                                            labelWidth="w-28"
                                            onChange={(event) => {
                                                if (uniqueNameError === true) {
                                                    setUniqueNameError(false);
                                                }
                                                handleChange(event);
                                            }}
                                            value={values.name}
                                            readonly={
                                                props.mode == ActionType.View ||
                                                (props.mode == ActionType.Edit &&
                                                    values.paymentId == POINTS)
                                                    ? true
                                                    : false
                                            }
                                        />

                                        {errors.name && touched.name ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.name}
                                            </div>
                                        ) : null}
                                        {uniqueNameError ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                Channel Name already exists
                                            </div>
                                        ) : null}
                                        {(errors.name && touched.name) || uniqueNameError
                                            ? window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: 'smooth',
                                              })
                                            : null}
                                    </div>
                                    <div className="flex flex-wrap col-start-1 col-end-3 flex-col lg:mb-5">
                                        <InputField
                                            readonly={props.mode == ActionType.View ? true : false}
                                            placeHolder="Enter Marked-up Percentage"
                                            name="Global Marked-up Percentage (%)"
                                            id="markedUpValue"
                                            labelWidth="w-28"
                                            type={'number'}
                                            value={values.markedUpValue}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.markedUpValue && touched.markedUpValue ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.markedUpValue}
                                            </div>
                                        ) : null}

                                        {errors.markedUpValue && touched.markedUpValue
                                            ? window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: 'smooth',
                                              })
                                            : null}
                                    </div>
                                    <div className="flex flex-wrap flex-col relative lg:mb-5 z-20 col-start-3 col-end-5">
                                        <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Method
                                        </div>
                                        <select
                                            disabled={
                                                props.mode == ActionType.View ||
                                                (props.mode == ActionType.Edit &&
                                                    values.paymentId == POINTS)
                                                    ? true
                                                    : false
                                            }
                                            value={paymentMethod}
                                            required
                                            className={`selectdropdownIcon p-4 lg:p-3 lg:py-4 rounded-xl outline-none border-2 bg-white border-gray-400 ${
                                                props.mode !== ActionType.View &&
                                                props.mode === ActionType.Edit &&
                                                values.paymentId !== POINTS &&
                                                `hover:border-purple-500`
                                            }`}
                                            id="product-type"
                                            placeholder="Select payment method"
                                            onChange={(e) => {
                                                if (e.target.value == 'ADD_NEW_METHOD') {
                                                    e.target.value = null;
                                                    setShowPaymentModal(true);
                                                } else {
                                                    setPaymentMethod(e.target.value);
                                                    setPaymentMethodError(false);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                setPaymentMethod(e.target.value);
                                                setPaymentMethodError(false);
                                            }}
                                        >
                                            <option value="" disabled>
                                                Select payment method
                                            </option>

                                            {paymentMethods
                                                ?.filter?.(
                                                    (paymentMethod) => paymentMethod != 'POINTS',
                                                )
                                                .map((paymentMethod) => (
                                                    <option key={paymentMethod}>
                                                        {paymentMethod}
                                                    </option>
                                                ))}

                                            {(props.mode == ActionType.Edit ||
                                                props.mode == ActionType.View) &&
                                                values.paymentId == POINTS && (
                                                    <option key={POINTS}>{POINTS}</option>
                                                )}
                                            <option
                                                value="ADD_NEW_METHOD"
                                                className="text-purple-500 cursor-pointer border-t-4 border-indigo-500"
                                            >
                                                + Add New Method
                                            </option>
                                        </select>
                                        {paymentMethodError ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {'Payment method is required'}
                                            </div>
                                        ) : null}
                                        <AddPaymentMethodModal
                                            showPaymentModalModal={showPaymentModal}
                                            setShowPaymentMethodModal={setShowPaymentModal}
                                            setPaymentMethodName={handleAddNewPaymentMethod}
                                            existingPaymentMethods={paymentMethods}
                                        />
                                    </div>
                                    <div className="col-start-1 col-end-5 ">
                                        <div className="col-start-1 col-end-9 flex flex-wrap relative">
                                            <div className="text-gray-500  w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                                Description
                                            </div>
                                            <textarea
                                                disabled={
                                                    props.mode == ActionType.View ? true : false
                                                }
                                                className={`p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 ${
                                                    props.mode != ActionType.View &&
                                                    `hover:border-purple-500`
                                                }`}
                                                id="groupDescription"
                                                name="groupDescription"
                                                rows={3}
                                                cols={138}
                                                draggable={false}
                                                maxLength={300}
                                                onInput={handleChange('description')}
                                                onChange={(e) => {
                                                    setDescriptionLong(e.target.value);
                                                }}
                                                value={values?.description}
                                                placeholder="Enter Description"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {selectedSupplierType === API && (
                                <div className="flex-col w-full mb-28 border-t-2 ml-4">
                                    <CronJobSchedulerView
                                        initialJobInfo={null}
                                        handleAddNewJobInfo={handleAddNewJobInfo}
                                        handleSchedulerEnabled={handleSchedulerEnabled}
                                        schedulerInputInvalid={schedulerInputInvalid}
                                    />
                                </div>
                            )}

                            <div className="flex w-10/12 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                                <Buttons
                                    name={
                                        props.mode == ActionType.Add ||
                                        props.mode == ActionType.Edit
                                            ? 'Cancel'
                                            : 'Edit'
                                    }
                                    type="button"
                                    buttonType="secondary-border-black"
                                    id="cancel"
                                    size="small"
                                    other="mr-3"
                                    onclick={() =>
                                        props.mode == ActionType.View
                                            ? history.push(
                                                  `/payment-channels/edit/${paymentChannelId}`,
                                              )
                                            : history.push('/payment-channels')
                                    }
                                />
                                <Buttons
                                    name={
                                        queryLoading ? (
                                            <NormalLoader />
                                        ) : props.mode == ActionType.Add ? (
                                            'Add New Channel'
                                        ) : props.mode == ActionType.Edit ? (
                                            'Save'
                                        ) : (
                                            'Close'
                                        )
                                    }
                                    type="submit"
                                    buttonType="primary"
                                    id="create-payment-channel"
                                    size="small"
                                    padding="p-1"
                                    onclick={() => {
                                        if (paymentMethod === null) {
                                            setPaymentMethodError(true);
                                        }
                                        if (!queryLoading && !paymentMethodError) {
                                            handleSubmit();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
}
