import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';

interface BackButtonProps {
    onClick: () => void;
    size?: number;
    className?: string;
    height?: string | number;
    width?: string | number;
}

const BackButton: React.FC<BackButtonProps> = ({
    onClick,
    size = 25,
    className = '',
    height = 9,
    width = 9,
}) => {
    return (
        <div
            className={`flex rounded-full bg-gray-300 h-${height} w-${width} items-center justify-center cursor-pointer hover:bg-gray-400 ${className}`}
        >
            <button onClick={onClick}>
                <FiArrowLeft size={size} />
            </button>
        </div>
    );
};

export default BackButton;
