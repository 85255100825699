import { useEffect, useState } from 'react';
import IconButton from '../atoms/IconButton';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import {
    GLOBAL_CURRENCY_EDIT_ERROR_BODY,
    GLOBAL_CURRENCY_EDIT_ERROR_TITLE,
} from '../../../constants/globalCurrencyConversions';

interface Props {
    initialValue: any;
    row: any;
    updateValue: (id, value, paymentMethhod?: string) => void;
    isDisabled?: boolean;
    isOverride?: boolean;
    setSelectedGlobalCurrency?: (value) => void;
    id?: string;
    data?: any;
    validationRule?: ValidationRule;
    onClick?: () => void;
    changeTextColorOnChange?: ChangeTextColorOnChangeType;
    doBeforeValidationModalOpen?: () => void;
    doAfterValidationModalVerification?: () => void;
}

interface ChangeTextColorOnChangeType {
    /**
     * Changes the color of the text to black when edit cells value changes.
     * Set `enabled` to true to allow or false to not allow, and cellRowAccessor is the rows accessor name which is used to get the cells initial value for comparison.
     */
    enabled: boolean;
    cellRowAccessor: string;
}
interface ValidationRule {
    /**
     * Controls whether values greater than 100 are allowed.
     * Set `enabled` to true to allow or false to not allow, and provide a custom `errorMessage` if needed , error works only if enable is false.
     */
    allowValuesGreaterThan100?: {
        enabled: boolean;
        errorMessage?: string;
        messageType?: 'Error' | 'Alert';
    };

    /**
     * Controls whether values less than 0 are allowed.
     * Set `enabled` to true to allow or false to not allow, and provide a custom `errorMessage` if needed , error works only if enable is false.
     */
    allowValuesLessThan0?: {
        enabled: boolean;
        errorMessage?: string;
        messageType?: 'Error' | 'Alert';
    };
    /**
     * Controls whether values are between 1 and 100.
     * Set `enabled` to true to allow or false to not allow, and provide a custom `errorMessage` if needed , error works only if enable is true.
     */
    allowValuesBetween1And100?: {
        enabled: boolean;
        errorMessage?: string;
        messageType?: 'Error' | 'Alert';
    };
    /**
     * Controls whether values can be empty
     * Set `enabled` to true to allow or false to not allow, and provide a custom `errorMessage` if needed, error works only if enable is false.
     */
    allowEmptyFeild?: {
        enabled: boolean;
        errorMessage?: string;
        messageType?: 'Error' | 'Alert';
    };
}

export default function EditableCell({
    initialValue,
    row,
    updateValue,
    isDisabled,
    isOverride,
    setSelectedGlobalCurrency,
    id,
    data,
    validationRule,
    onClick,
    changeTextColorOnChange,
}: Props) {
    const [value, setValue] = useState(initialValue);
    const [valueChanges, setValueChanges] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [warmingModalBody, setWarningModalBody] = useState(null);
    const [warmingModalTitle, setWarningModalTitle] = useState(null);

    const onChange = (e) => {
        setValueChanges(true);
        setValue(e.target.value);
    };

    const handleClickSave = () => {
        if (setSelectedGlobalCurrency) {
            setSelectedGlobalCurrency(row?.original);
        }
        if (data) {
            updateValue(row?.original?.id || row?.index, value, data);
        } else {
            updateValue(row?.original?.id || row?.index, value);
        }

        setValueChanges(false);
    };

    const handleClickClose = () => {
        setValue(initialValue ?? '');
        setValueChanges(false);
    };

    const handleButtonClick = () => {
        if (validationRule?.allowEmptyFeild?.enabled === false && value === '') {
            setShowWarningModal(true);
            setWarningModalBody(validationRule.allowEmptyFeild.errorMessage);
            setWarningModalTitle(validationRule.allowEmptyFeild.messageType);
        } else if (
            validationRule?.allowValuesBetween1And100?.enabled &&
            (value > 100 || value <= 0)
        ) {
            setShowWarningModal(true);
            setWarningModalBody(validationRule.allowValuesBetween1And100.errorMessage);
            setWarningModalTitle(validationRule.allowValuesBetween1And100.messageType);
        } else if (validationRule?.allowValuesLessThan0?.enabled === false && value <= 0) {
            setShowWarningModal(true);
            setWarningModalBody(validationRule.allowValuesLessThan0.errorMessage);
            setWarningModalTitle(validationRule.allowValuesLessThan0.messageType);
        } else if (validationRule?.allowValuesGreaterThan100?.enabled === false && value > 100) {
            setShowWarningModal(true);
            setWarningModalBody(validationRule.allowValuesGreaterThan100.errorMessage);
            setWarningModalTitle(validationRule.allowValuesGreaterThan100.messageType);
        } else {
            handleClickSave();
        }
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div>
            <div className="w-full flex flex-row">
                <div className="flex w-28">
                    <input
                        id={id || ''}
                        className={`p-2 w-full border-2 focus:border-purple-400 outline-none rounded-lg ${
                            changeTextColorOnChange?.enabled &&
                            row.original[changeTextColorOnChange.cellRowAccessor] != value
                                ? 'text-black'
                                : ''
                        }  ${isOverride ? 'border-red-400' : ''}`}
                        value={value}
                        onChange={onChange}
                        type="number"
                        placeholder={value ?? 'Not Set'}
                        disabled={isDisabled ?? false}
                        onClick={onClick}
                    />
                </div>
                {valueChanges && (
                    <>
                        <div className="flex">
                            <IconButton
                                id={id ? `${id}-save-button` : 'save-button'}
                                disabled={false}
                                iconUrl="/images/icons/check-lg.svg"
                                onClick={handleButtonClick}
                            />
                        </div>
                        <div className="flex ml-2">
                            <IconButton
                                id={id ? `${id}-cancel-button` : 'cancel-button'}
                                disabled={false}
                                iconUrl="/images/icons/x-lg.svg"
                                onClick={() => {
                                    handleClickClose();
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={
                    warmingModalBody != null ? warmingModalBody : GLOBAL_CURRENCY_EDIT_ERROR_BODY
                }
                warningModalTitle={
                    warmingModalTitle != null ? warmingModalTitle : GLOBAL_CURRENCY_EDIT_ERROR_TITLE
                }
            />
        </div>
    );
}
