/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState, useMemo } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ADD_PRODUCTS, FETCH_PRODUCTS } from '../../../queries/ItemQueries';
import { Buttons } from '../../ui/atoms/Button';
import jwt from 'jwt-decode';
import { InputField } from '../../ui/atoms/InputField';
import * as BsIcons from 'react-icons/bs';
import UploadProductPic from './UplaodProductImage';
import { useHistory, useLocation } from 'react-router';
import Loader from '../../../utils/loader';
import AddProductSuccessModal from '../../templates/modals/AddProductSuccess';
import UnSavedProduct from '../../templates/modals/UnsavedProductScreen';
import {
    DATE,
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    UN_SAVED_CHANGES,
    UN_SAVED_CHANGES_TEXT,
} from '../../../constants/product';
import * as Yup from 'yup';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import { getCookie } from '../../../utils/cookiesService';
import { FETCH_ADMIN_ONE_PROFILE_ID } from '../../../queries/AdminProfileQueries';
import { useDispatch } from 'react-redux';
import { saveProductName, saveSupplierName } from '../../../redux/rootActions';
import { SWAR_MVP_HIDE_FEATURE } from '../../../constants/config/constants';
import NormalLoader from '../../../utils/normalLoader';
import { CSV_VOUCHERS, VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';
import Toast from '../../ui/atoms/Toast';

export default function ProductGeneral() {
    const [message, setMessage] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [unPublished, setUnPublished] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showUnSavedModal, setShowUnSavedModal] = useState(false);

    const location = useLocation<any>();
    const [clicked, setClicked] = useState(false);
    const mappedProductDetails = location?.state?.product;
    const selectedSupplierID = location?.state?.supplier;
    const dispatch = useDispatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [productImage, setProductImage] = useState('');
    const [descriptionShort, setDescriptionShort] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [selectedProductType, setSelectedProductType] = useState('');
    const [selectedProductTypeError, setSelectedProductTypeError] = useState(false);
    const [selectedProductDescriptionError, setSelectedProductDescriptionError] = useState(false);
    const [safetyStockThreshold, setSafetyStockThreshold] = useState(50);
    const [productImageError, setProductImageError] = useState(false);
    const [gallery1, setGallery1Image] = useState('');
    const [gallery2, setGallery2Image] = useState('');
    const [gallery3, setGallery3Image] = useState('');
    const [gallery4, setGallery4Image] = useState('');
    const [gallery5, setGallery5Image] = useState('');
    const [gallery6, setGallery6Image] = useState('');
    const [outOfStockImage, setOutOfStockImage] = useState<string>('');
    const [outOfStockImageError, setOutOfStockImageError] = useState<boolean>(false);
    const [productImageEmptyError, setProductImageEmptyError] = useState<boolean>(false);
    const [selectedContentType, setSelectedContentType] = useState('');
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [voucherLastInventories, setVoucherLastInventories] = useState([]);
    const [unitPriceValue, setUnitPriceValue] = useState(null);
    const conversionRateValue = 1;
    const history = useHistory();
    const galleryImages = useMemo(() => [], []);

    const [saveItem, { loading: queryLoading, error: queryError, data: queryData }] = useMutation(
        ADD_PRODUCTS,
        {
            context: { clientName: ClientsEnum.STORE },
            refetchQueries: [FETCH_PRODUCTS],
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                setError(false);
                {
                    unPublished
                        ? setMessage('Item saved as draft')
                        : setMessage('Successfully created the product');
                }
                setComplete(true);
                setIsDirty(false);
                dispatch(saveProductName(data?.saveItem?.itemName));
                localStorage.removeItem('added_product');
                setShowToast(true);
                setTimeout(() => {
                    history.push({
                        pathname: '/products',
                        state: { setToast: true, addProduct: true },
                    });
                }, 2000); // Delay for 2 seconds
            },
            onError: () => {
                setMessage(queryError?.message || 'Oops something went wrong');
                setShowToast(true);
                setError(true);
            },
        },
    );

    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwt(token) : null;

    const { data: userIdData, loading: userDataLoading } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { userName: tokenDetails?.preferred_username },
    });

    useEffect(() => {
        const galleryArray = [];
        galleryArray.push(gallery1, gallery2, gallery3, gallery4, gallery5, gallery6);
        galleryArray?.forEach((image) => {
            if (image?.length > 0) {
                galleryImages?.push(image);
            }
        });
    }, [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, galleryImages]);

    const productTypeOptions = [
        {
            text: 'Physical',
            value: 'PHYSICAL',
        },
        {
            text: 'Virtual',
            value: 'VIRTUAL',
        },
    ];

    const contentTypeOptions = [
        {
            label: 'NORMAL',
            value: 'NORMAL',
        },
        {
            label: 'PREMIUM',
            value: 'PREMIUM',
        },
    ];

    useEffect(() => {
        if (!mappedProductDetails[0]?.voucherInventories) return; // Add null/undefined guard

        const arrayOfVoucher = mappedProductDetails[0].voucherInventories.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });

        if (arrayOfVoucher.length) {
            const arrayOfIds = arrayOfVoucher.map((voc) => voc?.id); // Safely map
            const firstVoucherID = arrayOfIds.length > 1 ? Math.min(...arrayOfIds) : arrayOfIds[0];
            const firstVoucher = mappedProductDetails[0].voucherInventories.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
        }
    }, [mappedProductDetails]);

    useEffect(() => {
        if (!mappedProductDetails[0]?.voucherInventories) return; // Add null/undefined guard

        const availableVoucher = mappedProductDetails[0].voucherInventories;
        const availableIds = availableVoucher.map((voc) => voc?.id); // Safely map

        if (availableIds.length) {
            const firstVoucherID =
                availableIds.length > 1 ? Math.max(...availableIds) : availableIds[0];
            const firstVoucher = mappedProductDetails[0].voucherInventories.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherLastInventories(firstVoucher);
        }
    }, [mappedProductDetails]);

    // useEffect(() => {
    //     if (!selectedProductType && clicked) setSelectedProductTypeError(true);
    //     else setSelectedProductTypeError(false);
    // }, [selectedProductType, setSelectedProductTypeError, clicked]);    not needed for now

    const validationSchema = Yup.object({
        itemName: Yup.string().trim().required('Product title is missing'),
        itemDescDetail: Yup.string().trim().required('Product detailed description is missing'),
    });

    useEffect(() => {
        if (descriptionLong) {
            setSelectedProductDescriptionError(false);
        }
    }, [descriptionLong]);

    useEffect(() => {
        if (conversionRateValue && voucherInventories['unitPrice']) {
            const result = parseFloat(
                (voucherInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (
            conversionRateValue &&
            !voucherInventories['unitPrice'] &&
            voucherLastInventories['unitPrice']
        ) {
            const result = parseFloat(
                (voucherLastInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (!conversionRateValue) {
            setUnitPriceValue(
                voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : 0,
            );
        }
    }, [conversionRateValue, setUnitPriceValue, voucherInventories, voucherLastInventories]);

    useEffect(() => {
        if (showToast) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Makes the scrolling smooth
            });
        }
    }, [showToast]);

    if (userDataLoading) return <Loader />;
    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: mappedProductDetails[0]?.productName,
                itemType: '',
                itemDescBrief: '',
                safetyStockThreshold: safetyStockThreshold,
                itemImageUrl: mappedProductDetails[0]?.productImageUrl,
                itemOutOfStockImageUrl: mappedProductDetails[0]?.outOfStockImageUrl,
                itemGalleryUrls: [],
                itemDescDetail: '',
                deliveryMethod: '',
                pointsValue: mappedProductDetails[0]?.unitPrice,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({
                itemName,
                safetyStockThreshold,
                itemImageUrl,
                itemOutOfStockImageUrl,
            }) => {
                if (descriptionLong) {
                    dispatch(saveProductName(itemName));

                    if (unPublished) {
                        saveItem({
                            variables: {
                                item: {
                                    itemName: itemName,
                                    itemType: selectedProductType || productTypeOptions[1]?.value,
                                    itemDescBrief: descriptionShort || '',
                                    itemImageUrl: productImage ? productImage : itemImageUrl,
                                    outOfStockImageUrl: outOfStockImage
                                        ? outOfStockImage
                                        : itemOutOfStockImageUrl,
                                    itemGalleryUrls: galleryImages?.filter(
                                        (gallery) => gallery?.length,
                                    ).length
                                        ? galleryImages?.filter((gallery) => gallery?.length)
                                        : [],
                                    itemDescDetail: descriptionLong || '',
                                    safetyStockThreshold:
                                        mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS
                                            ? safetyStockThreshold
                                            : null,
                                    deliveryMethod: 'REDEEM',
                                    isDeleted: false,
                                    currencyConversionRate: conversionRateValue,
                                    unitPrice: unitPriceValue || voucherInventories['unitPrice'],
                                    isActive: true,
                                    createdBy: userIdData?.user?.userId,
                                    itemStatus: 'UNPUBLISHED',
                                    product: {
                                        id: mappedProductDetails[0]?.id,
                                    },
                                    contentType: selectedContentType || contentTypeOptions[0].value,
                                },
                            },
                        });
                    } else {
                        saveItem({
                            variables: {
                                item: {
                                    itemName: itemName,
                                    itemType: selectedProductType || productTypeOptions[1]?.value,
                                    itemDescBrief: descriptionShort || '',
                                    itemImageUrl: productImage ? productImage : itemImageUrl,
                                    outOfStockImageUrl: outOfStockImage
                                        ? outOfStockImage
                                        : itemOutOfStockImageUrl,
                                    itemGalleryUrls: galleryImages || [],
                                    itemDescDetail: descriptionLong || '',
                                    safetyStockThreshold:
                                        mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS
                                            ? safetyStockThreshold
                                            : null,
                                    deliveryMethod: 'REDEEM',
                                    isDeleted: false,
                                    currencyConversionRate: conversionRateValue,
                                    unitPrice: unitPriceValue || voucherInventories['unitPrice'],
                                    isActive: true,
                                    createdBy: userIdData?.user?.userId,
                                    itemStatus: 'PUBLISHED',
                                    product: {
                                        id: mappedProductDetails[0]?.id,
                                    },
                                    contentType: selectedContentType || contentTypeOptions[0].value,
                                },
                            },
                        });
                    }
                }
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <>
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-8/12"
                            error={error}
                            selfDestruct={true}
                            selfDestructTimer={5000}
                        />
                    )}
                    <UnsaveChangesWarning
                        contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                        messageTitle={UN_SAVED_CHANGES}
                        when={isDirty}
                        navigate={(path) => history.push(path)}
                        shouldBlockNavigation={(location) => {
                            if (isDirty) {
                                return true;
                            }
                            return false;
                        }}
                        displayIcon={true}
                        displayIconName={'un-saved-changes.svg'}
                        yesButtonName="Yes"
                        noButtonName="No"
                    />
                    <div className="px-14 w-full h-full flex-col justify-between">
                        <div className="text-xl font-poppins font-bold px-4 ">General</div>

                        <AddProductSuccessModal
                            setShowSuccessModal={setShowSuccessModal}
                            showSuccessModal={showSuccessModal}
                            unPublish={unPublished}
                        />
                        <UnSavedProduct
                            setShowUnSavedModal={setShowUnSavedModal}
                            showUnSavedModal={showUnSavedModal}
                        />

                        <div className="text-gray-500 w-full my-5 text-left font-poppins text-md h-max bg-white z-10 ml-4 border-opacity-20">
                            Supplier Product Code:{' '}
                            <span className="font-semibold text-purple-500">
                                {getSupplierProductCode(mappedProductDetails[0])}
                            </span>
                        </div>

                        <div className="grid grid-cols-8 gap-4">
                            <div className="lg:col-start-1 lg:col-end-3 ">
                                <div className="flex flex-wrap flex-col relative mt-3  px-4">
                                    <div className="text-gray-500 w-25 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Product type
                                    </div>
                                    <select
                                        className="p-4 lg:p-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                        id="product_type"
                                        onChange={(e) => {
                                            setSelectedProductType(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedProductType(e.target.value);
                                        }}
                                        defaultValue={productTypeOptions[1]?.value}
                                    >
                                        <option value="" disabled selected>
                                            Select product type
                                        </option>
                                        {productTypeOptions?.map((option: any, index) => {
                                            return (
                                                <option value={option.value} key={index}>
                                                    {option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {selectedProductTypeError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Product type is missing'}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="lg:col-start-3 lg:col-end-5">
                                <div className="flex flex-wrap flex-col relative mt-3 px-4">
                                    <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Content Type
                                    </div>
                                    <select
                                        className="p-4 lg:p-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                        id="content_type"
                                        onChange={(e) => {
                                            setSelectedContentType(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedContentType(e.target.value);
                                        }}
                                        value={selectedContentType}
                                        defaultValue={contentTypeOptions[0]?.value}
                                    >
                                        {contentTypeOptions?.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className="font-medium"
                                                    key={index}
                                                >
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-5 mt-3 px-4">
                                <InputField
                                    id="itemName"
                                    placeHolder="Enter product title"
                                    name="Product title"
                                    labelWidth="w-24"
                                    maxLength={255}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.itemName}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        255 characters limited
                                    </div>
                                </div>
                                {errors.itemName && touched.itemName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.itemName}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4 ">
                                <div
                                    className={`text-gray-500  w-65 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product description(small)(optional)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="prod_description_small"
                                    name="itemDescBrief"
                                    rows={2}
                                    cols={138}
                                    draggable={false}
                                    maxLength={150}
                                    onInput={handleChange('itemDescBrief')}
                                    onChange={(e) => {
                                        setDescriptionShort(e.target.value);
                                    }}
                                    value={values.itemDescBrief}
                                    placeholder="Enter product short description - this will be used on the  product card"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        150 characters limited
                                    </div>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-54 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product description(detailed)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="prod_description_detailed"
                                    name="itemDescDetail"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={600}
                                    onInput={handleChange('itemDescDetail')}
                                    onChange={(e) => {
                                        setDescriptionLong(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    value={values.itemDescDetail}
                                    placeholder="Enter product short description - this will be used on the product detailed view"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        600 characters limited
                                    </div>
                                </div>
                                <div className="w-full py-0.5 justify-between mt-3 flex">
                                    {(errors.itemDescDetail && touched.itemDescDetail) ||
                                    selectedProductDescriptionError ? (
                                        <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                            {'Product description is missing'}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS ? (
                                <div className="col-start-1 col-end-5 mt-3 px-4">
                                    <InputField
                                        id="safetyStockThreshold"
                                        name="Safety stock threshold"
                                        labelWidth="w-24"
                                        maxLength={5}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setIsDirty(true);
                                        }}
                                        value={values.safetyStockThreshold}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="w-full flex mt-5 flex-col xl:flex-row px-4">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">Product image</div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>
                                <div className="flex py-2">
                                    <UploadProductPic
                                        width={'w-full'}
                                        height={'h-80'}
                                        isEdit={true}
                                        inputId="upload_image"
                                        setGalleryImage={setProductImage}
                                        imageProp={mappedProductDetails[0]?.productImageUrl}
                                        aspectRatio={1 / 1}
                                        transparencyEnabled={true}
                                    />
                                </div>
                                {productImageEmptyError &&
                                    (productImage == null || productImage?.length === 0) &&
                                    mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS && (
                                        <div className="w-full py-0.5 justify-between mt-3 flex">
                                            <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                                {'Product image is not selected'}
                                            </div>
                                        </div>
                                    )}

                                {!SWAR_MVP_HIDE_FEATURE ? (
                                    <div className="text-gray-400 flex mb-5">
                                        What can I upload?
                                    </div>
                                ) : null}
                            </div>

                            <div className="w-full ml-9">
                                <div className="flex my-3">
                                    <div className="justify-between font-poppins">
                                        Product gallery (optional)
                                    </div>
                                    <div className="text-gray-400 ml-1">(Max 64 X 64)</div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery1"
                                            setGalleryImage={setGallery1Image}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery2"
                                            setGalleryImage={setGallery2Image}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery3"
                                            setGalleryImage={setGallery3Image}
                                            transparencyEnabled={true}
                                        />
                                    </div>
                                    <div className="flex mt-8">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery4"
                                            setGalleryImage={setGallery4Image}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery5"
                                            setGalleryImage={setGallery5Image}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="product_gallery6"
                                            setGalleryImage={setGallery6Image}
                                            transparencyEnabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full mb-10 px-4">
                            <div className="flex my-4">
                                <div className="mr-1 font-poppins">
                                    {mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS
                                        ? `Out of Stock Image(Optional)`
                                        : `Out of Stock Image`}
                                </div>
                                <div className="text-gray-400"> (Max 2048 X 2048)</div>
                            </div>
                            <div className="flex py-2">
                                <UploadProductPic
                                    width={'w-full'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="out_of_stock_image"
                                    setGalleryImage={setOutOfStockImage}
                                    imageProp={mappedProductDetails[0]?.outOfStockImageUrl}
                                    aspectRatio={1 / 1}
                                    transparencyEnabled={true}
                                />
                            </div>
                            {outOfStockImageError &&
                                (outOfStockImage == null || outOfStockImage?.length === 0) && (
                                    <div className="w-full py-0.5 justify-between mt-3 flex">
                                        <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                            {'Out of stock image is not selected'}
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="absolute w-10/12 right-0">
                            <div className={`flex flex-row  bg-gray-100 p-4 justify-end`}>
                                <div
                                    id="move_unpublish"
                                    className="underline text-purple-500 font-poppins mr-10 mt-4"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => {
                                        if (
                                            !productImage.toString() &&
                                            !mappedProductDetails[0]?.productImageUrl
                                        )
                                            setProductImageError(true);
                                        setUnPublished(true);
                                        handleSubmit();
                                    }}
                                    onKeyDown={() => handleSubmit()}
                                >
                                    {queryLoading && unPublished ? (
                                        <NormalLoader />
                                    ) : (
                                        'Move to unpublished'
                                    )}
                                </div>
                                <Buttons
                                    name="Cancel"
                                    type="button"
                                    buttonType="secondary-border-black"
                                    id="cancel"
                                    size="e-small"
                                    other="mr-3"
                                    onclick={() => {
                                        history.push('/products');
                                    }}
                                />
                                <Buttons
                                    name={queryLoading && !unPublished ? <NormalLoader /> : 'Save'}
                                    type="submit"
                                    buttonType="primary"
                                    id="next"
                                    size="e-small"
                                    onclick={() => {
                                        setClicked(true);

                                        if (!descriptionLong) {
                                            setSelectedProductDescriptionError(true);
                                        }

                                        //From line 738 to line 755 is commented and code added 758 to 762 lines  only in QA branch due to AWS image upload block.

                                        // if (
                                        //     (productImage === null || productImage?.length == 0) &&
                                        //     mappedProductDetails[0]?.orderStrategy === CSV_VOUCHERS
                                        // ) {
                                        //     setProductImageEmptyError(true);
                                        // } else if (
                                        //     (outOfStockImage === null ||
                                        //         outOfStockImage?.length == 0) &&
                                        //     mappedProductDetails[0]?.orderStrategy !== CSV_VOUCHERS
                                        // ) {
                                        //     setOutOfStockImageError(true);
                                        // } else {
                                        //     setProductImageEmptyError(false);
                                        //     setOutOfStockImageError(false);
                                        //     if (!selectedProductDescriptionError) {
                                        //         handleSubmit();
                                        //     }
                                        // }

                                        setProductImageEmptyError(false);
                                        setOutOfStockImageError(false);
                                        if (!selectedProductDescriptionError) {
                                            handleSubmit();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
