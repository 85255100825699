export const INSTANT_EDIT_OPTION_VIEW_PAYMENT_CHANNEL = 'VIEW';

export const INSTANT_EDIT_OPTION_EDIT_PAYMENT_CHANNEL = 'EDIT';

export const INSTANT_EDIT_OPTION_ACTIVE_PAYMENT_CHANNEL = 'ACTIVE';

export const INSTANT_EDIT_OPTION_INACTIVE_PAYMENT_CHANNEL = 'INACTIVE';

export const SUCCESS_ADD_PAYMENT_CHANNEL = 'Payment channel added successfully.';

export const ERROR_ADD_PAYMENT_CHANNEL = 'Error occurred while creating the payment channel';

export const SUCCESS_EDIT_PAYMENT_CHANNEL = 'Payment channel Updated successfully.';

export const POINTS = 'POINTS';
