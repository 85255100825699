interface Props {
    columns: any;
    height?: string;
}
export const DataTableLoadingSkeleton = ({ columns, height }: Props) => {
    return (
        <div
            className={`${
                height ? height : 'h-screen'
            } bg-gray-100 flex items-center justify-center w-full`}
        >
            <section className="h-full w-full">
                <div className="w-full overflow-x-auto">
                    <table className="w-full min-w-max bg-white rounded-md shadow-md">
                        <thead className="bg-purple-200">
                            <tr>
                                {columns.map((col, index) => (
                                    <th
                                        key={index}
                                        className="py-4 px-3 text-left text-sm text-gray-500 font-poppins"
                                    >
                                        <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: 3 }).map((_, rowIndex) => (
                                <tr key={rowIndex} className="border-b-2 border-gray-100">
                                    {columns.map((_, colIndex) => (
                                        <td key={colIndex} className="py-3 px-3">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};
