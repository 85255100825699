import { useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserDetails } from '../../../../redux/rootActions';
import { InputField } from '../../../ui/atoms/InputField';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    GET_PAYMENT_CHANNEL_DATA_BY_PARTNER_ID,
    SAVE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS,
} from '../../../../queries/PaymentChannelQueries';
import { Dropdown, Loader } from 'semantic-ui-react';
import ReactSwitch from 'react-switch';
import { Buttons } from '../../../ui/atoms/Button';
import { useHistory } from 'react-router';
import NormalLoader from '../../../../utils/normalLoader';
import { RootState } from '../../../../redux/rootReducer';
import Toast from '../../../ui/atoms/Toast';

export default function AddNewPartnerMarkedUpPercentage() {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPartner = useSelector((state: RootState) => state.auth.partner);

    const [paymentChannelList, setPaymentChannelList] = useState([]);
    const [dbLoading, setDbLoading] = useState(false);
    const [updatedPaymentChannelDataList, setUpdatedPaymentChannelDataList] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const { loading: paymentChannelsDataLoading } = useQuery(
        GET_PAYMENT_CHANNEL_DATA_BY_PARTNER_ID,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            variables: {
                partnerId: null,
            },
            onCompleted: (data) => {
                setPaymentChannelList(data.paymentChannelsDataByPartnerId);
            },
        },
    );

    const [
        savePartnerPaymentChannelData,
        { loading: partnerPaymentChannelDataUpdateMutationLoading },
    ] = useMutation(SAVE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [GET_PAYMENT_CHANNEL_DATA_BY_PARTNER_ID],
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true,
        onCompleted(res) {
            setShowToast(true);
            setMessage('Successfully updated partner payment channel data');
            setTimeout(() => {
                history.push('/partner/agreement-details-add');
                setShowToast(false);
            }, 2000);
        },
        onError() {
            setShowToast(true);
            setIsError(true);
            setMessage(
                'Something went wrong while updating partner level markedup percentage data',
            );
        },
    });

    const updateMarkedUpPercentage = (e, paymentChannel) => {
        const newValue = e.target.value; // Get the typed value from the input field
        setPaymentChannelList((prevPaymentChannels) =>
            prevPaymentChannels.map(
                (channel) =>
                    channel.id === paymentChannel.id
                        ? {
                              ...channel,
                              markedUpPercentage: newValue, // Update the target channel
                          }
                        : channel, // Keep others unchanged
            ),
        );

        updateModifiedChannels(paymentChannel.id, { markedUpPercentage: newValue });
    };

    const handleToggle = (paymentChannelId) => {
        setPaymentChannelList((prevPaymentChannels) =>
            prevPaymentChannels.map((paymentChannel) =>
                paymentChannel.id === paymentChannelId
                    ? { ...paymentChannel, active: !paymentChannel.active }
                    : paymentChannel,
            ),
        );

        const toggledChannel = paymentChannelList.find(
            (channel) => channel.id === paymentChannelId,
        );
        updateModifiedChannels(paymentChannelId, { active: !toggledChannel.active });
    };

    const updateModifiedChannels = (id, updatedProperties) => {
        setUpdatedPaymentChannelDataList((prev) => {
            const existingChannel = prev.find((channel) => channel.id === id);
            if (existingChannel) {
                // Update existing channel in modified list
                return prev.map((channel) =>
                    channel.id === id ? { ...channel, ...updatedProperties } : channel,
                );
            } else {
                // Add new modified channel
                const originalChannel = paymentChannelList.find((channel) => channel.id === id);
                return [...prev, { ...originalChannel, ...updatedProperties }];
            }
        });
    };

    // Helper function to map to the new object structure
    const mapToNewStructure = (channel) => ({
        paymentChannelId: channel.id,
        markedUpPercentage: channel.markedUpPercentage,
        isActive: channel.active,
    });

    if (paymentChannelsDataLoading) return <Loader />;

    const validationSchema = Yup.object({
        paymentChannelList: Yup.array().of(
            Yup.object({
                markedUpPercentage: Yup.number()
                    .transform((originalValue) => {
                        // Convert null, undefined, or empty string to 0
                        if (
                            originalValue === null ||
                            originalValue === undefined ||
                            originalValue === ''
                        ) {
                            return 0;
                        }
                        // If the value is NaN (which could happen if it's not a valid number), return 0
                        return isNaN(originalValue) ? 0 : originalValue;
                    })
                    .required('Marked-up percentage is required')
                    .min(1, 'Marked-up percentage should be greater than 0')
                    .max(100, 'Marked-up percentage must be less than or equal to 100'),
            }),
        ),
    });

    const handleSubmit = (values) => {
        dispatch(getAllUserDetails());

        const partnerPaymentChannelDataDtoList = [];

        updatedPaymentChannelDataList.map((paymentChannel) => {
            const newchannel = mapToNewStructure(paymentChannel);
            partnerPaymentChannelDataDtoList.push(newchannel);
        });

        savePartnerPaymentChannelData({
            variables: {
                partnerPaymentChannelDataInput: {
                    partnerId: selectedPartner?.partner?.id,
                    partnerPaymentChannels: partnerPaymentChannelDataDtoList,
                },
            },
        });
    };

    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={isError}
                    width="w-10/12"
                    margin="-mt-10 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <Formik
                innerRef={formRef}
                initialValues={{
                    paymentChannelList: paymentChannelList,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <div className="h-full flex flex-col  justify-evens px-4">
                        <div className="text-lg font-poppins font-bold px-8">
                            Payment Channels & Marked Up Percentages
                        </div>
                        <div>
                            {values.paymentChannelList.length > 0 ? (
                                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 gap-x-20 mt-8 px-8 mb-40">
                                    {paymentChannelList.map((paymentChannel, index) => (
                                        <div
                                            key={paymentChannel.id}
                                            className="flex flex-col relative"
                                        >
                                            <div className="flex gap-2">
                                                <InputField
                                                    id={`add-partner-${paymentChannel.channelName}`}
                                                    // id="markedUpPercentage"
                                                    placeHolder={'0'}
                                                    name={paymentChannel.channelName}
                                                    labelWidth="w-21"
                                                    onChange={(e) => {
                                                        updateMarkedUpPercentage(e, paymentChannel);
                                                    }}
                                                    value={paymentChannel.markedUpPercentage ?? 0}
                                                    disabled={!paymentChannel.active}
                                                    readonly={!paymentChannel.active}
                                                    type="number"
                                                    min={0}
                                                />

                                                <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                                <div className="col-span-1 my-auto font-poppins text-2xl flex items-center">
                                                    <ReactSwitch
                                                        id={
                                                            paymentChannel.channelName +
                                                            '_toggle_switch'
                                                        }
                                                        checked={paymentChannel.active}
                                                        onChange={() => {
                                                            handleToggle(paymentChannel.id);
                                                        }}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        onColor={'#8b5cf6'}
                                                        height={24}
                                                        width={46}
                                                    />
                                                </div>
                                            </div>

                                            {errors.paymentChannelList &&
                                                (errors.paymentChannelList as any)[index]
                                                    ?.markedUpPercentage &&
                                                touched.paymentChannelList?.[index]
                                                    ?.markedUpPercentage && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '12px',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {
                                                            (errors.paymentChannelList as any)[
                                                                index
                                                            ]?.markedUpPercentage
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="grid grid-cols-1 gap-8 gap-x-10 mt-8 px-8 mb-40">
                                    Active payment channels are not found
                                </div>
                            )}
                        </div>
                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel-button"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push('/partners');
                                }}
                            />
                            <Buttons
                                name={
                                    dbLoading || partnerPaymentChannelDataUpdateMutationLoading ? (
                                        <NormalLoader />
                                    ) : (
                                        'Save'
                                    )
                                }
                                type="submit"
                                buttonType="primary"
                                id="save-button"
                                size="e-small"
                                onclick={handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}
