import React from 'react';

interface ButtonProps {
    name: any;
    type?: any;
    buttonType: string;
    onclick?: () => void;
    id: string;
    size?: 'large' | 'large-full' | 'medium' | 'small' | 'e-small' | string;
    padding?: string;
    other?: any;
    disabled?: boolean;
    buttonMargin?: string;
}

export const Buttons: React.FunctionComponent<ButtonProps> = (props) => {
    let width;
    let buttonProperty;

    if (props.size === 'large') {
        width = 'w-5/6';
    } else if (props.size === 'large-full') {
        width = 'w-full';
    } else if (props.size === 'medium') {
        width = 'w-3/6';
    } else if (props.size === 'small') {
        width = 'w-7/12 xl:w-3/12';
    } else if (props.size === 'e-small') {
        width = 'w-7/12 xl:w-1/6';
    }

    if (props.buttonType === 'primary') {
        buttonProperty = 'bg-purple-500 text-white rounded-lg';
    } else if (props.buttonType === 'secondary') {
        buttonProperty = 'bg-white text-gray-500 bg-gray-100 border rounded-lg';
    } else if (props.buttonType === 'secondary-border-black') {
        buttonProperty = 'bg-white text-gray-500 bg-gray-100 border border-black rounded-lg';
    } else if (props.buttonType === 'secondary-main') {
        buttonProperty = `border-2 rounded-md mr-5 ${
            props?.disabled
                ? 'bg-white text-gray-400 border-gray-100'
                : 'bg-white text-purple-500 border-purple-500 hover:bg-purple-500 hover:text-white'
        }`;
    } else if (props.buttonType === 'secondary-main-grey') {
        buttonProperty = `bg-white text-gray-500  border-2 border-gray-500 rounded-md ${
            props?.buttonMargin ? props.buttonMargin : 'mr-5'
        }`;
    } else if (props.buttonType === 'delete') {
        buttonProperty = 'bg-red-500 text-white rounded-lg';
    }

    return (
        <button
            onClick={props.onclick}
            id={props.id}
            disabled={props?.disabled}
            type={props.type}
            className={`${buttonProperty} justify-center   ${width}  ${
                props.padding || 'py-4'
            } font-poppins  text-center font-bold ${props.other}  ${
                props?.disabled ? 'bg-opacity-20 cursor-not-allowed' : null
            } `}
        >
            {props.name}
        </button>
    );
};
