import { gql } from '@apollo/client';

export const FETCH_DEALS = gql`
    query (
        $filter: AllDealInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        deals(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            deals {
                id
                dealName
                dealPercentage
                startDateTime
                endDateTime
                status
                productCount
            }
            currentPage
            totalDeals
            totalFilteredDeals
            totalPages
            totalOngoingDeals
            totalEndedDeals
            totalScheduledDeals
        }
    }
`;

export const IS_DEAL_NAME_EXISTS = gql`
    query IsDealNameExists($dealName: String!) {
        isDealNameExists(dealName: $dealName)
    }
`;

export const GET_DEAL_ITEMS = gql`
    query (
        $partnerIds: [ID]!
        $paymentChannelIds: [ID]!
        $searchText: String
        $dealDateInput: DealDateInputDto
    ) {
        dealItemsByPartnerIdsAndPaymentChannelIds(
            partnerIds: $partnerIds
            paymentChannelIds: $paymentChannelIds
            searchText: $searchText
            dealDateInput: $dealDateInput
        ) {
            id
            partnerId
            catalogId
            catalogName
            partnerName
            categories {
                id
                categoryName
                productGroups {
                    id
                    productGroupName
                    items {
                        id
                        itemId
                        itemName
                        sourceCurrency
                        unitPrice
                        isAlreadyInDeal
                    }
                }
            }
        }
    }
`;

export const FETCH_DEAL_BY_ID = gql`
    query ($dealId: ID!) {
        deal(dealId: $dealId) {
            id
            dealName
            dealPercentage
            startDateTime
            endDateTime
            finalPriceRoundOffBoundary
            finalPriceRoundOffDecimalPlace
            isActive
            dealStatus
        }
    }
`;

export const SAVE_DEAL = gql`
    mutation ($dealInput: DealInput!) {
        saveDeal(dealInput: $dealInput) {
            id
        }
    }
`;

export const FETCH_SELECTED_PARTNERS_BY_DEAL_ID = gql`
    query ($dealId: ID!) {
        dealSelectedPartners(dealId: $dealId) {
            id
            partnerId
            partnerName
            country
            localCurrency
            partnerUrl
        }
    }
`;

export const FETCH_SELECTED_PAYMENT_CHANNELS_BY_DEAL_ID = gql`
    query ($dealId: ID!) {
        dealSelectedPaymentChannels(dealId: $dealId) {
            paymentChannels {
                id
                channelId
                channelName
                paymentMethod
                description
                isActive
            }
        }
    }
`;

export const FETCH_DEAL_LOG_BY_ID = gql`
    query ($dealId: ID!, $offset: Int, $limit: Int) {
        dealLog(dealId: $dealId, offset: $offset, limit: $limit) {
            dealLogs {
                id
                dealId
                itemId
                itemName
                description
                createdDate
                initiatedBy
            }
            currentPage
            totalFilteredDealLogs
            totalPages
        }
    }
`;

export const FETCH_DEAL_PARTNER_VALIDATION_BY_CATALOG_ID = gql`
    query ($catalogId: ID!) {
        validatePartnerDeal(catalogId: $catalogId)
    }
`;

// Query to fetch selected items for a deal
export const GET_DEAL_SELECTED_ITEMS = gql`
    query getDealSelectedItems($dealId: ID!) {
        dealSelectedPartnerItems(dealId: $dealId) {
            catalogId
            catalogName
            itemId
            itemName
        }
    }
`;
