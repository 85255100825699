import { Conversion } from '../types/currencyConversions/currencyAndConversionTypes';

export const getConversionRateFormat = (
    sourceCurrency: string,
    targetCurrency: string,
    conversionRate: number,
) => {
    return conversionRate
        ? `1 ${sourceCurrency} - ${targetCurrency} ${conversionRate}`
        : `Conversion Rate beetween ${sourceCurrency} and ${targetCurrency} not set`;
};

/**
 *  This method is used to update and add edit an array of conversion type which is used for bulk update Input of mutation
 */

export const updateConversionData = (
    currentData: Conversion[],
    sourceCurrency: string,
    targetCurrency: string,
    conversionRate: number,
): Conversion[] => {
    const index = currentData.findIndex(
        (conversion) =>
            conversion.sourceCurrency === sourceCurrency &&
            conversion.targetCurrency === targetCurrency,
    );

    if (index !== -1) {
        const updatedData = [...currentData];
        updatedData[index].conversionRate = conversionRate;
        return updatedData;
    } else {
        return [...currentData, { sourceCurrency, targetCurrency, conversionRate }];
    }
};

export const updateMarkedUpPercentageData = (
    currentData: {
        paymentChannelId: number;
        isEnabled: boolean;
        markedUpPercentage: number;
    }[],
    paymentChannelId: number,
    isEnabled: boolean,
    markedUpPercentage: number,
): {
    paymentChannelId: number;
    isEnabled: boolean;
    markedUpPercentage: number;
}[] => {
    const index = currentData.findIndex((entry) => entry.paymentChannelId === paymentChannelId);

    if (index !== -1) {
        // Update existing object
        const updatedData = [...currentData];
        updatedData[index] = {
            ...updatedData[index],
            isEnabled,
            markedUpPercentage,
        };
        return updatedData;
    } else {
        // Add new object
        return [...currentData, { paymentChannelId, isEnabled, markedUpPercentage }];
    }
};
