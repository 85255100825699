import { useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_PAYMENT_CHANNELS_FOR_DEALS } from '../../../../queries/PaymentChannelQueries';
import DataTable from '../../../ui/organisms/DataTable';
import TableTabView from '../../productScreens/TableTabView';
import { Buttons } from '../../../ui/atoms/Button';
import { useDealForm } from '../../../../contexts/DealFormContext';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import Loader from '../../../../utils/loader';
import SearchBar from '../../../ui/molecules/SearchBar';
import { pagination } from '../../../../constants/config/constants';

export default function SelectPaymentChannels() {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [initialsTableData, setInitialTableData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [initialCheckedChannels, setInitialCheckedChannels] = useState({});
    const [checkedRowIds, setCheckedRowIds] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const { formData, updateFormData } = useDealForm();
    const [selectedPaymentChannels, setSelectedPaymentChannels] = useState(
        formData.selectedPaymentChannels,
    );
    const [warningMessage, setWarningMessage] = useState<string>();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);

    const [fetchPaymentChannelsData, { loading: paymentChannelsLoading }] = useLazyQuery(
        FETCH_PAYMENT_CHANNELS_FOR_DEALS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setSelectedPaymentChannels(formData.selectedPaymentChannels);
                setTableData(data.paymentChannelsByPartners.paymentChannels);
                setInitialTableData(data.paymentChannelsByPartners.paymentChannels);
                setTotalItems(data.paymentChannelsByPartners.totalPaymentChannels);
            },
        },
    );

    const paymentChannelNames = tableData?.map((paymentChannel) => paymentChannel?.channelName);
    const paymentChannelIds = tableData?.map((paymentChannel) => paymentChannel?.channelId);
    const paymentChannelSearchOptions = [...new Set(paymentChannelNames.concat(paymentChannelIds))];

    useEffect(() => {
        const selectedPartners = [...formData.selectedPartners];
        if (selectedPartners.length > 0) {
            fetchPaymentChannelsData({
                variables: {
                    partnerIds: selectedPartners,
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: null,
                    order: null,
                    searchText: searchClicked && searchTerm?.length > 1 ? searchTerm : null,
                },
            });
        }
    }, [formData.selectedPartners, searchTerm, pageSize, currentPage]);

    useEffect(() => {
        const checkedList = {};
        if (selectedPaymentChannels) {
            selectedPaymentChannels.forEach((rowItem) => {
                checkedList[rowItem?.id] = true;
            });
        }
        setInitialCheckedChannels(checkedList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentChannels]);

    useEffect(() => {
        if (initialsTableData.length > 0) {
            const filteredChannels = initialsTableData.filter(
                (channel) => checkedRowIds[channel.id],
            );
            setSelectedPaymentChannels(filteredChannels);
            updateFormData({ selectedPaymentChannels: filteredChannels });
        }
    }, [checkedRowIds, initialsTableData]);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize, searchTerm]);

    const addCheckedChannels = (checkedData) => {
        // Collect the IDs of checked items
        const checkedIds = new Set(checkedData.map((data) => data.id));

        // Filter the tableData to get the checked channels
        const checkedChannels = tableData.filter((channel) => checkedIds.has(channel.id));

        // Update the selected payment channels and the form data
        setSelectedPaymentChannels(checkedChannels);
        updateFormData({ selectedPaymentChannels: checkedChannels });

        // Create a mapping of row IDs for checked items
        const checkedRowIds = checkedChannels.reduce((acc, channel) => {
            acc[channel.id] = true;
            return acc;
        }, {});
        setCheckedRowIds(checkedRowIds);
    };

    const columns = useMemo(
        () => [
            { userId: 'id', Header: 'Channel ID', accessor: 'channelId' },

            {
                id: 'name',
                Header: 'Name',
                accessor: 'channelName',
                Cell: function productGroupCell({ row }) {
                    return (
                        <Link
                            className="hover:underline"
                            rel="noreferrer"
                            target="_blank"
                            to={`/payment-channels/view/${row?.original?.id}`}
                        >
                            {row?.original?.channelName}
                        </Link>
                    );
                },
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'method',
                Header: 'Method',
                accessor: 'paymentMethod',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'isActive',
                Cell: function paymentStatus({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                            }`}
                        >
                            {value ? 'Active' : 'Inactive'}
                        </span>
                    );
                },
            },
        ],
        [],
    );

    const isValid = () => {
        let isValid = true;

        if (selectedPaymentChannels?.length === 0) {
            setWarningMessage('Please select at least one payment channel.');
            setShowWarningModal(true);
            isValid = false;
        }

        return isValid;
    };

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const handleSubmit = () => {
        if (isValid()) {
            history.push('/deals/select-products');
        }
    };

    return (
        <div>
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningMessage}
                warningModalTitle={'Alert'}
            />
            <>
                <div className="text-lg font-poppins font-bold px-8 ml-3">Payment Channels</div>
                <div className="border-2 border-gray-300 rounded-lg ml-10 mt-7 mr-7 mb-24">
                    <div className="w-full">
                        <div className="pl-5">
                            <TableTabView
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                dealPaymentChannelCounts={{
                                    all: tableData.length,
                                    selected: selectedPaymentChannels.length,
                                }}
                            />
                        </div>

                        {selectedTab === 1 && (
                            <>
                                <div
                                    className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 mt-5 ml-3"
                                    id="category_search"
                                >
                                    <SearchBar
                                        id="deal-payment-channel-search-field"
                                        options={paymentChannelSearchOptions}
                                        handleChange={(e, newVal, reason) => {
                                            setSearchClicked(true);
                                            handleChange(e, newVal, reason);
                                        }}
                                        placeHolder={'Search Channel by Name or ID'}
                                    />
                                </div>
                                {paymentChannelsLoading ? (
                                    <div>
                                        <Loader />
                                    </div>
                                ) : tableData?.length > 0 ? (
                                    <div className="mt-5">
                                        <DataTable
                                            columns={columns}
                                            data={tableData}
                                            serverSidePagination={true}
                                            pageCount={pageCount}
                                            setPageIndex={setPageIndex}
                                            setDefaultPageSize={setPageSize}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSizes={pageSize}
                                            loading={paymentChannelsLoading}
                                            selectedTab={setSelectedTab}
                                            totalItems={totalItems}
                                            checkbox={true}
                                            checkboxHeader={true}
                                            initialSelectedChannels={initialCheckedChannels}
                                            setCheckedChannels={addCheckedChannels}
                                            setCheckedRowIds={setCheckedRowIds}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full py-10 text-center">
                                        No active payment channels are available for the selected
                                        partners
                                    </div>
                                )}
                            </>
                        )}
                        {selectedTab === 2 && (
                            <>
                                {selectedPaymentChannels?.length ? (
                                    <div className="mt-8">
                                        <DataTable
                                            columns={columns}
                                            data={selectedPaymentChannels}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full py-10 text-center">
                                        No payment channels have been selected
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
            <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                <Buttons
                    name="Go Back"
                    type="button"
                    buttonType="secondary-border-black"
                    id="cancel-button"
                    size="e-small"
                    other="mr-3"
                    onclick={() => {
                        history.push('/deals/select-partners');
                    }}
                />
                <Buttons
                    name={'Continue'}
                    type="submit"
                    buttonType="primary"
                    id="save-button"
                    size="e-small"
                    onclick={handleSubmit}
                />
            </div>
        </div>
    );
}
