import { Modal } from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import PaymentChannelsDataTable from '../../ui/organisms/PaymentChannelsDataTable';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DataTableLoadingSkeleton } from '../../ui/organisms/skeletons/DataTableLoadingSkeleton';
import EditableCell from '../../ui/molecules/EditableCell';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import {
    getConversionRateFormat,
    updateConversionData,
} from '../../../utils/pricingAndConversions';
import WarningMessageModal from './WarningMessageModal';
import {
    CurrencyConversionDataType,
    CurrencyConversionData,
    MessageType,
} from '../../../types/currencyConversions/currencyAndConversionTypes';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    FETCH_PARTNER_ITEM_CONVERSION_RATES,
    SAVE_ITEM_PARTNER_CURRENCY_CONVERSIONS,
} from '../../../queries/CurrencyConversionQueries';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_ITEM_PARTNER_PRICING_DETAILS } from '../../../queries/ProductQueries';
import Confirmation from './ConfirmationModalWithMessage';
import {
    CURRENCY_CONVERSION_UNSAVED_BODY,
    CURRENCY_CONVERSION_UNSAVED_HEADER,
} from '../../../constants/product';
interface Props {
    showConversionModal: boolean;
    setShowConversionModal: (value: boolean) => void;
    partnerName?: string;
    dataTableLoadingSkeleton?: boolean;
    loading?: boolean;
    handleModalSave: () => void;
    mode: 'view' | 'edit' | 'dealView';
    handleConversionRateReset: (sourceCurrency: string, targetCurrency: string) => void;
    handleEditableCellClick: (row: any) => void;
    handleModalCancelBtn?: () => void;
    selectedPartnerUid: string;
    itemId: string;
    setOnConfirmAction?: (value: React.SetStateAction<() => void>) => void;
}

export const ViewConversionRateModal: React.FC<Props> = ({
    showConversionModal,
    setShowConversionModal,
    partnerName,
    loading,
    handleConversionRateReset,
    handleEditableCellClick,
    mode,
    selectedPartnerUid,
    handleModalSave,
    itemId,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currencyConversionData, setCurrencyConversionData] = useState<
        CurrencyConversionDataType[]
    >([]);
    const currencyConversionInputRef = useRef<CurrencyConversionDataType[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = useState<MessageType>({
        header: null,
        body: null,
    });

    const { loading: dataLoading } = useQuery(FETCH_PARTNER_ITEM_CONVERSION_RATES, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { partnerId: selectedPartnerUid, itemId: itemId },
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data: CurrencyConversionData) => {
            setCurrencyConversionData(data.currencyConversions);
            currencyConversionInputRef.current = [];
        },
    });

    const [overrideCurrencyConversion] = useMutation(SAVE_ITEM_PARTNER_CURRENCY_CONVERSIONS, {
        refetchQueries: [FETCH_PARTNER_ITEM_CONVERSION_RATES, FETCH_ITEM_PARTNER_PRICING_DETAILS],
        context: { clientName: ClientsEnum.STORE },
    });

    const upsertConversion = useCallback(
        (sourceCurrency: string, targetCurrency: string, conversionRate: number): void => {
            const currentData = currencyConversionInputRef.current;
            const updatedData = updateConversionData(
                currentData,
                sourceCurrency,
                targetCurrency,
                conversionRate,
            );
            currencyConversionInputRef.current = updatedData;
        },
        [],
    );

    const handleUpdateConversionRate = useCallback(
        (sourceCurrency: string, targetCurrency: string, conversionRate: number) => {
            upsertConversion(sourceCurrency, targetCurrency, conversionRate);
        },
        [upsertConversion],
    );

    const handleModalSaveClick = () => {
        overrideCurrencyConversion({
            variables: {
                partnerId: selectedPartnerUid,
                itemId: itemId,
                conversions: currencyConversionInputRef.current,
            },
        });

        handleModalSave();
    };

    const getUpdatedConversionFormat = (
        sourceCurrency: string,
        targetCurrency: string,
        conversionRate: number,
    ) => {
        const objectInRef = currencyConversionInputRef.current.find(
            (currencyConversion) =>
                currencyConversion.sourceCurrency == sourceCurrency &&
                currencyConversion.targetCurrency == targetCurrency,
        );

        if (objectInRef) {
            return getConversionRateFormat(
                objectInRef.sourceCurrency,
                objectInRef.targetCurrency,
                objectInRef.conversionRate,
            );
        }

        return getConversionRateFormat(sourceCurrency, targetCurrency, conversionRate);
    };

    const tableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function sourceCurrencyCell({ row }) {
                    const sourceCurrency = row.original?.sourceCurrency;
                    if (!sourceCurrency) return null;
                    return (
                        <div className="flex items-center justify-center">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === sourceCurrency)
                                    ?.country.toLowerCase()} flag`}
                            ></i>
                            <p className="text-gray-600">{sourceCurrency}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function sourceCurrencyCell({ row }) {
                    const targetCurrency = row.original?.targetCurrency;
                    if (!targetCurrency) return null;
                    return (
                        <div className="flex items-center justify-center">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === targetCurrency)
                                    ?.country.toLowerCase()} flag`}
                            ></i>
                            <p className="text-gray-600">{targetCurrency}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion',
                accessor: 'conversionRate',
                Cell: function editModalConversionRate({ row, value }) {
                    const showRedBorder =
                        row?.original?.conversionRateOverriddenLevel !== null &&
                        row?.original?.conversionRateOverriddenLevel !== 'GLOBAL_LEVEL';
                    return (
                        <div className="flex" key={row?.original?.channelId}>
                            <EditableCell
                                id={`${row?.original?.channelId}_modal_conversion_rate`}
                                initialValue={value}
                                row={row}
                                isDisabled={
                                    mode == 'view' ||
                                    mode == 'dealView' ||
                                    row?.original?.sourceCurrency === row?.original?.targetCurrency
                                }
                                isOverride={
                                    showRedBorder &&
                                    row?.original?.sourceCurrency !== row?.original?.targetCurrency
                                }
                                updateValue={(_, value) => {
                                    setIsEditing(true);
                                    handleUpdateConversionRate(
                                        row.original.sourceCurrency,
                                        row.original.targetCurrency,
                                        value,
                                    );
                                }}
                                validationRule={{
                                    allowEmptyFeild: {
                                        enabled: false,
                                        errorMessage: 'conversion is Required',
                                        messageType: 'Alert',
                                    },
                                    allowValuesLessThan0: {
                                        enabled: false,
                                        errorMessage: 'conversion should be greater than 0',
                                        messageType: 'Alert',
                                    },
                                }}
                                changeTextColorOnChange={{
                                    enabled: true,
                                    cellRowAccessor: 'conversionRate',
                                }}
                                key={row?.original?.channelId}
                                onClick={() => {
                                    row?.original?.conversionRateOverriddenLevel ??
                                        handleEditableCellClick(row.original);
                                }}
                            />
                            {row.original.conversionRateOverriddenLevel == 'ITEM_LEVEL' && (
                                <div className="flex px-1">
                                    <button
                                        disabled={mode == 'view' || mode == 'dealView'}
                                        type="reset"
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-default border border-transparent focus:outline-none text-xl"
                                        id={`currencyConversion_reset_${row?.original?.channelId}`}
                                        onClick={() => {
                                            handleConversionRateReset(
                                                row.original.sourceCurrency,
                                                row.original.targetCurrency,
                                            );
                                        }}
                                    >
                                        <BsArrowCounterclockwise
                                            className={`mx-auto ${
                                                mode == 'view' || mode == 'dealView'
                                                    ? 'cursor-default'
                                                    : 'cursor-pointer'
                                            }`}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: ' ',
                accessor: ' ',
                Cell: function sourceCurrencyCell({ row }) {
                    const conversionRateFormat = getUpdatedConversionFormat(
                        row.original.sourceCurrency,
                        row.original.targetCurrency,
                        row.original?.conversionRate,
                    );

                    return (
                        <div className="flex items-center">
                            <p className="text-gray-600">{conversionRateFormat}</p>
                        </div>
                    );
                },
            },
        ],
        [handleConversionRateReset, handleUpdateConversionRate, mode, handleEditableCellClick],
    );

    return (
        <>
            <Modal
                showCloseIcon={false}
                open={showConversionModal}
                onClose={() => {
                    setShowConversionModal(false);
                }}
                center={true}
                styles={{
                    modal: {
                        borderRadius: 20,
                        maxWidth: '706px',
                        width: '80%',
                        padding: 0,
                        margin: 0,
                    },
                }}
                closeOnOverlayClick={false}
                focusTrapped={false}
                aria-labelledby="view currency conversion"
                aria-describedby="view the currency converion for item and partner"
            >
                <div className="w-full">
                    <div className="flex justify-center items-center py-10">
                        <h2 className="font-poppins text-2xl">{`Currency Conversion For Partner : ${partnerName}`}</h2>
                    </div>

                    {dataLoading && loading ? (
                        <DataTableLoadingSkeleton columns={tableColumns} height={'h-60'} />
                    ) : currencyConversionData.length > 0 ? (
                        <PaymentChannelsDataTable
                            columns={tableColumns}
                            data={currencyConversionData}
                            hidePagination={true}
                        />
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {tableColumns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No conversion currency conversion</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}

                    <div className="flex py-4 px-4 mt-10 justify-end">
                        <Buttons
                            name={mode == 'dealView' ? 'Close ' : 'Cancel'}
                            type="submit"
                            buttonType="secondary-main-grey"
                            id="cancel"
                            onclick={() => {
                                if (isEditing) {
                                    setShowConfirmationModal(true);
                                    setConfirmationMessage({
                                        header: CURRENCY_CONVERSION_UNSAVED_HEADER,
                                        body: CURRENCY_CONVERSION_UNSAVED_BODY,
                                    });
                                } else {
                                    setShowConversionModal(false);
                                }
                            }}
                            other="w-48"
                            buttonMargin={null}
                        />
                        {mode != 'dealView' ? (
                            <Buttons
                                name="Save"
                                type={'submit'}
                                buttonType="primary"
                                id="update"
                                other="w-48"
                                onclick={() => {
                                    setShowConversionModal(false);
                                    handleModalSaveClick();
                                    setIsEditing(false);
                                }}
                                disabled={!isEditing}
                            />
                        ) : null}
                    </div>
                </div>
            </Modal>

            <Confirmation
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                messageTitle={confirmationMessage.header}
                message={confirmationMessage.body}
                setYesBtnClick={() => {
                    setShowConversionModal(false);
                    setShowConfirmationModal(false);
                    setIsEditing(false);
                }}
                setNoBtnClick={() => {
                    setShowConfirmationModal(false);
                }}
            />
        </>
    );
};
