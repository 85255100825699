import { Modal } from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import { Formik } from 'formik';
import { InputField } from '../../ui/atoms/InputField';
import * as Yup from 'yup';

interface Props {
    showPaymentModalModal: boolean;
    setShowPaymentMethodModal: (value: boolean) => void;
    setPaymentMethodName?: any;
    existingPaymentMethods?: string[];
}

export default function AddPaymentMethodModal({
    setShowPaymentMethodModal,
    showPaymentModalModal,
    setPaymentMethodName,
    existingPaymentMethods,
}: Props) {
    const existingPaymentMethodsLowerCase = existingPaymentMethods.map((method) =>
        method.toLowerCase(),
    );

    const paymentMethodValidationSchema = Yup.object().shape({
        methodName: Yup.string()
            .trim()
            .max(50, 'Name cannot be more than 50 characters')
            .required('Method name is required')
            .test('is-unique', 'Method name already exists.', function (value) {
                const { path, createError } = this;
                if (existingPaymentMethodsLowerCase?.includes(value?.toLowerCase().trim())) {
                    return createError({ path, message: 'Method name already exists.' });
                }
                return true;
            }),
    });

    return (
        <Modal
            closeIcon={
                <div
                    style={{
                        backgroundColor: 'lightgray',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '1.5rem',
                        height: '1.5rem',
                    }}
                >
                    <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
            }
            open={showPaymentModalModal}
            onClose={() => {
                setShowPaymentMethodModal(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 20, maxWidth: '706px', width: '25%' } }}
        >
            <div className="mx-6">
                <div className="flex items-start justify-between py-4">
                    <div className="text-xl font-poppins font-bold">Add Method</div>
                </div>
                <div>
                    <Formik
                        initialValues={{ methodName: '' }}
                        onSubmit={({ methodName }) => {
                            setPaymentMethodName(methodName);
                        }}
                        validationSchema={paymentMethodValidationSchema}
                    >
                        {({ values, errors, touched, handleChange, handleSubmit }) => (
                            <>
                                <div className="grid grid-cols-6">
                                    <div className="flex flex-wrap col-start-1 col-end-7 flex-col py-4 mb-4">
                                        <InputField
                                            placeHolder="Enter method name"
                                            name="Method Name"
                                            id="paymentId"
                                            onChange={handleChange('methodName')}
                                        />
                                        {errors.methodName ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.methodName}
                                            </div>
                                        ) : null}

                                        {errors.methodName && touched.methodName
                                            ? window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: 'smooth',
                                              })
                                            : null}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-arounds py-4">
                                    <Buttons
                                        name="Cancel"
                                        type="button"
                                        buttonType="secondary-main-grey"
                                        id="cancel"
                                        size="large-full"
                                        other="mr-6"
                                        onclick={() => setShowPaymentMethodModal(false)}
                                    />
                                    <Buttons
                                        name="Add Method"
                                        type="submit"
                                        buttonType="primary"
                                        id="update"
                                        size="large-full"
                                        onclick={() => handleSubmit()}
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}
