import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Images } from '../ui/atoms/Images';
import { PRODUCTS_EXLCUDE_URLS, SWAR_MVP_HIDE_FEATURE } from '../../constants/config/constants';
import { TbDiscount } from 'react-icons/tb';

export default function SideBarElements() {
    const history = useHistory();

    const [clickedItem, setClickedItem] = useState('');
    const [products, setProducts] = useState(true);
    const [orders, setOrders] = useState(true);
    const onclickFunction = (props: any) => {
        return history.push(`/${props}`);
    };

    const pathSelected = window?.location?.pathname;

    function containsExcludedUrl(pathUrl: string, exludedUrls: string[]): boolean {
        return exludedUrls.some((exludeUrl) => pathUrl.includes(exludeUrl));
    }

    return (
        <div>
            <div
                id="sidebar-dashboard-button"
                className={`mb-3 mt-7 hover:bg-purple-400 w-full ${
                    pathSelected === '/dashboard' ? 'bg-purple-400' : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => onclickFunction(`dashboard`)}
                onKeyDown={() => onclickFunction(`dashboard`)}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height=" hidden md:block"
                        src="/images/icons/dashboard.png"
                        alt="Dashboard"
                    />

                    <div className="mx-5 font-poppins text-sm">Dashboard </div>
                </div>
            </div>

            <div
                id="sidebar-reports-button"
                className={`mb-3  hover:bg-purple-400 w-full ${
                    clickedItem === 'reports' ||
                    pathSelected === '/reports' ||
                    pathSelected === '/reports/overview' ||
                    pathSelected === '/reports/orders' ||
                    pathSelected === '/reports/inventory'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => setClickedItem(`reports`)}
                onKeyDown={() => setClickedItem(`reports`)}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height="hidden md:block"
                        src="/images/icons/reports.svg"
                        alt="Reports"
                    />

                    <div className="mx-5 font-poppins text-sm">Reports</div>
                </div>
            </div>

            {clickedItem === 'reports' ||
            pathSelected === '/reports/overview' ||
            pathSelected === '/reports/products' ||
            pathSelected === '/reports/partners' ||
            pathSelected === '/reports/suppliers' ||
            pathSelected === '/reports/orders' ||
            pathSelected === '/reports/inventory' ? (
                <>
                    {!SWAR_MVP_HIDE_FEATURE && (
                        <>
                            <div
                                role="button"
                                tabIndex={0}
                                className={` ${
                                    pathSelected === `/reports/overview`
                                        ? 'border-purple-400  border-l-4'
                                        : null
                                } flex flex-row h-10 pt-2 hover:bg-transparent`}
                                onClick={() => {
                                    onclickFunction(`reports/overview`);
                                }}
                                onKeyDown={() => onclickFunction(`reports/overview`)}
                            >
                                <div className="justify-center font-poppins text-sm ml-14">
                                    Overview
                                </div>
                            </div>
                            <div
                                role="button"
                                tabIndex={0}
                                className={` ${
                                    pathSelected === `/reports/products`
                                        ? 'border-purple-400  border-l-4'
                                        : null
                                } flex flex-row h-10 pt-2 hover:bg-transparent`}
                                onClick={() => {
                                    onclickFunction(`reports/products`);
                                }}
                                onKeyDown={() => onclickFunction(`reports/products`)}
                            >
                                <div className="justify-center font-poppins text-sm ml-14">
                                    Products
                                </div>
                            </div>
                            <div
                                role="button"
                                tabIndex={0}
                                className={` ${
                                    pathSelected === `/reports/partners`
                                        ? 'border-purple-400  border-l-4'
                                        : null
                                } flex flex-row h-10 pt-2 hover:bg-transparent`}
                                onClick={() => {
                                    onclickFunction(`reports/partners`);
                                }}
                                onKeyDown={() => onclickFunction(`reports/partners`)}
                            >
                                <div className="justify-center font-poppins text-sm ml-14">
                                    Partners
                                </div>
                            </div>
                            <div
                                role="button"
                                tabIndex={0}
                                className={` ${
                                    pathSelected === `/reports/suppliers`
                                        ? 'border-purple-400  border-l-4'
                                        : null
                                } flex flex-row h-10 pt-2 hover:bg-transparent`}
                                onClick={() => {
                                    onclickFunction(`reports/suppliers`);
                                }}
                                onKeyDown={() => onclickFunction(`reports/suppliers`)}
                            >
                                <div className="justify-center font-poppins text-sm ml-14">
                                    Suppliers
                                </div>
                            </div>{' '}
                        </>
                    )}
                    <div
                        id="sidebar-orders-report-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            pathSelected === `/reports/orders`
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`reports/orders`);
                        }}
                        onKeyDown={() => onclickFunction(`reports/orders`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            Orders report
                        </div>
                    </div>
                    <div
                        id="sidebar-inventory-report-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            pathSelected === `/reports/inventory`
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`reports/inventory`);
                        }}
                        onKeyDown={() => onclickFunction(`reports/inventory`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            Inventory report
                        </div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-partners-button"
                className={`mb-3  hover:bg-purple-400 w-full ${
                    clickedItem === 'partners' ||
                    pathSelected === '/partners' ||
                    pathSelected === '/partner/general-details-edit' ||
                    pathSelected === '/partner/agreement-details-edit' ||
                    pathSelected === '/partner/view/catalog/setup' ||
                    pathSelected === '/partner/view/item/setup' ||
                    pathSelected === '/partner/general-details-add' ||
                    pathSelected === '/partner/agreement-details-add' ||
                    pathSelected === '/partner/general-details-view' ||
                    pathSelected === '/partner/agreement-details-view' ||
                    pathSelected === '/partner/edit/catalog/setup' ||
                    pathSelected === '/partner/edit/item/setup' ||
                    pathSelected === '/partner/currency-preferences-add' ||
                    pathSelected === '/partner/edit/currency-preferences' ||
                    pathSelected === '/partner/view/currency-preferences' ||
                    pathSelected === '/partner/payment-channel/marked-up-percentage-view' ||
                    pathSelected === '/partner/payment-channel/marked-up-percentage-edit' ||
                    pathSelected === '/partner/payment-channel/marked-up-percentage-add'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => setClickedItem('partners')}
                onKeyDown={() => setClickedItem('partners')}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height=" hidden md:block"
                        src="/images/icons/partners.svg"
                        alt="Partners"
                    />

                    <div className="mx-5 font-poppins text-sm">Partners</div>
                </div>
            </div>
            {clickedItem === 'partners' ||
            pathSelected === '/partners' ||
            pathSelected === '/partner/general-details-edit' ||
            pathSelected === '/partner/agreement-details-edit' ||
            pathSelected === '/partner/edit/catalog/setup' ||
            pathSelected === '/partner/general-details-add' ||
            pathSelected === '/partner/agreement-details-add' ||
            pathSelected === '/partner/general-details-view' ||
            pathSelected === '/partner/agreement-details-view' ||
            pathSelected === '/partner/view/catalog/setup' ||
            pathSelected === '/partner/view/item/setup' ||
            pathSelected === '/partner/edit/item/setup' ||
            pathSelected === '/partner/currency-preferences-add' ||
            pathSelected === '/partner/edit/currency-preferences' ||
            pathSelected === '/partner/view/currency-preferences' ||
            pathSelected === '/partner/payment-channel/marked-up-percentage-view' ||
            pathSelected === '/partner/payment-channel/marked-up-percentage-edit' ||
            pathSelected === '/partner/payment-channel/marked-up-percentage-add' ? (
                <>
                    <div
                        id="sidebar-all-partners-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            pathSelected === '/partners' ||
                            pathSelected === '/partner/general-details-edit' ||
                            pathSelected === '/partner/agreement-details-edit' ||
                            pathSelected === '/partner/edit/catalog/setup' ||
                            pathSelected === '/partner/general-details-add' ||
                            pathSelected === '/partner/agreement-details-add' ||
                            pathSelected === '/partner/general-details-view' ||
                            pathSelected === '/partner/agreement-details-view' ||
                            pathSelected === '/partner/view/catalog/setup' ||
                            pathSelected === '/partner/view/item/setup' ||
                            pathSelected === '/partner/edit/item/setup' ||
                            pathSelected === '/partner/currency-preferences-add' ||
                            pathSelected === '/partner/edit/currency-preferences' ||
                            pathSelected === '/partner/view/currency-preferences' ||
                            pathSelected === '/partner/payment-channel/marked-up-percentage-view' ||
                            pathSelected === '/partner/payment-channel/marked-up-percentage-edit' ||
                            pathSelected === '/partner/payment-channel/marked-up-percentage-add'
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`partners`);
                        }}
                        onKeyDown={() => onclickFunction(`partners`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            All partners
                        </div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-suppliers-button"
                className={`mb-3 mt-3 hover:bg-purple-400 w-full ${
                    clickedItem === 'suppliers' ||
                    pathSelected === '/suppliers' ||
                    pathSelected === '/suppliers-view' ||
                    (pathSelected.includes('/suppliers-view') &&
                        pathSelected.includes('/product') &&
                        pathSelected.includes('/vouchers')) ||
                    pathSelected === '/suppliers-edit-points' ||
                    (pathSelected.includes('/suppliers-view') && pathSelected.includes('/file')) ||
                    pathSelected === '/suppliers-edit' ||
                    pathSelected === '/suppliers/points-add' ||
                    pathSelected === '/suppliers/general-add'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setClickedItem('suppliers');
                }}
                onKeyDown={() => {
                    setClickedItem('suppliers');
                }}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height="h-6 hidden md:block"
                        src="/images/icons/suppliers.svg"
                        alt="Suppliers"
                    />

                    <div className="mx-5 font-poppins text-sm">Suppliers</div>
                </div>
            </div>

            {clickedItem === 'suppliers' ||
            pathSelected === '/suppliers' ||
            pathSelected === '/suppliers-view' ||
            (pathSelected.includes('/suppliers-view') &&
                pathSelected.includes('/product') &&
                pathSelected.includes('/vouchers')) ||
            pathSelected === '/suppliers-edit-points' ||
            (pathSelected.includes('/suppliers-view') && pathSelected.includes('/file')) ||
            pathSelected === '/suppliers-edit' ||
            pathSelected === '/suppliers/points-add' ||
            pathSelected === '/suppliers/general-add' ? (
                <>
                    <div
                        id="sidebar-all-suppliers-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            clickedItem === 'suppliers' ||
                            pathSelected === '/suppliers' ||
                            pathSelected === '/suppliers-view' ||
                            (pathSelected.includes('/suppliers-view') &&
                                pathSelected.includes('/product') &&
                                pathSelected.includes('/vouchers')) ||
                            pathSelected === '/suppliers-edit-points' ||
                            (pathSelected.includes('/suppliers-view') &&
                                pathSelected.includes('/file')) ||
                            pathSelected === '/suppliers-edit' ||
                            pathSelected === '/suppliers/points-add' ||
                            pathSelected === '/suppliers/general-add'
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`suppliers`);
                        }}
                        onKeyDown={() => onclickFunction(`suppliers`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            All suppliers
                        </div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-products-button"
                role="button"
                tabIndex={0}
                onClick={() => {
                    setProducts(true);
                    setClickedItem('products');
                }}
                onKeyDown={() => {
                    setProducts(true);
                    setClickedItem('products');
                }}
                className={`mb-3 mt-3 hover:bg-purple-400 w-full ${
                    pathSelected === '/adminProfile/categories' ||
                    (pathSelected.includes('/products') &&
                        !containsExcludedUrl(pathSelected, PRODUCTS_EXLCUDE_URLS)) ||
                    clickedItem === '/products' ||
                    clickedItem === 'products/categories' ||
                    clickedItem === 'products/groups' ||
                    clickedItem === '/products/groups/add' ||
                    pathSelected.includes('/products/groups/view') ||
                    pathSelected.includes('/products/groups/edit')
                        ? 'bg-purple-400'
                        : null
                }`}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height="h-5 hidden md:block"
                        src="/images/icons/products.svg"
                        alt="Products"
                    />

                    <div className="mx-5 font-poppins text-sm">Products</div>
                </div>
            </div>

            {(products && clickedItem === 'products') ||
            (pathSelected.includes('/products') &&
                !containsExcludedUrl(pathSelected, PRODUCTS_EXLCUDE_URLS)) ? (
                <>
                    <div
                        id="sidebar-all-products-button"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            onclickFunction(`products`);
                        }}
                        onKeyDown={() => {
                            onclickFunction(`products`);
                        }}
                        className={` ${
                            pathSelected.includes(`/products`) &&
                            !pathSelected.includes('/products/categories') &&
                            !pathSelected.includes('/products/groups')
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            All products
                        </div>
                    </div>
                    <div
                        id="sidebar-all-groups-button"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            onclickFunction(`products/groups`);
                        }}
                        onKeyDown={() => {
                            onclickFunction(`products/groups`);
                        }}
                        className={` ${
                            pathSelected === `/products/groups` ||
                            pathSelected === `/products/groups/add` ||
                            pathSelected.includes(`/products/groups/view`) ||
                            pathSelected.includes(`/products/groups/edit`)
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">All groups</div>
                    </div>
                    <div
                        id="sidebar-all-categories-button"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            onclickFunction(`products/categories`);
                        }}
                        onKeyDown={() => {
                            onclickFunction(`products/categories`);
                        }}
                        className={` ${
                            pathSelected === `/products/categories` ||
                            pathSelected === '/products/categories/add' ||
                            pathSelected === '/products/categories/edit' ||
                            pathSelected === '/products/categories/view'
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            All categories
                        </div>
                    </div>
                    {!SWAR_MVP_HIDE_FEATURE && (
                        <div
                            role="button"
                            tabIndex={0}
                            className="flex flex-row h-10 pt-2 hover:bg-transparent"
                        >
                            <div className="justify-center font-poppins text-sm ml-14">
                                Attributes
                            </div>
                        </div>
                    )}
                </>
            ) : null}

            <div
                id="sidebar-orders-button"
                className={`mb-3 mt-3 hover:bg-purple-400 w-full ${
                    pathSelected === '/orders' ||
                    pathSelected === '/orders/failedTransactions' ||
                    clickedItem === 'orders' ||
                    clickedItem === 'orders/failedTransactions'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setOrders(true);
                    setClickedItem('orders');
                }}
                onKeyDown={() => {
                    setOrders(true);
                    setClickedItem(`orders`);
                }}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images height="hidden md:block" src="/images/icons/orders.svg" alt="Orders" />

                    <div className="mx-5 font-poppins text-sm">Orders</div>
                </div>
            </div>
            {(orders && clickedItem === 'orders') ||
            pathSelected === '/orders' ||
            clickedItem === 'orders/failedTransactions' ||
            pathSelected === '/orders/failedTransactions' ? (
                <>
                    <div
                        id="sidebar-all-ordes-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            pathSelected === '/orders' &&
                            !pathSelected.includes('/orders/failedTransactions')
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`orders`);
                        }}
                        onKeyDown={() => onclickFunction(`orders`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">All orders</div>
                    </div>
                    <div
                        id="sidebar-failed-transactions-button"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            onclickFunction(`orders/failedTransactions`);
                        }}
                        onKeyDown={() => {
                            onclickFunction(`orders/failedTransactions`);
                        }}
                        className={` ${
                            pathSelected === `/orders/failedTransactions`
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            Failed Transactions
                        </div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-deals-button"
                className={`mb-3 mt-3 hover:bg-purple-400 w-full ${
                    pathSelected.includes('/deal') || clickedItem === 'deals'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setOrders(true);
                    setClickedItem('deals');
                }}
                onKeyDown={() => {
                    setOrders(true);
                    setClickedItem(`deals`);
                }}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height="w-6 h-6 hidden md:block"
                        src="/images/icons/deals.svg"
                        alt="Deals"
                    />
                    <div className="mx-5 font-poppins text-sm mt-0.5">Deals</div>
                </div>
            </div>
            {pathSelected.includes('/deal') || clickedItem === 'deals' ? (
                <>
                    <div
                        id="sidebar-all-deals-button"
                        role="button"
                        tabIndex={0}
                        className={`${
                            [
                                '/deals',
                                '/deals/general-details-add',
                                '/deals/select-products',
                                '/deals/select-partners',
                                '/deal/selected-partners-view',
                                '/deal/selected-payment-channels-view',
                                '/deal/products-edit',
                                '/deal/general-details-edit',
                            ].some((path) => pathSelected.startsWith(path))
                                ? 'border-purple-400 border-l-4'
                                : ''
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`deals`);
                        }}
                        onKeyDown={() => onclickFunction(`deals`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">All Deals</div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-payment-channels-button"
                className={`mb-3  hover:bg-purple-400 w-full ${
                    clickedItem === 'payment-channels' ||
                    pathSelected === '/payment-channels' ||
                    pathSelected === '/payment-channels/add' ||
                    pathSelected.includes('/payment-channels/view') ||
                    pathSelected.includes('payment-channels/edit')
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => setClickedItem('payment-channels')}
                onKeyDown={() => setClickedItem('payment-channels')}
            >
                <div className="flex flex-row  h-10 mx-6 pt-2">
                    <Images
                        height=" hidden md:block"
                        src="/images/icons/payment-channel.svg"
                        alt="payment-channels"
                    />

                    <div className="mx-5 font-poppins text-sm">Payment Channels</div>
                </div>
            </div>
            {clickedItem === 'payment-channels' ||
            pathSelected === '/payment-channels' ||
            pathSelected === '/payment-channels/add' ||
            pathSelected.includes('/payment-channels/view') ||
            pathSelected.includes('payment-channels/edit') ? (
                <>
                    <div
                        id="sidebar-all-payment-channels-button"
                        role="button"
                        tabIndex={0}
                        className={` ${
                            clickedItem === 'payment-channels' ||
                            pathSelected === '/payment-channels' ||
                            pathSelected === '/payment-channels/add' ||
                            pathSelected.includes('/payment-channels/view') ||
                            pathSelected.includes('payment-channels/edit')
                                ? 'border-purple-400  border-l-4'
                                : null
                        } flex flex-row h-10 pt-2 hover:bg-transparent`}
                        onClick={() => {
                            onclickFunction(`payment-channels`);
                        }}
                        onKeyDown={() => onclickFunction(`payment-channels`)}
                    >
                        <div className="justify-center font-poppins text-sm ml-14">
                            All Payment Channels
                        </div>
                    </div>
                </>
            ) : null}

            <div
                id="sidebar-users-button"
                className={`mb-3 mt-3 hover:bg-purple-400 w-full ${
                    clickedItem === 'users' ||
                    pathSelected === '/users' ||
                    pathSelected === '/users/view' ||
                    pathSelected === '/users/add-user' ||
                    pathSelected === '/users/account-details-add' ||
                    pathSelected === '/users/personal-details-add'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setClickedItem('users');
                    onclickFunction(`users`);
                }}
                onKeyDown={() => {
                    setClickedItem('users');
                    onclickFunction(`users`);
                }}
            >
                <div className="flex flex-row hover:bg-purple-400 h-10 mx-6 pt-2">
                    <Images height="hidden md:block" src="/images/icons/users.svg" alt="Users" />

                    <div className="mx-5 font-poppins text-sm">Users</div>
                </div>
            </div>

            <div
                id="sidebar-currency-button"
                className={`my-3 hover:bg-purple-400 w-full ${
                    clickedItem === 'currencyConversion' || pathSelected === '/currencyConversion'
                        ? 'bg-purple-400'
                        : null
                }`}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setClickedItem('currencyConversion');
                    onclickFunction(`currencyConversion`);
                }}
                onKeyDown={() => onclickFunction(`currencyConversion`)}
            >
                <div className="flex flex-row h-10 mx-6 pt-2">
                    <Images
                        height="hidden md:block"
                        src="/images/icons/money-finder.svg"
                        alt="Currency"
                    />

                    <div className="mx-5 font-poppins text-sm">Currency Conversions</div>
                </div>
            </div>
            {clickedItem === 'currencyConversion' ||
                (pathSelected === '/currencyConversion' && (
                    <>
                        <div
                            id="sidebar-all-currency-button"
                            role="button"
                            tabIndex={0}
                            className={` ${
                                clickedItem === 'currencyConversion' ||
                                pathSelected === '/currencyConversion'
                                    ? 'border-purple-400 border-l-4'
                                    : null
                            } flex flex-row h-10 pt-2 hover:bg-transparent`}
                            onClick={() => {
                                onclickFunction(`currencyConversion`);
                            }}
                            onKeyDown={() => onclickFunction(`currencyConversion`)}
                        >
                            <div className="justify-center font-poppins text-sm ml-14">
                                Currency Conversions
                            </div>
                        </div>
                    </>
                ))}

            {!SWAR_MVP_HIDE_FEATURE && (
                <div
                    className="mb-3 mt-3 hover:bg-purple-400 w-full"
                    role="button"
                    tabIndex={0}
                    onClick={() => onclickFunction(`help`)}
                    onKeyDown={() => onclickFunction(`help`)}
                >
                    <div className="flex flex-row  h-10 mx-6 pt-2">
                        <Images height=" hidden md:block" src="/images/icons/help.png" alt="Help" />

                        <div className="mx-4 font-poppins text-sm">Help</div>
                    </div>
                </div>
            )}
            {!SWAR_MVP_HIDE_FEATURE && (
                <div
                    className="mb-3 mt-3 hover:bg-purple-400 w-full"
                    role="button"
                    tabIndex={0}
                    onClick={() => onclickFunction(`settings`)}
                    onKeyDown={() => onclickFunction(`settings`)}
                >
                    <div className="flex flex-row  h-10 mx-6 pt-2">
                        <Images
                            height=" hidden md:block"
                            src="/images/icons/settings.svg"
                            alt="Settings"
                        />

                        <div className="mx-3 font-poppins text-sm">Settings</div>
                    </div>
                </div>
            )}
        </div>
    );
}
