import { gql } from '@apollo/client';

export const FETCH_PAYMENT_CHANNELS = gql`
    query (
        $filter: PaymentChannelFilterInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        paymentChannels(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            paymentChannels {
                id
                channelId
                channelName
                description
                paymentMethod
                isActive
                markedUpPercentage
            }
            currentPage
            totalPaymentChannels
            totalFilteredPaymentChannels
            totalPages
            totalActivePaymentChannels
            totalInactivePaymentChannels
        }
    }
`;

export const FETCH_PAYMENT_CHANNELS_FOR_DEALS = gql`
    query (
        $partnerIds: [ID!]
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        paymentChannelsByPartners(
            partnerIds: $partnerIds
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            paymentChannels {
                id
                channelId
                channelName
                description
                paymentMethod
                isActive
            }
            currentPage
            totalPaymentChannels
            totalFilteredPaymentChannels
            totalPages
            totalActivePaymentChannels
            totalInactivePaymentChannels
        }
    }
`;

export const ADD_PAYMENT_CHANNEL = gql`
    mutation ($paymentChannel: PaymentChannelInput!) {
        savePaymentChannel(paymentChannel: $paymentChannel) {
            id
            channelId
            channelName
        }
    }
`;

export const GET_PAYMENT_CHANNEL_BY_ID = gql`
    query ($paymentChannelId: ID!) {
        paymentChannel(paymentChannelId: $paymentChannelId) {
            id
            channelId
            channelName
            description
            paymentMethod
            isActive
            markedUpPercentage
        }
    }
`;

export const FETCH_PARTNER_ITEM_PAYMENT_CHANNELS = gql`
    query ($partnerId: ID!, $itemId: ID!, $paymentType: PaymentMethod) {
        partnerItemPaymentChannels(
            partnerId: $partnerId
            itemId: $itemId
            paymentType: $paymentType
        ) {
            id
            channelId
            paymentName
            isEnabled
            globallyEnabled
        }
    }
`;

export const UPDATE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS = gql`
    mutation ($updateDisabledPaymentChannelInput: UpdateDisabledPaymentChannelInput) {
        updateDisabledPaymentChannels(
            updateDisabledPaymentChannelInput: $updateDisabledPaymentChannelInput
        ) {
            partnerId
            itemId
            paymentType
            disabledPaymentChannels {
                id
                channelId
                paymentName
                isEnabled
                globallyEnabled
            }
        }
    }
`;

export const FETCH_DISTINCT_PAYMENT_METHODS = gql`
    query {
        distinctPaymentMethods
    }
`;

export const GET_PAYMENT_CHANNEL_DATA_BY_PARTNER_ID = gql`
    query ($partnerId: ID) {
        paymentChannelsDataByPartnerId(partnerId: $partnerId) {
            id
            channelName
            active
            overridden
            markedUpPercentage
        }
    }
`;

export const SAVE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS = gql`
    mutation ($partnerPaymentChannelDataInput: PartnerPaymentChannelDataInput) {
        savePartnerPaymentChannelData(
            partnerPaymentChannelDataInput: $partnerPaymentChannelDataInput
        ) {
            partnerId
            paymentChannelId
        }
    }
`;

export const RESET_PARTNER_PAYMENT_CHANNEL_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $paymentChannelId: ID!) {
        resetPartnerPaymentChannelMarkedUpPercentage(
            partnerId: $partnerId
            paymentChannelId: $paymentChannelId
        )
    }
`;

export const RESET_PARTNER_ITEM_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $itemId: ID!, $paymentChannelId: ID!) {
        resetPartnerItemMarkedUpPercentage(
            partnerId: $partnerId
            itemId: $itemId
            paymentChannelId: $paymentChannelId
        )
    }
`;

export const SAVE_PARTNER_ITEM_FINAL_PRICES = gql`
    mutation (
        $partnerId: ID!
        $itemId: ID!
        $finalPriceOverrideDataList: [FinalPriceOverrideData]
    ) {
        partnerItemFinalPriceOverride(
            partnerId: $partnerId
            itemId: $itemId
            finalPriceOverrideDataList: $finalPriceOverrideDataList
        )
    }
`;

export const RESET_PARTNER_ITEM_FINAL_PRICE = gql`
    mutation (
        $itemId: ID!
        $partnerId: ID!
        $paymentChannelId: ID!
        $sourceCurrency: String!
        $targetCurrency: String!
    ) {
        resetOverriddenFinalPrice(
            itemId: $itemId
            partnerId: $partnerId
            paymentChannelId: $paymentChannelId
            sourceCurrency: $sourceCurrency
            targetCurrency: $targetCurrency
        )
    }
`;

export const SAVE_PARTNER_ITEM_MARKED_UP_PERCENTAGE_AND_CHANNEL_ACTIVE_STATUS = gql`
    mutation (
        $partnerId: ID!
        $itemId: ID!
        $updatedPartnerItemPaymentChannels: [UpdatedPartnerItemPaymentChannelInput]!
    ) {
        updatePartnerItemPaymentChannelData(
            updatePartnerItemPaymentChannelInput: {
                partnerId: $partnerId
                itemId: $itemId
                updatedPartnerItemPaymentChannels: $updatedPartnerItemPaymentChannels
            }
        ) {
            itemId
            partnerId
            updatedPartnerItemPaymentChannels {
                channelId
                isEnabled
                channelName
            }
        }
    }
`;
