import { PaymentMethod } from '../../enums/payment';

export enum RoundOffBoundary {
    UPPER_BOUNDARY,
    LOWER_BOUNDARY,
    NOT_SELECTED,
}

export enum RoundOffPlace {
    WHOLE_NUMBER,
    FIRST_DECIMAL,
    SECOND_DECIMAL,
    NOT_SELECTED,
}

export enum DealStatus {
    ENDED,
    ONGOING,
    SCHEDULED,
}

export type DealDto = {
    id: number;
    dealName: string;
    dealPercentage: number;
    finalPriceRoundOffBoundary: RoundOffBoundary;
    finalPriceRoundOffDecimalPlace: RoundOffPlace;
    startDateTime: string;
    endDateTime: string;
    selectedPartners: unknown;
    isActive: boolean;
    status: DealStatus;
};

export type PaginatedDeal = {
    deals: [DealDto];
    currentPage: number;
    totalDeals: number;
    totalFilteredDeals: number;
    totalPages: number;
    totalOngoingDeals: number;
    totalEndedDeals: number;
    totalScheduledDeals: number;
};

export type AllDealsResponse = {
    deals: PaginatedDeal;
};

export type DealPaymentChannelsDto = {
    isPspSelected: boolean;
    isDcbSelected: boolean;
    isPointsSelected: boolean;
    paymentChannels: [PaymentChannel];
};

type PaymentChannel = {
    id: number;
    channelId: string;
    channelName: string;
    description: string;
    paymentMethod: PaymentMethod;
    isActive: boolean;
};
