import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getCategoryOrderUpdateFailedMessage,
    SUCCESS_TITLE,
    PARTNER_EDIT_SUCCESS,
} from '../../../../constants/partner';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import {
    getCategoryOrderString,
    getOrderedCategories,
} from '../../../../helpers/CatalogCategoryHelpers.helpers';
import {
    FETCH_CATALOG_BY_ID,
    FETCH_ITEMS_BY_PARTNER,
    UPDATE_CATALOG_ITEM_ORDER,
} from '../../../../queries/PartnerQueries';
import Loader from '../../../../utils/loader';
import NormalLoader from '../../../../utils/normalLoader';
import SuccessMessageModal from '../../../templates/modals/SuccessMessageModal';
import { Buttons } from '../../../ui/atoms/Button';
import Toast from '../../../ui/atoms/Toast';
import DataTableAlter from '../../../ui/organisms/DataTableAlter';
import { DraggableDataTable } from '../../../ui/organisms/DraggableDataTable';
import { getSupplierProductCode } from '../../../../helpers/ItemHelpers.helpers';

interface Props {
    viewOnly: boolean;
}

export default function CatalogItem({ viewOnly }: Props) {
    const partnerId = localStorage.getItem('partnerUUID');
    const catalogId = localStorage.getItem('catalogId');
    const history = useHistory();
    const [orderedItems, setOrderedItem] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showAddNewPartnerSuccess, setShowAddNewPartnerSuccess] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Item ID',
                accessor: 'itemId',
            },
            {
                Header: 'Supplier Product Code',
                accessor: 'product',
                Cell: function showSupplierProductCode({ value }) {
                    const productCode = getSupplierProductCode(value);
                    return productCode;
                },
            },
            {
                Header: 'Item Name',
                accessor: 'itemName',
            },
            {
                Header: 'Stocks Status',
                accessor: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock'
                                        ? '96px'
                                        : '',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
        ],
        [],
    );

    const { loading: catalogLoading, data: catalog } = useQuery(FETCH_CATALOG_BY_ID, {
        variables: { catalogId: catalogId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });

    const { loading: itemLoading, data: items } = useQuery(FETCH_ITEMS_BY_PARTNER, {
        variables: { partnerId: partnerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });

    const [updateItemOrder, { loading: saveCatalogLoading }] = useMutation(
        UPDATE_CATALOG_ITEM_ORDER,
        {
            context: { clientName: ClientsEnum.STORE },
            onCompleted() {
                setError(false);
                setShowAddNewPartnerSuccess(true);
                setShowToast(true);
            },
            onError() {
                setError(true);
                setMessage(
                    getCategoryOrderUpdateFailedMessage(catalog?.catalog?.partner?.partnerName),
                );
                setShowToast(true);
            },
        },
    );

    useEffect(() => {
        if (processFinishedClicked) {
            history.push('/partners');
        }
    }, [history, processFinishedClicked]);

    useEffect(() => {
        if (items && catalog) {
            const ordered = getOrderedCategories(
                catalog?.catalog?.itemOrder,
                items?.itemsByPartner,
            );
            setOrderedItem(ordered);
        }
    }, [items, catalog]);

    const updateOrderedList = (orderedList) => {
        setOrderedItem(orderedList);
    };

    const handleSubmit = () => {
        updateItemOrder({
            variables: {
                catalogInput: {
                    catalogId: catalogId,
                    itemOrder: getCategoryOrderString(orderedItems),
                },
            },
        });
    };

    if (itemLoading || catalogLoading) return <Loader />;

    return (
        <>
            <div className="h-full flex flex-col  justify-evens px-4">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                        error={error}
                        selfDestruct={true}
                        selfDestructTimer={2000}
                    />
                )}
                <div className="text-lg font-poppins font-bold px-8">Item Order Setup</div>
                <div className="border-2 border-gray-300 rounded-lg my-5 mr-5 ml-7 mb-10">
                    {orderedItems && (
                        <div className="w-full mb-4">
                            {!viewOnly ? (
                                <DraggableDataTable
                                    columns={columns}
                                    data={orderedItems}
                                    setData={updateOrderedList}
                                />
                            ) : (
                                <DataTableAlter
                                    columns={columns}
                                    data={orderedItems}
                                    hidePagination={true}
                                    customizedPageSize={orderedItems?.length}
                                    pageCount={1}
                                />
                            )}
                        </div>
                    )}
                </div>
                {!viewOnly ? (
                    <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="cancel-button"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                history.push('/partners');
                            }}
                        />
                        <Buttons
                            name={saveCatalogLoading ? <NormalLoader /> : 'Save'}
                            type="submit"
                            buttonType="primary"
                            id="save-button"
                            size="e-small"
                            onclick={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                ) : null}
            </div>
            <SuccessMessageModal
                showSuccessModal={showAddNewPartnerSuccess}
                setShowSuccessModal={setShowAddNewPartnerSuccess}
                successModalTitle={SUCCESS_TITLE}
                successModalBody={PARTNER_EDIT_SUCCESS}
                setProcessFinishedClicked={setProcessFinishedClicked}
            />
        </>
    );
}
