import React, { ReactElement, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Buttons } from '../../ui/atoms/Button';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    FETCH_FAILED_ORDER_TRANSACTIONS,
    FETCH_ORDER,
    RETRY_TRANSACTION,
    FETCH_RETRIED_FAILED_ORDER_TRANSACTIONS,
} from '../../../queries/OrdersQueries';
import { Modal } from 'semantic-ui-react';
import {
    CANCELLED_ORDERS,
    COMPLETE_ORDERS,
    FAILED_ORDERS,
    PROCESSING_ORDERS,
    REFUNDED_ORDERS,
} from '../../../constants/order';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import Toast from '../../ui/atoms/Toast';
import Loader from '../../../utils/loader';
import { FETCH_DEAL_BY_ID } from '../../../queries/DealQueries';

interface Props {
    showVerifyModal: boolean;
    setShowVerifyModal: (value: boolean) => void;
    setLoadingViewOneFailedTransaction: (value: boolean) => void;
    currentFailedTransaction?: any;
    refetchFailedTransactions: any;
}

export default function ViewOneFailedTransaction({
    showVerifyModal,
    setShowVerifyModal,
    setLoadingViewOneFailedTransaction,
    currentFailedTransaction,
    refetchFailedTransactions,
}: Props): ReactElement {
    const currentOrderID = localStorage.getItem('orderId');
    const [retriedFailedTransactionsData, setRetriedFailedTransactionsData] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);

    // const currentTransactionId = localStorage.getItem('transactionId');
    const currentTransactionId = currentFailedTransaction?.id;
    const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
    const [isDealPurchase, setIsDealPurchase] = useState(false);
    const [dealName, setDealName] = useState(null);

    const [loadOrderData, { data: currentFailedTransactionDetails, loading }] = useLazyQuery(
        FETCH_ORDER,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
        },
    );

    const { loading: retriedTransactionslsLoading, refetch: refetchRetriedTransactions } = useQuery(
        FETCH_RETRIED_FAILED_ORDER_TRANSACTIONS,
        {
            context: { clientName: ClientsEnum.STORE },
            variables: {
                transactionId: currentFailedTransaction?.transactionId,
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                if (data.retriedFailedOrderTransactions?.length > 1) {
                    setRetriedFailedTransactionsData(data.retriedFailedOrderTransactions);
                } else {
                    setRetriedFailedTransactionsData([]);
                }
            },
            onError: () => {
                setRetriedFailedTransactionsData([]);
            },
        },
    );

    const [retryTransaction, { loading: retrying, error: retryTransactionError }] = useMutation(
        RETRY_TRANSACTION,
        {
            refetchQueries: [FETCH_FAILED_ORDER_TRANSACTIONS],
            context: { clientName: ClientsEnum.STORE },
            onCompleted: () => {
                setIsTransactionCompleted(true);
                setShowToast(false);
                setShowSuccessToast(true);
                refetchRetriedTransactions();
                refetchFailedTransactions();
            },
            onError: () => {
                setShowToast(true);
                setShowSuccessToast(false);
                refetchRetriedTransactions();
                refetchFailedTransactions();
            },
        },
    );

    const [loadDealData, { data: dealData, loading: dealLoading }] = useLazyQuery(
        FETCH_DEAL_BY_ID,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: () => {
                setDealName(dealData?.deal?.dealName);
            },
        },
    );

    useEffect(() => {
        if (currentFailedTransaction?.dealId) {
            loadDealData({
                variables: {
                    dealId: currentFailedTransaction?.dealId,
                },
            });
            setIsDealPurchase(true);
        } else {
            setIsDealPurchase(false);
        }
    }, [currentFailedTransaction]);

    const handleRetryButtonClick = () => {
        retryTransaction({
            variables: {
                failedOrderId: currentTransactionId,
            },
        });
    };

    useEffect(() => {
        if (currentOrderID) {
            loadOrderData({
                variables: {
                    orderId: currentOrderID,
                },
            });
        }
    }, [currentOrderID, loadOrderData]);

    useEffect(() => {
        if (loading) {
            setLoadingViewOneFailedTransaction(true);
        }
    }, [loading, setLoadingViewOneFailedTransaction]);

    useEffect(() => {
        if (currentFailedTransaction?.transactionId) {
            refetchRetriedTransactions();
            setShowToast(false);
            setShowSuccessToast(false);
        }
    }, [currentFailedTransaction?.transactionId, refetchRetriedTransactions, showVerifyModal]);

    const columns = React.useMemo(
        () => [
            { Header: 'Transaction ID', accessor: 'retriedTransactionId' },
            {
                id: 'createdDate',
                Header: 'Date',
                accessor: 'createdDate',
            },
            {
                id: 'errorMessage',
                Header: 'Store Error Code',
                accessor: 'errorMessage',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'status',
                Cell: function retriedStatus({ value }) {
                    if (value === 'COMPLETED') {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-green-100 text-green-600">
                                Completed
                            </span>
                        );
                    } else if (value === 'FAILED') {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-red-100 text-red-600">
                                Failed
                            </span>
                        );
                    }
                },
            },
        ],
        [],
    );

    return (
        <Modal
            open={showVerifyModal}
            onClose={() => {
                setShowVerifyModal(false);
            }}
        >
            <div
                className={`flex  py-5  rounded-t  ${
                    currentFailedTransaction?.status === PROCESSING_ORDERS
                        ? 'bg-yellow-100'
                        : currentFailedTransaction?.status === COMPLETE_ORDERS
                        ? 'bg-green-100'
                        : currentFailedTransaction?.status === CANCELLED_ORDERS
                        ? 'bg-red-100'
                        : currentFailedTransaction?.status === REFUNDED_ORDERS
                        ? 'bg-gray-100'
                        : currentFailedTransaction?.status === FAILED_ORDERS
                        ? 'bg-red-100'
                        : null
                }`}
            >
                <div className="flex px-10">
                    <div className="text-xl font-poppins font-semibold flex">
                        Order {currentFailedTransaction?.transactionId}
                    </div>
                    <div
                        className={`text-xl font-poppins ml-10 font-semibold  flex  ${
                            currentFailedTransaction?.status === PROCESSING_ORDERS
                                ? 'text-yellow-500'
                                : currentFailedTransaction?.status === COMPLETE_ORDERS
                                ? 'text-green-500'
                                : currentFailedTransaction?.status === CANCELLED_ORDERS
                                ? 'text-red-500'
                                : currentFailedTransaction?.status === REFUNDED_ORDERS
                                ? 'text-gray-500'
                                : currentFailedTransaction?.status === FAILED_ORDERS
                                ? 'text-red-500'
                                : null
                        }`}
                    >
                        {currentFailedTransaction?.status}
                    </div>
                </div>
            </div>
            {showToast && (
                <div className="relative block h-16">
                    <Toast
                        setShowToast={setShowToast}
                        message={retryTransactionError?.message}
                        width="w-11/12"
                        style="absolute left-0 top-10"
                        selfDestruct={true}
                        selfDestructTimer={3000}
                        error={true}
                    />
                </div>
            )}

            {showSuccessToast && (
                <div className="relative block h-16">
                    <Toast
                        setShowToast={setShowSuccessToast}
                        message="Transaction Successful"
                        width="w-11/12"
                        style="absolute left-0 top-10"
                        selfDestruct={true}
                        selfDestructTimer={3000}
                    />
                </div>
            )}

            <div className="relative py-3 border-b-2">
                <div className="flex flex-row justify-between px-10 mt-2">
                    <div>
                        <div className="font-poppins font-bold mb-2">User ID</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.userId}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Display Name</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.displayName}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Gamer Email</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.gamerEmail}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-10">
                    <div>
                        <div className="font-poppins font-bold mb-2">Date</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.createdDate?.slice(0, 16) + '(UTC)'}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Purchase Channel</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.purchasePaymentChannelName ||
                                currentFailedTransaction?.purchaseMedium}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Content Type</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.contentType}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Purchase Currency</div>
                        <div className="font-poppins text-gray-400 mb-7">
                            {currentFailedTransaction?.purchasePaymentChannelName == 'POINTS' ||
                            currentFailedTransaction?.purchaseMedium == 'POINTS' ? (
                                'PTS'
                            ) : (
                                <div className="flex items-center">
                                    <div>{currentFailedTransaction?.targetCurrency}</div>
                                    <i
                                        className={`${currencyAndCountryCodes
                                            .find(
                                                (x) =>
                                                    x.currency ===
                                                    currentFailedTransaction?.targetCurrency,
                                            )
                                            ?.country.toLowerCase()} flag pl-1`}
                                    ></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-10">
                    <div>
                        <div className="font-poppins font-bold mb-2">Store Error Code</div>
                        <div className="font-poppins text-gray-400 mb-5">
                            {currentFailedTransaction?.errorDetails}
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-between border-b-2 my-3">
                <div className="flex px-10 justify-between">
                    <div className="w-4/12">
                        <div className="font-poppins font-bold mb-4">Item Id</div>
                        <div className="font-poppins text-gray-400 mb-5 w-11/12">
                            {currentFailedTransaction?.item?.itemId}
                        </div>
                    </div>
                    <div className="w-4/12">
                        <div className="font-poppins font-bold mb-4">Item Name</div>
                        <div className="font-poppins text-gray-400 mb-5 w-11/12">
                            {currentFailedTransaction?.item?.itemName}
                        </div>
                    </div>

                    <div className="w-2/12">
                        <div className="font-poppins font-bold mb-4 text-center">Quantity</div>
                        <div className="font-poppins text-gray-400 mb-5 text-center">
                            {currentFailedTransaction?.quantity}
                        </div>
                    </div>
                    <div className="w-2/12" hidden={!isDealPurchase}>
                        <div className="font-poppins font-bold mb-4 text-center">Price</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentFailedTransaction?.totalPoints?.toFixed(2) ||
                                currentFailedTransaction?.markedUpPrice?.toFixed(2)}
                        </div>
                    </div>
                    <div className="w-2/12" hidden={!isDealPurchase}>
                        <div className="font-poppins font-bold mb-4 text-center">Deal</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentFailedTransaction?.dealPercentage}% {dealName}
                        </div>
                    </div>
                    <div className="w-2/12">
                        <div className="font-poppins font-bold mb-4 text-center">Total</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentFailedTransaction?.dealValue ||
                                currentFailedTransaction?.totalPoints?.toFixed(2) ||
                                currentFailedTransaction?.markedUpPrice?.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-10 ">
                <div className="mt-5 mb-3 font-bold">Transaction History</div>
                <div className="w-full relative">
                    {retriedTransactionslsLoading ? (
                        <div className="w-full">
                            <div className="m-auto flex flex-col ">
                                <div className="m-auto p-10">
                                    <Loader />
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {retriedFailedTransactionsData?.length ? (
                                <div className="w-full flex-col border-2 border-gray-300 rounded-lg overflow-scroll max-h-64">
                                    <DataTableAlter //without selection of row
                                        columns={columns}
                                        data={retriedFailedTransactionsData}
                                        loading={retriedTransactionslsLoading}
                                        isInitialLoad={isInitialLoad}
                                        setIsInitialLoad={setIsInitialLoad}
                                        hidePagination={true}
                                        scrollable={true}
                                    />
                                </div>
                            ) : (
                                <p className="text-gray-600">No history available</p>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="flex flex-row justify-end mx-4 my-7">
                {currentFailedTransaction?.retryButtonDisabled === false &&
                    !isTransactionCompleted && (
                        <Buttons
                            name="Retry"
                            type="submit"
                            buttonType="secondary-main"
                            id="order-retry-button"
                            size="small"
                            onclick={handleRetryButtonClick}
                            disabled={retrying}
                        />
                    )}

                <Buttons
                    name="Close"
                    type="submit"
                    buttonType="secondary-main-grey"
                    id="order-close-popup-button"
                    size="small"
                    onclick={() => {
                        setShowVerifyModal(false);
                    }}
                />
            </div>
        </Modal>
    );
}
