/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ADD_PRODUCTS, FETCH_PRODUCTS, FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import UploadProductPic from './UplaodProductImage';
import Loader from '../../../utils/loader';
import AddProductSuccessModal from '../../templates/modals/AddProductSuccess';
import UnSavedProduct from '../../templates/modals/UnsavedProductScreen';
import { useHistory } from 'react-router';
import Toast from '../../ui/atoms/Toast';
import NormalLoader from '../../../utils/normalLoader';
import {
    PARTNER_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/partner';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/product';
import { useDispatch, useSelector } from 'react-redux';
import { saveProductName } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useParams } from 'react-router-dom';
import { CSV_VOUCHERS } from '../../../constants/supplier';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';

interface Props {
    props: any;
}
export default function EditProductScreen({ props }: Props) {
    const [message, setMessage] = useState('');
    const [complete, setComplete] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [completeItemQuery, setCompleteItem] = useState(false);
    const [completeItemCategory, setCompleteCategory] = useState(false);
    const [productImage, setProductImage] = useState('');
    const [outOfStockImage, setOutOfStockImage] = useState<string>('');
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [descriptionShort, setDescriptionShort] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [safetyStockThreshold, setSafetyStockThreshold] = useState(50);
    const [orderStrategy, setOrderStrategy] = useState('');
    const [selectedProductType, setSelectedProductType] = useState('');
    const [selectedContentType, setSelectedContentType] = useState('');
    const [gallery1, setGallery1Image] = useState('');
    const [gallery2, setGallery2Image] = useState('');
    const [gallery3, setGallery3Image] = useState('');
    const [gallery4, setGallery4Image] = useState('');
    const [gallery5, setGallery5Image] = useState('');
    const [gallery6, setGallery6Image] = useState('');

    const [outOfStockImageError, setOutOfStockImageError] = useState<boolean>(false);
    const [productImageEmptyError, setProductImageEmptyError] = useState<boolean>(false);
    const [selectedProductDescriptionError, setSelectedProductDescriptionError] =
        useState<boolean>(false);
    const [productTitleError, setproductTitleError] = useState<boolean>(false);

    const [showUnSavedModal, setShowUnSavedModal] = useState(false);

    const parameters = useParams();

    const selectedProduct = parameters['id'];

    const dispatch = useDispatch();

    const { data: itemData, loading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: selectedProduct },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setCompleteItem(true);
            dispatch(saveProductName(itemData?.item?.itemName));
            setSelectedProductType(itemData?.item?.itemType);
            setSelectedContentType(itemData?.item?.contentType);
            setDescriptionLong(itemData?.item?.itemDescDetail);
            setDescriptionShort(itemData?.item?.itemDescBrief);
            setSafetyStockThreshold(itemData?.item?.safetyStockThreshold ?? safetyStockThreshold);
            setOrderStrategy(itemData?.item?.product?.orderStrategy);
            setGallery1Image(itemData?.item?.itemGalleryUrls[0]);
            setGallery2Image(itemData?.item?.itemGalleryUrls[1]);
            setGallery3Image(itemData?.item?.itemGalleryUrls[2]);
            setGallery4Image(itemData?.item?.itemGalleryUrls[3]);
            setGallery5Image(itemData?.item?.itemGalleryUrls[4]);
            setGallery6Image(itemData?.item?.itemGalleryUrls[5]);
            setOutOfStockImage(itemData?.item?.outOfStockImageUrl);
            setProductImage(itemData?.item?.itemImageUrl);
        },
    });
    const item = itemData?.item;

    const [saveItem, { loading: queryLoading, error: queryError, data }] = useMutation(
        ADD_PRODUCTS,
        {
            context: { clientName: ClientsEnum.STORE },
            refetchQueries: [FETCH_PRODUCTS],
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                setError(false);
                setComplete(true);
                setMessage(`Successfully updated product ${data?.saveItem?.itemName} `);
                setShowToast(true);
                setTimeout(() => {
                    history.push('/products');
                }, 1800);
            },
            onError: () => {
                setMessage(queryError?.message || 'Oops something went wrong');
                setShowToast(true);
                setError(true);
            },
        },
    );

    useEffect(() => {
        if (descriptionLong) {
            setSelectedProductDescriptionError(false);
        }
    }, [descriptionLong]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const galleryImages = [];

    const history = useHistory();

    galleryImages.push(gallery1, gallery2, gallery3, gallery4, gallery5, gallery6);

    const productTypeOptions = [
        {
            text: 'Physical',
            value: 'PHYSICAL',
        },
        {
            text: 'Virtual',
            value: 'VIRTUAL',
        },
    ];

    const defaultStatus = productTypeOptions?.filter((option: any) => {
        return option?.value === item?.itemType;
    });

    const contentTypeOptions = [
        {
            label: 'Normal',
            value: 'NORMAL',
        },
        {
            label: 'Premium',
            value: 'PREMIUM',
        },
    ];

    const defaultContentType = contentTypeOptions?.filter((option: any) => {
        return option?.value === item?.contentType;
    });

    if (complete) {
        localStorage.setItem('added_product', data?.saveItem?.id);
    }

    if (loading) return <Loader />;
    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: item?.itemName,
                itemType: '',
                itemDescBrief: item?.itemDescBrief,
                itemImageUrl: '',
                itemGalleryUrls: [],
                itemDescDetail: item?.itemDescDetail,
                safetyStockThreshold: safetyStockThreshold,
                deliveryMethod: '',
            }}
            enableReinitialize
            onSubmit={({ itemName }) => {
                if (selectedProductType && descriptionLong) {
                    saveItem({
                        variables: {
                            item: {
                                id: item?.id,
                                itemId: item?.itemId,
                                itemName: itemName || item?.itemName,
                                itemType: selectedProductType || item?.itemType,
                                itemDescBrief: descriptionShort,
                                itemImageUrl: productImage || item?.itemImageUrl,
                                outOfStockImageUrl: outOfStockImage || item?.outOfStockImageUrl,
                                itemGalleryUrls: galleryImages || item?.itemGalleryUrls,
                                itemDescDetail: descriptionLong || item?.itemDescDetail || '',
                                deliveryMethod: item?.deliveryMethod,
                                isDeleted: item?.isDeleted || false,
                                isActive: item?.isActive || true,
                                itemStatus: item?.itemStatus,
                                createdBy: item?.createdBy,
                                currencyConversionRate: item?.currencyConversionRate,
                                unitPrice: item?.unitPrice,
                                safetyStockThreshold:
                                    orderStrategy === CSV_VOUCHERS ? safetyStockThreshold : null,
                                product: {
                                    id: item?.product?.id,
                                },
                                contentType: selectedContentType || item?.contentType,
                            },
                        },
                    });
                }
            }}
        >
            {({ handleSubmit, handleChange, values, handleBlur, errors, touched }) => (
                <div className="w-full">
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            error={error}
                            width="w-8/12"
                        />
                    )}
                    <div className="px-14">
                        <div className="text-xl font-poppins font-bold px-4">General</div>

                        <UnsaveChangesWarning
                            contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                            messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                            when={isDirty || window.onbeforeunload ? true : false}
                            navigate={(path) => history.push(path)}
                            shouldBlockNavigation={(location) => {
                                if (isDirty) {
                                    return true;
                                }
                                return false;
                            }}
                            displayIcon={true}
                            displayIconName={'clarity_error-standard-line-svg.svg'}
                        />

                        <AddProductSuccessModal
                            setShowSuccessModal={setShowSuccessModal}
                            showSuccessModal={showSuccessModal}
                        />
                        <UnSavedProduct
                            setShowUnSavedModal={setShowUnSavedModal}
                            showUnSavedModal={showUnSavedModal}
                        />

                        <div className="text-gray-500 w-full my-5 text-left font-poppins text-md h-max bg-white z-10 ml-4 border-opacity-20">
                            Supplier Product Code:{' '}
                            <span className="font-semibold text-purple-500">
                                {getSupplierProductCode(item?.product)}
                            </span>
                        </div>

                        <div className="grid grid-cols-8 gap-4">
                            <div className="lg:col-start-1 lg:col-end-3">
                                <div className="flex flex-wrap flex-col relative mt-3  px-4">
                                    <div className="text-gray-500 w-25 text-center font-poppins text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Product Type
                                    </div>
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                        id="product-type"
                                        onChange={(e) => {
                                            setSelectedProductType(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedProductType(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        defaultValue={defaultStatus[0]?.value}
                                    >
                                        <option
                                            value=""
                                            disabled
                                            className={'font-medium'}
                                            selected
                                        >
                                            Select Product Type
                                        </option>
                                        {productTypeOptions?.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {!selectedProductType ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Product type is missing'}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="lg:col-start-3 lg:col-end-5">
                                <div className="flex flex-wrap flex-col relative mt-3 px-4">
                                    <div className="text-gray-500 w-24 text-center font-poppins text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Content Type
                                    </div>
                                    <select
                                        className="p-4 lg:p-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                        id="content_type"
                                        onChange={(e) => {
                                            setSelectedContentType(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedContentType(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        value={selectedContentType}
                                        defaultValue={defaultContentType[0]?.value}
                                    >
                                        {contentTypeOptions?.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className="font-medium"
                                                    key={index}
                                                >
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-5 mt-3 px-4">
                                <InputField
                                    id="itemName"
                                    placeHolder="Enter product title"
                                    name="Product Title"
                                    labelWidth="w-24"
                                    maxLength={255}
                                    onChange={(e) => {
                                        handleChange(e);
                                        if (productTitleError && e.target.value.length > 0) {
                                            setproductTitleError(false);
                                        }
                                        setIsDirty(true);
                                    }}
                                    value={values.itemName}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        255 characters limited
                                    </div>
                                </div>
                                {productTitleError ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        Product title is missing
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-49 text-center font-poppins text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product Description(Small)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="itemDescBrief"
                                    name="itemDescBrief"
                                    rows={2}
                                    cols={138}
                                    draggable={false}
                                    maxLength={150}
                                    onInput={handleChange('itemDescBrief')}
                                    onChange={(e) => {
                                        setDescriptionShort(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    value={values.itemDescBrief}
                                    placeholder="Enter product short description. This will be used on the product card"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        150 characters limited
                                    </div>
                                </div>
                            </div>
                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-54 text-center font-poppins text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product Description(Detailed)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="itemDescDetail"
                                    name="itemDescDetail"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={600}
                                    onInput={handleChange('itemDescDetail')}
                                    onChange={(e) => {
                                        setDescriptionLong(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    value={values.itemDescDetail}
                                    placeholder="Enter product short description - this will be used on the product detailed view"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        600 characters limited
                                    </div>
                                </div>
                                {(errors.itemDescDetail && touched.itemDescDetail) ||
                                selectedProductDescriptionError ? (
                                    <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                        Product description is missing
                                    </div>
                                ) : null}
                            </div>
                            {orderStrategy === CSV_VOUCHERS ? (
                                <div className="col-start-1 col-end-5 mt-3 px-4">
                                    <InputField
                                        id="safetyStockThreshold"
                                        name="Safety Stock Threshold"
                                        labelWidth="w-24"
                                        maxLength={5}
                                        onChange={(e) => {
                                            setSafetyStockThreshold(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        value={values.safetyStockThreshold}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row px-4">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">Product Image</div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>
                                <div className="flex py-2">
                                    <UploadProductPic
                                        width={'w-full'}
                                        height={'h-80'}
                                        isEdit={true}
                                        inputId="productImage"
                                        setGalleryImage={setProductImage}
                                        imageProp={productImage}
                                        aspectRatio={1 / 1}
                                        transparencyEnabled={true}
                                    />
                                </div>
                                {productImageEmptyError &&
                                    (productImage == null || productImage?.length === 0) &&
                                    orderStrategy === CSV_VOUCHERS && (
                                        <div className="w-full py-0.5 justify-between mt-3 flex">
                                            <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                                {'Product image is not selected'}
                                            </div>
                                        </div>
                                    )}
                                <div className="text-gray-400 mb-5 flex">What can I upload?</div>
                            </div>

                            <div className="w-full ml-9">
                                <div className="flex my-3">
                                    <div className="justify-between font-poppins">
                                        Product Gallery{' '}
                                    </div>
                                    <div className="text-gray-400 ml-1"> (Max 64 X 64)</div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery1"
                                            setGalleryImage={setGallery1Image}
                                            imageProp={gallery1}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery2"
                                            setGalleryImage={setGallery2Image}
                                            imageProp={gallery2}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery3"
                                            setGalleryImage={setGallery3Image}
                                            imageProp={gallery3}
                                            transparencyEnabled={true}
                                        />
                                    </div>
                                    <div className="flex mt-8">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery4"
                                            setGalleryImage={setGallery4Image}
                                            imageProp={gallery4}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery5"
                                            setGalleryImage={setGallery5Image}
                                            imageProp={gallery5}
                                            transparencyEnabled={true}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            inputId="gallery6"
                                            setGalleryImage={setGallery6Image}
                                            imageProp={gallery6}
                                            transparencyEnabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 mb-10">
                            <div className="flex my-3">
                                <div className="mr-1 font-poppins">
                                    {orderStrategy === CSV_VOUCHERS
                                        ? `Out of Stock Image(Optional)`
                                        : `Out of Stock Image`}
                                </div>
                                <div className="text-gray-400"> (Max 2048 X 2048)</div>
                            </div>
                            <div className="flex py-2">
                                <UploadProductPic
                                    width={'w-full'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="outOfStockImage"
                                    setGalleryImage={setOutOfStockImage}
                                    imageProp={item?.outOfStockImageUrl}
                                    aspectRatio={1 / 1}
                                    transparencyEnabled={true}
                                />
                            </div>
                            {outOfStockImageError &&
                                (outOfStockImage == null || outOfStockImage?.length === 0) && (
                                    <div className="w-full py-0.5 justify-between mt-3 flex">
                                        <div className="text-red-500 text-xs -mt-2 md:text-sm">
                                            {'Out of stock image is not selected'}
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="absolute w-10/12 right-0">
                            <div className={`flex flex-row  bg-gray-100 p-4 justify-end`}>
                                <Buttons
                                    name="Cancel"
                                    type="button"
                                    buttonType="secondary-border-black"
                                    id="cancel-button"
                                    size="e-small"
                                    other="mr-3"
                                    onclick={() => {
                                        history.push('/products');
                                    }}
                                />
                                <Buttons
                                    name={queryLoading ? <NormalLoader /> : 'Update'}
                                    type="submit"
                                    buttonType="primary"
                                    id="update-button"
                                    size="e-small"
                                    onclick={() => {
                                        setIsDirty(false);

                                        if (!descriptionLong) {
                                            setSelectedProductDescriptionError(true);
                                        }

                                        if (!values.itemName && values.itemName.length == 0) {
                                            setproductTitleError(true);
                                        } else {
                                            setproductTitleError(false);
                                        }

                                        if (
                                            (productImage === null || productImage?.length == 0) &&
                                            orderStrategy === CSV_VOUCHERS
                                        ) {
                                            setProductImageEmptyError(true);
                                        } else if (
                                            (outOfStockImage === null ||
                                                outOfStockImage?.length == 0) &&
                                            orderStrategy !== CSV_VOUCHERS
                                        ) {
                                            setOutOfStockImageError(true);
                                        } else {
                                            setProductImageEmptyError(false);
                                            setOutOfStockImageError(false);
                                            if (
                                                values.itemName &&
                                                values.itemName.length > 0 &&
                                                !selectedProductDescriptionError
                                            ) {
                                                handleSubmit();
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}
