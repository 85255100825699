import { useQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PRODUCT_POINTS_TABLE_TYPE } from '../../../constants/partner';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
    FINAL_PRICE_MESSAGE,
    FINAL_PRICE_TITLE,
    POINTS_CONVERSION_BODY,
    POINTS_CONVERSION_TITLE,
    CURRENCY_CONVERSION_BODY,
    CURRENCY_CONVERSION_TITLE,
    PARTNER_ITEM_GLOBAL_CURRENCY_SET_MESSAGE,
} from '../../../constants/product';
import { VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { FETCH_PARTNER_DETAILS_BY_ITEM_ID } from '../../../queries/PartnerQueries';
import { SAVE_PARTNER_POINTS } from '../../../queries/PointsQueries';
import { saveProductName } from '../../../redux/rootActions';

import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import { Buttons } from '../../ui/atoms/Button';
import NormalLoader from '../../../utils/normalLoader';
import Toast from '../../ui/atoms/Toast';
import { currencyAndCountryCodes } from '../../../utils/currencies';

import {
    FETCH_ITEM_PARTNER_PRICING_DETAILS,
    RESET_LOCAL_CURRENCY_RATE,
} from '../../../queries/ProductQueries';
import {
    getMarkedUpPrice,
    getPointsValue,
    getRoundedPointsValue,
} from '../../../helpers/CurrencyPriceHelper.helpers';

import { BsArrowCounterclockwise, BsArrowRepeat } from 'react-icons/bs';
import PaymentChannelsDataTable from '../../ui/organisms/PaymentChannelsDataTable';
import { SelectLabel } from '../../ui/molecules/SelectLabel';
import EditableCell from '../../ui/molecules/EditableCell';
import { ViewConversionRateModal } from '../../templates/modals/ViewConversionRateModal';
import {
    FETCH_PARTNER_ITEM_CONVERSION_RATES,
    RESET_OVERRIDE_CONVERSION_RATE,
} from '../../../queries/CurrencyConversionQueries';
import ReactSwitch from 'react-switch';
import {
    RESET_PARTNER_ITEM_FINAL_PRICE,
    RESET_PARTNER_ITEM_MARKED_UP_PERCENTAGE,
    SAVE_PARTNER_ITEM_FINAL_PRICES,
    SAVE_PARTNER_ITEM_MARKED_UP_PERCENTAGE_AND_CHANNEL_ACTIVE_STATUS,
} from '../../../queries/PaymentChannelQueries';
import Confirmation from '../../templates/modals/ConfirmationModalWithMessage';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import GlobalCurrencyUpdateModal from '../../templates/modals/GlobalCurrencyUpdateModal';

import {
    FINAL_MARKED_UP_PRICE_TITLE,
    RESET_MARKED_UP_PERCENTAGE_BODY,
    RESET_MARKED_UP_PERCENTAGE_TITLE,
} from '../../../constants/currency';
import {
    Channel,
    MessageType,
    OverrideFinalPriceData,
    PartnerDetails,
    PaymentChannelPricing,
    RouteParams,
    UpdatedPartnerItemPaymentChannelInput,
    PartnerItemPricingDetails,
    PartnerByItemType,
    PricingDetailsPropTypes,
} from '../../../types/currencyConversions/currencyAndConversionTypes';
import BackButton from '../../ui/atoms/BackButton';
import { calculateDealPrice } from '../../../helpers/DealPricing.helpers';
import Loader from '../../../utils/loader';
import { getConversionRateFormat } from '../../../utils/pricingAndConversions';

export default function EditPricingDetails({
    mode,
    viewDealDetails,
    dealId,
}: PricingDetailsPropTypes) {
    const dispatch = useDispatch();
    const history = useHistory();
    const parameters = useParams<RouteParams>();
    const [pointsData, setPointsData] = useState([]);
    const [productData, setProductData] = useState(null);
    const [error, setError] = useState(false);
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [voucherLastInventories, setVoucherLastInventories] = useState([]);

    const [itemMappedPartners, setItemMappedPartners] = useState<PartnerDetails[]>([]);
    const [selectedPartnerId, setSelectedPartnerId] = useState<string | undefined>(undefined);
    const [selectedPartnerUid, setSelectedPartnerUid] = useState<string | undefined>(undefined);
    const [selectedPartnerName, setSelectedPartnerName] = useState<string | undefined>(undefined);
    const [partnerItemPaymentChannelDetails, setPartnerItemPaymentChannelDetails] = useState<
        PaymentChannelPricing[]
    >([]);
    const [showConversionRateModal, setShowConversionRateModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [onConfirmAction, setOnConfirmAction] = useState<(() => void) | null>(null);
    const [partnerItemPaymentChannelInput, setPartnerItemPaymentChannelInput] = useState<
        UpdatedPartnerItemPaymentChannelInput[]
    >([]);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const [globalCurrencyUpdateModal, setGlobalCurrencyUpdateModal] = useState<boolean>(false);
    const [selectedGlobalCurrency, setSelectedGlobalCurrency] = useState(null);
    const [setItNowButtonEnabled, setSetItNowButtonEnabled] = useState(true);
    const [showToast, setShowToast] = useState<boolean>(false);
    const finalPriceDetailsRef = useRef<OverrideFinalPriceData[]>([]);
    const [message, setMessage] = useState<string>('');
    const channels = useRef<Channel[]>([]);
    const [confirmationMessage, setConfirmationMessage] = useState<MessageType>({
        header: null,
        body: null,
    });
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [dealValidationShowWarningModal, setDealValidationShowWarningModal] =
        useState<boolean>(false);
    const [dealValidationWarningModalTitle, setDealValidationWarningModalTitle] =
        useState<string>('');
    const [dealValidationWarningModalBody, setDealValidationWarningModalBody] =
        useState<string>('');

    const [savePointsInput, setSavePointsInput] = useState({
        localCurrencyConversionRate: null,
        finalPrice: null,
    });

    const itemID = mode == 'dealView' ? parameters['itemId'] : parameters['id'];
    const partnerID = mode == 'dealView' ? parameters['partnerId'] : selectedPartnerId;

    const transformedPartners = itemMappedPartners.map((itemPartner) => ({
        id: itemPartner.id,
        value: itemPartner.partnerName,
    }));

    useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: itemID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            dispatch(saveProductName(data?.item?.itemName));
            setProductData(data?.item);
            localStorage.setItem('iId', itemID);
        },
    });

    const {
        data: conversionPricingData,
        loading: paymentChannelDataLoading,
        refetch: refetchPartnerItemDetails,
    } = useQuery(FETCH_ITEM_PARTNER_PRICING_DETAILS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { itemId: itemID, partnerId: partnerID },
        context: { clientName: ClientsEnum.STORE },
        skip: !selectedPartnerId,
        onCompleted: (data: PartnerItemPricingDetails) => {
            let pointsChannels;
            let otherChannels;

            if (mode == 'dealView') {
                pointsChannels = data.partnerItemPricingDetails.filter(
                    (channel) =>
                        channel.channelName.trim().toLowerCase() == 'points' &&
                        viewDealDetails.channelIds?.some(
                            (dealChannelIds) => dealChannelIds == channel.channelId,
                        ),
                );

                otherChannels = data.partnerItemPricingDetails.filter(
                    (channel) =>
                        channel.channelName.trim().toLowerCase() !== 'points' &&
                        viewDealDetails.channelIds?.some(
                            (dealChannelIds) => dealChannelIds == channel.channelId,
                        ),
                );
            } else {
                pointsChannels = data.partnerItemPricingDetails.filter(
                    (channel) => channel.channelName.trim().toLowerCase() === 'points',
                );

                otherChannels = data.partnerItemPricingDetails.filter(
                    (channel) => channel.channelName.trim().toLowerCase() !== 'points',
                );
            }
            finalPriceDetailsRef.current = [];
            setPartnerItemPaymentChannelInput([]);
            setPointsData(pointsChannels);
            setPartnerItemPaymentChannelDetails(otherChannels);
        },
        onError: (error) => {
            setError(true);
            setMessage(
                `An error ocurred while fetching data for FETCH_ITEM_PARTNER_PRICING_DETAILS ${error}`,
            );
            setShowToast(true);
        },
    });

    const { loading: partnerDetailsLoading } = useQuery(FETCH_PARTNER_DETAILS_BY_ITEM_ID, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { itemId: itemID },
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data: PartnerByItemType) => {
            if (mode == 'dealView') {
                const dealViewPartner = data.partnersByItemId.filter(
                    (partners) => partners.id == partnerID,
                );

                setSelectedPartnerUid(dealViewPartner[0].partnerId);
                setSelectedPartnerId(dealViewPartner[0].id);
                setSelectedPartnerName(dealViewPartner[0].partnerName);
            } else {
                setItemMappedPartners(data.partnersByItemId);
                if (data.partnersByItemId.length > 0) {
                    setSelectedPartnerUid(data.partnersByItemId[0].partnerId);
                    setSelectedPartnerId(data.partnersByItemId[0].id);
                    setSelectedPartnerName(data.partnersByItemId[0].partnerName);
                }
            }
        },
    });

    const [overriddeFinalPrice] = useMutation(SAVE_PARTNER_ITEM_FINAL_PRICES, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setMessage('Pricing updated successfully');
            setShowToast(true);
            setIsEditing(false);
        },
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while updating final price data ${error}`);
            setShowToast(true);
        },
    });

    const [resetMarkedUpPercentage] = useMutation(RESET_PARTNER_ITEM_MARKED_UP_PERCENTAGE, {
        refetchQueries: [FETCH_ITEM_PARTNER_PRICING_DETAILS],
        context: { clientName: ClientsEnum.STORE },
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while fetching data ${error}`);
            setShowToast(true);
        },
    });

    const [resetOverriddenFinalPrice] = useMutation(RESET_PARTNER_ITEM_FINAL_PRICE, {
        refetchQueries: [FETCH_ITEM_PARTNER_PRICING_DETAILS],
        context: { clientName: ClientsEnum.STORE },
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while fetching data ${error}`);
            setShowToast(true);
        },
    });

    const [
        overrideMarkUpPercentageOrChannelActiveness,
        { loading: markedUpPercentageDataUpdateLoading },
    ] = useMutation(SAVE_PARTNER_ITEM_MARKED_UP_PERCENTAGE_AND_CHANNEL_ACTIVE_STATUS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setMessage('Pricing updated successfully');
            setShowToast(true);
            setIsEditing(false);
        },
        onError: (error) => {
            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1658) {
                    setDealValidationWarningModalTitle('Error');
                    setDealValidationWarningModalBody(graphQLErrors[0]?.message);
                    setDealValidationShowWarningModal(true);
                }
            } else {
                setError(true);
                setMessage(`An error ocurred while updating marked up percentage data ${error}`);
                setShowToast(true);
            }
        },
    });

    const [resetOverride] = useMutation(RESET_OVERRIDE_CONVERSION_RATE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_PARTNER_ITEM_CONVERSION_RATES, FETCH_ITEM_PARTNER_PRICING_DETAILS],
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while fetching data ${error}`);
            setShowToast(true);
        },
    });

    // Note: Please don't refetch FETCH_ITEM_PARTNER_PRICING_DETAILS through the query because it will not update values properly when there's a bulk update
    const [overrideLocalPointsConversion] = useMutation(SAVE_PARTNER_POINTS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setMessage('Pricing updated successfully');
            setShowToast(true);
            setIsEditing(false);
        },
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while updating points data ${error}`);
            setShowToast(true);
        },
    });

    const [resetLocalCurrencyConversion] = useMutation(RESET_LOCAL_CURRENCY_RATE, {
        refetchQueries: [FETCH_ITEM_PARTNER_PRICING_DETAILS],
        context: { clientName: ClientsEnum.STORE },
        onError: (error) => {
            setError(true);
            setMessage(`An error ocurred while fetching data ${error}`);
            setShowToast(true);
        },
    });

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories?.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.min(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
        }
    }, [productData]);

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories;
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.max(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherLastInventories(firstVoucher);
        }
    }, [productData]);

    const handleResetMarkedUpPercentage = useCallback(
        (paymentChannelId: number) => {
            resetMarkedUpPercentage({
                variables: {
                    partnerId: String(selectedPartnerId),
                    itemId: String(itemID),
                    paymentChannelId: String(paymentChannelId),
                },
            });
        },
        [itemID, selectedPartnerId, resetMarkedUpPercentage],
    );

    const handleClickSave = async () => {
        try {
            let markedUpPercentageData;
            if (partnerItemPaymentChannelInput.length > 0) {
                markedUpPercentageData = await overrideMarkUpPercentageOrChannelActiveness({
                    variables: {
                        itemId: itemID,
                        partnerId: selectedPartnerId,
                        updatedPartnerItemPaymentChannels: partnerItemPaymentChannelInput,
                    },
                });
            }

            let pointsData;
            if (
                (savePointsInput.localCurrencyConversionRate != null ||
                    savePointsInput.finalPrice != null) &&
                markedUpPercentageData?.errors == undefined
            ) {
                pointsData = await overrideLocalPointsConversion({
                    variables: {
                        saveItemPoints: {
                            item: {
                                id: itemID,
                            },
                            partner: {
                                id: selectedPartnerId,
                            },
                            localCurrencyConversionRate:
                                savePointsInput.localCurrencyConversionRate,
                        },
                    },
                });
            }

            let finalPriceData;
            if (
                finalPriceDetailsRef.current.length > 0 &&
                markedUpPercentageData?.errors == undefined &&
                pointsData?.errors == undefined
            ) {
                finalPriceData = await overriddeFinalPrice({
                    variables: {
                        itemId: itemID,
                        partnerId: selectedPartnerId,
                        finalPriceOverrideDataList: finalPriceDetailsRef.current,
                    },
                });
            }

            // Note: Please don't refetch this through the query because it will not update values properly when there's a bulk update
            if (
                (markedUpPercentageData && markedUpPercentageData?.data) ||
                (pointsData && pointsData?.data) ||
                (finalPriceData && finalPriceData?.data)
            ) {
                refetchPartnerItemDetails();
            }
        } catch (error) {
            setMessage(`An error ocurred while fetching data ${error.message}`);
            setShowToast(true);
            setError(error);
        }
    };

    const handleModalSave = () => {
        setShowToast(true);
        setMessage(`Conversion updated for ${selectedPartnerName}`);
    };

    const handleFinalPriceReset = useCallback(
        (
            itemId: string,
            paymentChannelId: string,
            sourceCurrency: string,
            targetCurrency: string,
        ) => {
            resetOverriddenFinalPrice({
                variables: {
                    itemId: itemId,
                    partnerId: selectedPartnerId,
                    paymentChannelId: paymentChannelId,
                    sourceCurrency: sourceCurrency,
                    targetCurrency: targetCurrency,
                },
            });
        },
        [selectedPartnerId, resetOverriddenFinalPrice],
    );

    const handleLocalPointsRateReset = useCallback(() => {
        setSavePointsInput({
            ...savePointsInput,
            localCurrencyConversionRate: null,
        });

        resetLocalCurrencyConversion({
            variables: {
                itemId: itemID,
                partnerId: selectedPartnerId,
            },
        });
    }, [itemID, resetLocalCurrencyConversion, savePointsInput, selectedPartnerId]);

    const handleConversionRateReset = (sourceCurrency: string, targetCurrency: string) => {
        setShowConfirmationModal(true);

        setConfirmationMessage({
            body: CURRENCY_CONVERSION_BODY,
            header: CURRENCY_CONVERSION_TITLE,
        });

        setOnConfirmAction(() => () => {
            setShowConversionRateModal(true);
            resetOverride({
                variables: {
                    partnerId: selectedPartnerUid,
                    itemId: itemID,
                    conversion: {
                        sourceCurrency: sourceCurrency,
                        targetCurrency: targetCurrency,
                        resetLevelItem: true,
                    },
                },
            });
        });
    };

    const upsertFinalPrice = useCallback(
        (existingData: OverrideFinalPriceData[], newData: OverrideFinalPriceData) => {
            const index = existingData.findIndex(
                (item) =>
                    item.paymentChannelId === newData.paymentChannelId &&
                    item.sourceCurrency === newData.sourceCurrency &&
                    item.targetCurrency === newData.targetCurrency,
            );

            if (index > -1) {
                existingData[index] = {
                    ...existingData[index],
                    overriddenFinalPrice: newData.overriddenFinalPrice,
                };
            } else {
                existingData.push(newData);
            }
            return existingData;
        },
        [],
    );

    const updateMarkedUpPercentageAndFinalPriceInput = useCallback(
        (paymentChannelId: number, isEnabled: boolean, markedUpPercentage: number) => {
            let updatedState: Array<{
                paymentChannelId: number;
                isEnabled: boolean;
                markedUpPercentage: number;
            }> = [];
            setPartnerItemPaymentChannelInput((prevState) => {
                const existingIndex = prevState.findIndex(
                    (input) => input.paymentChannelId === paymentChannelId,
                );

                if (existingIndex !== -1) {
                    // Update the existing object
                    updatedState = [...prevState];
                    updatedState[existingIndex] = {
                        ...updatedState[existingIndex],
                        isEnabled,
                        markedUpPercentage,
                    };
                } else {
                    // Add a new object to the array
                    updatedState = [
                        ...prevState,
                        { paymentChannelId, isEnabled, markedUpPercentage },
                    ];
                }

                return updatedState;
            });

            return updatedState;
        },
        [],
    );

    useEffect(() => {
        setPartnerItemPaymentChannelDetails([]);
        setPointsData([]);
    }, [selectedPartnerId, selectedPartnerUid, conversionPricingData]);

    const handleConfirmation = () => {
        if (onConfirmAction) {
            onConfirmAction();
        }
        setShowConfirmationModal(false);
    };

    const handleOverridePercentageAndActiveness = useCallback(
        (paymentChannelId: number, isEnabled: boolean, markedUpPercentage: number) => {
            updateMarkedUpPercentageAndFinalPriceInput(
                paymentChannelId,
                isEnabled,
                markedUpPercentage,
            );
        },
        [updateMarkedUpPercentageAndFinalPriceInput],
    );

    const handleEditableCellClick = (row: any) => {
        setShowWarningModal(true);
        setShowConversionRateModal(false);
        setSelectedGlobalCurrency(row);
    };

    const setChannelInfo = useCallback(
        (partnerId: string, channelName: string, targetCurrency: string): void => {
            const channelExists = channels.current.some(
                (channel) => channel.channelName === channelName && channel.partnerId === partnerId,
            );

            if (!channelExists) {
                // Reset channels if partnerId is different
                channels.current = channels.current.filter(
                    (channel) => channel.partnerId === partnerId,
                );

                // Add the new channel info
                channels.current.push({ channelName, targetCurrency, partnerId });
            }
        },
        [],
    );
    const handleSelectChangesModal = () => {
        setConfirmationMessage({
            header: 'Save Changes',
            body: 'Are you sure you want change partner without saving changes',
        });
        setShowConfirmationModal(true);
        setOnConfirmAction(() => () => setIsEditing(false));
    };

    const getUpdatedPaymentChannelData = useCallback(
        (paymentChannelId, existingMarkedUpPercentage, existingIsEnabled) => {
            const updatedActivePaymentChannel = partnerItemPaymentChannelInput.find(
                (pc) => pc.paymentChannelId === paymentChannelId,
            );

            const isEnabled = updatedActivePaymentChannel
                ? updatedActivePaymentChannel.isEnabled
                : existingIsEnabled;

            const markedUpPercentage = updatedActivePaymentChannel
                ? updatedActivePaymentChannel.markedUpPercentage
                : existingMarkedUpPercentage;

            return { updatedActivePaymentChannel, isEnabled, markedUpPercentage };
        },
        [partnerItemPaymentChannelInput],
    );

    const getUnitPrice = useCallback(
        (voucherInventories) => {
            return voucherInventories['unitPrice']
                ? voucherInventories['unitPrice'].toFixed(2)
                : voucherLastInventories['unitPrice']
                ? voucherLastInventories['unitPrice'].toFixed(2)
                : 0;
        },
        [voucherLastInventories],
    );

    const getUpdatedMarkedUpData = useCallback(
        (markedUpPercentage, updatedActivePaymentChannel, row) => {
            let markedUpPrice = row.original.markedUpPrice;

            const conversionRate = row.original?.conversionRate;

            const unitPrice = getUnitPrice(voucherInventories);

            if (
                unitPrice != null &&
                conversionRate != null &&
                markedUpPercentage != null &&
                updatedActivePaymentChannel
            ) {
                markedUpPrice = getMarkedUpPrice(unitPrice, conversionRate, markedUpPercentage);
            }

            return markedUpPrice;
        },
        [getUnitPrice, voucherInventories],
    );

    const baseColums = useMemo(
        () => [
            {
                Header: ' ',
                accessor: 'isChannelActive',
                Cell: function editChannelActiveStatus({ row, value }) {
                    const { isEnabled, markedUpPercentage } = getUpdatedPaymentChannelData(
                        row?.original?.channelId,
                        row?.original?.markedUpPercentage,
                        value,
                    );

                    return (
                        <div>
                            <ReactSwitch
                                id={row?.original?.channelId + '_toggle_switch'}
                                checked={isEnabled}
                                onColor={'#8b5cf6'}
                                onChange={() => {
                                    setIsEditing(true);
                                    handleOverridePercentageAndActiveness(
                                        row?.original?.channelId,
                                        !isEnabled,
                                        markedUpPercentage,
                                    );
                                }}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={24}
                                width={46}
                                disabled={mode == 'view' || mode == 'dealView'}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Channel',
                accessor: 'channelName',
                Cell: function editChannelActiveStatus({ value }) {
                    return (
                        <div className="w-44 break-all">
                            <p className="w-full">{value}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Marked-up (%)',
                accessor: 'markedUpPercentage',
                Cell: function editMarkedUpPercentage({ row, value }) {
                    const { channelName, targetCurrency } = row.original;

                    setChannelInfo(selectedPartnerId, channelName, targetCurrency);

                    const isChannelFirstIndex = channels?.current.some(
                        (channel) =>
                            channel.channelName == channelName &&
                            channel.targetCurrency == targetCurrency,
                    );

                    const isGlobalLevel =
                        row.original.markedUpPercentageOverriddenLevel == 'GLOBAL_LEVEL'
                            ? true
                            : false;

                    const { isEnabled, markedUpPercentage } = getUpdatedPaymentChannelData(
                        row?.original?.channelId,
                        value,
                        row.original.isChannelActive,
                    );

                    return (
                        <div key={row?.original?.channelId} className="flex gap-3">
                            <EditableCell
                                id={
                                    row.original.channelId +
                                    '_markedUpPercentage' +
                                    row.original.targetCurrency
                                }
                                initialValue={markedUpPercentage}
                                row={row}
                                updateValue={(_, value) => {
                                    setIsEditing(true);

                                    handleOverridePercentageAndActiveness(
                                        parseInt(row.original.channelId),
                                        isEnabled,
                                        parseFloat(value),
                                    );
                                }}
                                key={row?.original?.channelId}
                                isDisabled={
                                    mode == 'view' ||
                                    mode == 'dealView' ||
                                    !isEnabled ||
                                    !isChannelFirstIndex
                                }
                                isOverride={!isGlobalLevel && isChannelFirstIndex}
                                validationRule={{
                                    allowEmptyFeild: {
                                        enabled: false,
                                        errorMessage: 'Marked-up Percentage is Required',
                                        messageType: 'Alert',
                                    },
                                    allowValuesBetween1And100: {
                                        enabled: true,
                                        errorMessage:
                                            'Marked-up should be greater than 0 and less than or equal to 100.',
                                        messageType: 'Alert',
                                    },
                                }}
                                changeTextColorOnChange={{
                                    enabled: true,
                                    cellRowAccessor: 'markedUpPercentage',
                                }}
                            />
                            {row?.original?.markedUpPercentageOverriddenLevel == 'ITEM_LEVEL' &&
                                isChannelFirstIndex && (
                                    <div className="flex items-center">
                                        <button
                                            value="menu cursor-pointer"
                                            className="text-gray-500 rounded cursor-default border border-transparent focus:outline-none text-xl"
                                            disabled={
                                                mode == 'view' || mode == 'dealView' || !isEnabled
                                            }
                                            id="currency_conversion_reset"
                                            onClick={() => {
                                                setShowConfirmationModal(true);

                                                setConfirmationMessage({
                                                    body: RESET_MARKED_UP_PERCENTAGE_BODY,
                                                    header: RESET_MARKED_UP_PERCENTAGE_TITLE,
                                                });

                                                setOnConfirmAction(
                                                    () => () =>
                                                        handleResetMarkedUpPercentage(
                                                            row.original.channelId,
                                                        ),
                                                );
                                            }}
                                        >
                                            <BsArrowCounterclockwise
                                                className={`mx-auto ${
                                                    mode == 'view' ||
                                                    mode == 'dealView' ||
                                                    !isEnabled
                                                        ? 'cursor-default'
                                                        : 'cursor-pointer'
                                                }`}
                                            />
                                        </button>
                                    </div>
                                )}
                        </div>
                    );
                },
            },
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function sourceCurrencyCell({ row }) {
                    const sourceCurrency = row.original?.sourceCurrency;
                    if (!sourceCurrency) return null;
                    return (
                        <div className="flex items-center justify-center">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === sourceCurrency)
                                    ?.country.toLowerCase()} flag `}
                            ></i>
                            <p className="text-gray-600">{sourceCurrency}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function sourceCurrencyCell({ row }) {
                    const targetCurrency = row.original?.targetCurrency;
                    if (!targetCurrency) return null;
                    return (
                        <div className="flex items-center justify-center">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === targetCurrency)
                                    ?.country.toLowerCase()} flag`}
                            ></i>
                            <p className="text-gray-600">{targetCurrency}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Currency Conversion',
                accessor: 'conversionRate',
                Cell: function sourceCurrencyCell({ row }) {
                    const conversionRateFormat = getConversionRateFormat(
                        row.original.sourceCurrency,
                        row.original.targetCurrency,
                        row.original?.conversionRate,
                    );
                    return (
                        <div className="flex items-center justify-start">
                            <p className="text-gray-600">{conversionRateFormat}</p>
                        </div>
                    );
                },
            },
            {
                id: 'Price',
                Header: 'price',
                accessor: 'price',
                Cell: function dispayMarkedUpPrice({ row, value }) {
                    return (
                        <div key={row.original.channelId} className="flex gap-3 justify-end">
                            <p>
                                {value
                                    ? typeof value === 'number'
                                        ? value.toFixed(2)
                                        : parseFloat(value).toFixed(2)
                                    : 'N/A'}
                            </p>
                        </div>
                    );
                },
            },
            {
                Header: 'Marked-up Price',
                accessor: 'markedUpPrice',
                Cell: function editConversionRate({ row }) {
                    const { updatedActivePaymentChannel, markedUpPercentage } =
                        getUpdatedPaymentChannelData(
                            row?.original?.channelId,
                            row?.original?.markedUpPercentage,
                            row.original.isChannelActive,
                        );

                    const markedUpPrice = getUpdatedMarkedUpData(
                        markedUpPercentage,
                        updatedActivePaymentChannel,
                        row,
                    );

                    return (
                        <div key={row.original.channelId} className="flex gap-3 justify-end">
                            <p>
                                {markedUpPrice
                                    ? typeof markedUpPrice === 'number'
                                        ? markedUpPrice.toFixed(2)
                                        : parseFloat(markedUpPrice).toFixed(2)
                                    : 'N/A'}
                            </p>
                        </div>
                    );
                },
            },
        ],
        [
            getUpdatedPaymentChannelData,
            mode,
            handleOverridePercentageAndActiveness,
            setChannelInfo,
            selectedPartnerId,
            handleResetMarkedUpPercentage,
            getUpdatedMarkedUpData,
        ],
    );

    const paymentChannelColumns = React.useMemo(
        () => [
            ...baseColums,
            {
                Header: 'Final Price',
                accessor: 'finalPrice',
                Cell: function editFinalPrice({ row }) {
                    const { isEnabled, markedUpPercentage } = getUpdatedPaymentChannelData(
                        row?.original?.channelId,
                        row?.original?.markedUpPercentage,
                        row.original.isChannelActive,
                    );

                    const updatedMarkedUpPercentageData = partnerItemPaymentChannelInput.find(
                        (markedUpPercentageData) =>
                            markedUpPercentageData.paymentChannelId == row?.original?.channelId,
                    );

                    const updatedMarkedUpPriceData = finalPriceDetailsRef.current.find(
                        (finalPriceData) =>
                            finalPriceData.paymentChannelId == row?.original?.channelId &&
                            finalPriceData.sourceCurrency == row?.original?.sourceCurrency &&
                            finalPriceData.targetCurrency == row?.original?.targetCurrency,
                    );

                    let markedUpPrice = updatedMarkedUpPriceData
                        ? updatedMarkedUpPriceData.overriddenFinalPrice
                        : row.original.finalPrice;

                    const conversionRate = row.original?.conversionRate;

                    const unitPrice = getUnitPrice(voucherInventories);

                    if (
                        unitPrice != null &&
                        conversionRate != null &&
                        markedUpPercentage != null &&
                        updatedMarkedUpPercentageData &&
                        !updatedMarkedUpPriceData &&
                        row.original.finalPriceOverridden === false
                    ) {
                        markedUpPrice = getMarkedUpPrice(
                            unitPrice,
                            conversionRate,
                            markedUpPercentage,
                        );
                    }

                    return (
                        <div key={row?.original?.id} className="flex gap-3">
                            <EditableCell
                                id={`${row?.original?.channelId}-final-price-cell`}
                                initialValue={markedUpPrice}
                                row={row}
                                updateValue={(_, value) => {
                                    setIsEditing(true);
                                    const newFinalPriceDetails = {
                                        paymentChannelId: row?.original?.channelId,
                                        sourceCurrency: row?.original?.sourceCurrency,
                                        targetCurrency: row?.original?.targetCurrency,
                                        overriddenFinalPrice: parseFloat(value),
                                    };

                                    finalPriceDetailsRef.current = upsertFinalPrice(
                                        finalPriceDetailsRef.current,
                                        newFinalPriceDetails,
                                    );
                                }}
                                key={row?.original?.channelId}
                                changeTextColorOnChange={{
                                    enabled: true,
                                    cellRowAccessor: 'finalPrice',
                                }}
                                isDisabled={mode == 'dealView' || mode == 'view' || !isEnabled}
                                isOverride={row.original.finalPriceOverridden}
                                validationRule={{
                                    allowEmptyFeild: {
                                        enabled: false,
                                        errorMessage: 'Final price is Required',
                                        messageType: 'Alert',
                                    },
                                    allowValuesLessThan0: {
                                        enabled: false,
                                        errorMessage: 'Final price should be greater than 0',
                                        messageType: 'Alert',
                                    },
                                }}
                            />
                            {row?.original?.finalPriceOverridden && (
                                <div className="flex items-center">
                                    <button
                                        type="reset"
                                        value="menu cursor-pointer"
                                        disabled={
                                            mode == 'view' || mode == 'dealView' || !isEnabled
                                        }
                                        className="text-gray-500 rounded border border-transparent cursor-default focus:outline-none text-xl"
                                        id="finalPriceNonPointsChannel_reset"
                                        onClick={() => {
                                            setShowConfirmationModal(true);

                                            setConfirmationMessage({
                                                body: FINAL_PRICE_MESSAGE,
                                                header: FINAL_MARKED_UP_PRICE_TITLE,
                                            });

                                            setOnConfirmAction(
                                                () => () =>
                                                    handleFinalPriceReset(
                                                        itemID,
                                                        row.original.channelId,
                                                        row.original.sourceCurrency,
                                                        row.original.targetCurrency,
                                                    ),
                                            );
                                        }}
                                    >
                                        <BsArrowCounterclockwise
                                            className={`mx-auto ${
                                                mode == 'view' || mode == 'dealView' || !isEnabled
                                                    ? 'cursor-default'
                                                    : 'cursor-pointer'
                                            }`}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            ...(mode == 'dealView'
                ? [
                      {
                          Header:
                              'Deal Price (-' +
                              viewDealDetails.dealPercentageDetails.dealPercentage +
                              '%)',
                          accessor: '',
                          Cell: function displayDealPrice({ row }) {
                              const dealPrice = calculateDealPrice(
                                  row.original.finalPrice,
                                  viewDealDetails.dealPercentageDetails.dealPercentage,
                                  viewDealDetails.dealPercentageDetails
                                      .finalPriceRoundOffDecimalPlace,
                                  viewDealDetails.dealPercentageDetails.finalPriceRoundOffBoundary,
                              );

                              return (
                                  <div
                                      key={row.original.channelId}
                                      className="flex gap-3 justify-end"
                                  >
                                      <p>{dealPrice == 0 ? 'N/A' : dealPrice.toFixed(2)}</p>
                                  </div>
                              );
                          },
                      },
                  ]
                : []),
        ],
        [
            baseColums,
            getUpdatedPaymentChannelData,
            partnerItemPaymentChannelInput,
            getUnitPrice,
            voucherInventories,
            mode,
            upsertFinalPrice,
            handleFinalPriceReset,
            itemID,
            viewDealDetails,
        ],
    );

    const pointsPaymentChannelColumns = useMemo(
        () => [
            ...baseColums,
            {
                Header: 'Points Conversion',
                accessor: 'localPointsRate',

                Cell: function editPointsConversion({ row }) {
                    const {
                        localPointsRate,
                        localPointsRateOverriddenLevel,
                        isChannelActive,
                        channelId,
                    } = row.original;

                    const isGlobalLevel =
                        localPointsRateOverriddenLevel == 'GLOBAL_LEVEL' ? true : false;

                    const localPointsValue =
                        savePointsInput.localCurrencyConversionRate != null
                            ? savePointsInput.localCurrencyConversionRate
                            : localPointsRate;

                    const { isEnabled } = getUpdatedPaymentChannelData(
                        channelId,
                        row?.original?.markedUpPercentage,
                        isChannelActive,
                    );

                    return (
                        <div key={channelId} className="flex gap-3">
                            <EditableCell
                                id={`${channelId}-pointsConversion`}
                                initialValue={localPointsValue}
                                row={row}
                                updateValue={(_, value) => {
                                    setIsEditing(true);
                                    setSavePointsInput({
                                        ...savePointsInput,
                                        localCurrencyConversionRate: value,
                                    });
                                }}
                                key={row?.original?.channelId}
                                changeTextColorOnChange={{
                                    enabled: true,
                                    cellRowAccessor: 'localPointsRate',
                                }}
                                isDisabled={mode == 'view' || mode == 'dealView' || !isEnabled}
                                isOverride={!isGlobalLevel}
                                validationRule={{
                                    allowEmptyFeild: {
                                        enabled: false,
                                        errorMessage: 'Points Conversation is Required',
                                        messageType: 'Alert',
                                    },
                                    allowValuesLessThan0: {
                                        enabled: false,
                                        errorMessage:
                                            'Points Conversation should be greater than 0',
                                        messageType: 'Alert',
                                    },
                                }}
                            />
                            {localPointsRateOverriddenLevel == 'ITEM_LEVEL' && (
                                <div className="flex items-center">
                                    <button
                                        type="reset"
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-default border border-transparent focus:outline-none text-xl"
                                        id="pointsConversionReset"
                                        disabled={mode == 'view' || !isEnabled}
                                        onClick={() => {
                                            setShowConfirmationModal(true);

                                            setConfirmationMessage({
                                                body: POINTS_CONVERSION_BODY,
                                                header: POINTS_CONVERSION_TITLE,
                                            });

                                            setOnConfirmAction(
                                                () => () => handleLocalPointsRateReset(),
                                            );
                                        }}
                                    >
                                        <BsArrowCounterclockwise
                                            className={`mx-auto ${
                                                mode == 'view' || mode == 'dealView' || !isEnabled
                                                    ? 'cursor-default'
                                                    : 'cursor-pointer'
                                            }`}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Item Points',
                accessor: '',
                Cell: function editConversionRate({ row }) {
                    const localPointsRate =
                        savePointsInput?.localCurrencyConversionRate ||
                        row.original.localPointsRate;

                    const { updatedActivePaymentChannel, markedUpPercentage } =
                        getUpdatedPaymentChannelData(
                            row?.original?.channelId,
                            row?.original?.markedUpPercentage,
                            row.original.isChannelActive,
                        );

                    const markedUpPrice = getUpdatedMarkedUpData(
                        markedUpPercentage,
                        updatedActivePaymentChannel,
                        row,
                    );

                    const itemPoints =
                        markedUpPrice != null && localPointsRate != null
                            ? getPointsValue(markedUpPrice, localPointsRate)
                            : null;

                    return (
                        <div
                            key={row?.original?.id}
                            className="flex gap-3 justify-center items-center"
                        >
                            <p>{itemPoints == null ? 'N/A' : itemPoints}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Price in Points (Rounded)',
                accessor: 'roundedPointsValue',
                Cell: function editConversionRate({ row }) {
                    const localPointsRate = savePointsInput?.localCurrencyConversionRate
                        ? savePointsInput?.localCurrencyConversionRate
                        : row.original.localPointsRate;

                    const { updatedActivePaymentChannel, markedUpPercentage } =
                        getUpdatedPaymentChannelData(
                            row?.original?.channelId,
                            row?.original?.markedUpPercentage,
                            row.original.isChannelActive,
                        );

                    const markedUpPrice = getUpdatedMarkedUpData(
                        markedUpPercentage,
                        updatedActivePaymentChannel,
                        row,
                    );

                    const roundedPointsValue =
                        markedUpPrice != null && localPointsRate != null
                            ? getRoundedPointsValue(markedUpPrice, localPointsRate)
                            : null;

                    return (
                        <div
                            key={row?.original?.id}
                            className="flex gap-3 justify-center items-center"
                        >
                            <p>{roundedPointsValue == null ? 'N/A' : roundedPointsValue}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'Final Price',
                accessor: 'finalPrice',
                Cell: function editPointsFinalPrice({ row }) {
                    const { finalPriceOverridden, channelId, sourceCurrency, targetCurrency } =
                        row.original;

                    const { isEnabled, markedUpPercentage } = getUpdatedPaymentChannelData(
                        channelId,
                        row?.original?.markedUpPercentage,
                        row.original.isChannelActive,
                    );

                    const updatedMarkedUpPercentageData = partnerItemPaymentChannelInput.find(
                        (markedUpPercentageData) =>
                            markedUpPercentageData.paymentChannelId == row?.original?.channelId,
                    );

                    const updatedMarkedUpPriceData = finalPriceDetailsRef.current.find(
                        (finalPriceData) =>
                            finalPriceData.paymentChannelId == row?.original?.channelId &&
                            finalPriceData.sourceCurrency == row?.original?.sourceCurrency &&
                            finalPriceData.targetCurrency == row?.original?.targetCurrency,
                    );

                    let markedUpPrice = row.original.markedUpPrice;

                    const conversionRate = row.original?.conversionRate;

                    const unitPrice = getUnitPrice(voucherInventories);

                    if (
                        unitPrice != null &&
                        conversionRate != null &&
                        markedUpPercentage != null &&
                        updatedMarkedUpPercentageData &&
                        !updatedMarkedUpPriceData &&
                        row.original.finalPriceOverridden === false
                    ) {
                        markedUpPrice = getMarkedUpPrice(
                            unitPrice,
                            conversionRate,
                            markedUpPercentage,
                        );
                    }

                    let finalPrice = updatedMarkedUpPriceData
                        ? updatedMarkedUpPriceData.overriddenFinalPrice
                        : row.original.finalPrice;

                    if (!updatedMarkedUpPriceData && row.original.finalPriceOverridden === false) {
                        const localPointsRate =
                            savePointsInput?.localCurrencyConversionRate ||
                            row.original.localPointsRate;

                        finalPrice =
                            markedUpPrice != null && localPointsRate != null
                                ? getRoundedPointsValue(markedUpPrice, localPointsRate)
                                : null;
                    }

                    return (
                        <div className="flex gap-3">
                            <EditableCell
                                id={`${row?.original?.id}-PointsfinalRetailPrice`}
                                initialValue={finalPrice}
                                row={row}
                                isDisabled={mode == 'view' || mode == 'dealView' || !isEnabled}
                                isOverride={finalPriceOverridden}
                                updateValue={(_, value) => {
                                    setIsEditing(true);

                                    const newFinalPriceDetails = {
                                        paymentChannelId: row?.original?.channelId,
                                        sourceCurrency: row?.original?.sourceCurrency,
                                        targetCurrency: row?.original?.targetCurrency,
                                        overriddenFinalPrice: parseFloat(value),
                                    };

                                    finalPriceDetailsRef.current = upsertFinalPrice(
                                        finalPriceDetailsRef.current,
                                        newFinalPriceDetails,
                                    );
                                }}
                                validationRule={{
                                    allowEmptyFeild: {
                                        enabled: false,
                                        errorMessage: 'Final Retail price is Required',
                                        messageType: 'Alert',
                                    },
                                    allowValuesLessThan0: {
                                        enabled: false,
                                        errorMessage: 'Final Retail price should be greater than 0',
                                        messageType: 'Alert',
                                    },
                                }}
                                changeTextColorOnChange={{
                                    enabled: true,
                                    cellRowAccessor: 'finalPrice',
                                }}
                                key={row?.original?.channelId}
                            />

                            {finalPriceOverridden && (
                                <div className="flex">
                                    <button
                                        type="reset"
                                        disabled={mode == 'view' || !isEnabled}
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-default border border-transparent focus:outline-none text-xl"
                                        id="pointsConversionReset"
                                        onClick={() => {
                                            setShowConfirmationModal(true);

                                            setConfirmationMessage({
                                                header: FINAL_PRICE_TITLE,
                                                body: FINAL_PRICE_MESSAGE,
                                            });

                                            setOnConfirmAction(
                                                () => () =>
                                                    handleFinalPriceReset(
                                                        itemID,
                                                        channelId,
                                                        sourceCurrency,
                                                        targetCurrency,
                                                    ),
                                            );
                                        }}
                                    >
                                        <BsArrowCounterclockwise
                                            className={`mx-auto ${
                                                mode == 'view' || mode == 'dealView' || !isEnabled
                                                    ? 'cursor-default'
                                                    : 'cursor-pointer'
                                            }`}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            ...(mode == 'dealView'
                ? [
                      {
                          Header:
                              'Deal Price (-' +
                              viewDealDetails.dealPercentageDetails.dealPercentage +
                              '%)',
                          accessor: '',
                          Cell: function displayDealPrice({ row }) {
                              const dealPrice = calculateDealPrice(
                                  row.original.finalPrice,
                                  viewDealDetails.dealPercentageDetails.dealPercentage,
                                  viewDealDetails.dealPercentageDetails
                                      .finalPriceRoundOffDecimalPlace,
                                  viewDealDetails.dealPercentageDetails.finalPriceRoundOffBoundary,
                              );

                              return (
                                  <div
                                      key={row.original.channelId}
                                      className="flex gap-3 justify-end"
                                  >
                                      <p>{dealPrice.toFixed(2)}</p>
                                  </div>
                              );
                          },
                      },
                  ]
                : []),
        ],
        [
            baseColums,
            savePointsInput,
            mode,
            handleLocalPointsRateReset,
            getUpdatedPaymentChannelData,
            getUpdatedMarkedUpData,
            partnerItemPaymentChannelInput,
            getUnitPrice,
            voucherInventories,
            upsertFinalPrice,
            handleFinalPriceReset,
            itemID,
            viewDealDetails,
        ],
    );

    const handleOnClickUpdate = () => {
        if (mode == 'view') {
            history.push('/products');
        }
    };

    const handleSelectedPartners = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPartnerId(event.target.value);
        const selectedPartnerDetails = itemMappedPartners.filter(
            (partner) => partner.id == event.target.value,
        );
        const partnerUid = selectedPartnerDetails[0].partnerId;
        const partnerName = selectedPartnerDetails[0].partnerName;
        setSelectedPartnerUid(partnerUid);
        setSelectedPartnerName(partnerName);
    };

    const handleShowModal = () => {
        setShowConversionRateModal((prev) => !prev);
    };

    return (
        <>
            {partnerDetailsLoading || paymentChannelDataLoading ? (
                <Loader />
            ) : (
                <div className="px-8 flex flex-col justify-between">
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-8/12"
                            error={error}
                            selfDestruct={true}
                            selfDestructTimer={3000}
                            style={'m-0 p-0'}
                        />
                    )}
                    <UnsaveChangesWarning
                        contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                        messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                        when={isEditing}
                        navigate={(path) => history.push(path)}
                        shouldBlockNavigation={() => {
                            if (isEditing) {
                                return true;
                            }
                            return false;
                        }}
                        displayIcon={true}
                        displayIconName={'clarity_error-standard-line-svg.svg'}
                    />
                    {itemMappedPartners.length < 1 && mode != 'dealView' ? (
                        <div className="text-xl font-poppins font-bold items-center justify-center flex">
                            <p>{`No partner catalogs have been mapped to this product`}</p>
                        </div>
                    ) : (
                        <div className="px-10 w-10/12">
                            <div className="flex mt-5">
                                <div className="text-xl font-poppins font-bold items-center justify-center flex">
                                    {mode == 'dealView' ? (
                                        <div className="pr-4">
                                            <BackButton
                                                onClick={() =>
                                                    viewDealDetails.dealMode == 'view'
                                                        ? history.push('/deals/select-products')
                                                        : history.push(
                                                              `/deal/products-edit/${dealId}`,
                                                          )
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    <p>
                                        {`${
                                            mode == 'dealView'
                                                ? 'View Deal Prices'
                                                : mode == 'view'
                                                ? 'View Conversions & Prices'
                                                : 'Edit Conversions & Prices'
                                        } `}
                                    </p>
                                </div>
                                <div className="border-2 rounded-md flex items-center justify-center ml-auto text-purple-500 border-purple-500 hover:bg-purple-500 hover:text-white cursor-pointer">
                                    <button
                                        className="flex w-48 h-9 items-center justify-center"
                                        onClick={handleShowModal}
                                        type="button"
                                    >
                                        <BsArrowRepeat size={16} />
                                        <p className="pl-2 text-sm font-poppins font-bold">
                                            Conversion Rates
                                        </p>
                                    </button>
                                </div>
                            </div>
                            <div className="w-full pb-24 mt-10">
                                <div className="border-2 rounded-xl w-full">
                                    <div className="flex justify-center py-5 px-5 items-center">
                                        <div className="flex flex-wrap flex-col justify-center">
                                            {mode == 'dealView' ? (
                                                <p className="text-base font-poppins font-bold text-gray-500">
                                                    Partner Name :
                                                    <span className="text-black pl-2 text-base">
                                                        {selectedPartnerName}
                                                    </span>
                                                    <p className="pt-1">
                                                        Item Name :
                                                        <span className="text-black pl-2 text-base">
                                                            {productData?.itemName}
                                                        </span>
                                                    </p>
                                                </p>
                                            ) : itemMappedPartners.length > 0 ? (
                                                <SelectLabel
                                                    labelHeading="Partner"
                                                    width={80}
                                                    handleSelectedValue={handleSelectedPartners}
                                                    options={transformedPartners}
                                                    disableOtherOptions={{
                                                        isEditing: isEditing,
                                                        currentSelectedValue: selectedPartnerId,
                                                        showSaveChangesModal:
                                                            handleSelectChangesModal,
                                                    }}
                                                />
                                            ) : null}
                                        </div>

                                        <div className="ml-auto">
                                            <div className="flex py-5 items-baseline">
                                                <p className="text-base font-poppins font-bold text-gray-500 pr-1">
                                                    Product Cost & Currency :
                                                </p>
                                                <div className="flex flex-row">
                                                    <p className="text-sm font-poppins font-bold text-black pl-1">
                                                        {getUnitPrice(voucherInventories)}
                                                    </p>
                                                    <p className="text-sm font-poppins font-bold text-black pl-1">
                                                        {voucherInventories['currency']
                                                            ? voucherInventories['currency']
                                                            : voucherLastInventories['currency']
                                                            ? voucherLastInventories['currency']
                                                            : ''}
                                                    </p>
                                                    <i
                                                        className={`${currencyAndCountryCodes
                                                            .find(
                                                                (x) =>
                                                                    x.currency ===
                                                                    (voucherInventories[
                                                                        'currency'
                                                                    ] ||
                                                                        voucherLastInventories[
                                                                            'currency'
                                                                        ]),
                                                            )
                                                            ?.country.toLowerCase()} af flag px-1 pt-1`}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(partnerItemPaymentChannelDetails.length > 0 ||
                                        pointsData.length > 0) &&
                                    !paymentChannelDataLoading ? (
                                        <>
                                            {partnerItemPaymentChannelDetails.length > 0 && (
                                                <PaymentChannelsDataTable
                                                    columns={paymentChannelColumns}
                                                    data={partnerItemPaymentChannelDetails}
                                                    pageCount={-1}
                                                    checkbox={false}
                                                    hidePagination={true}
                                                    customizedPageSize={
                                                        partnerItemPaymentChannelDetails.length
                                                    }
                                                    loading={paymentChannelDataLoading}
                                                />
                                            )}
                                            {pointsData.length > 0 && (
                                                <PaymentChannelsDataTable
                                                    columns={pointsPaymentChannelColumns}
                                                    data={pointsData}
                                                    checkbox={false}
                                                    tableType={PRODUCT_POINTS_TABLE_TYPE}
                                                    hidePagination={true}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <div className="m-auto flex flex-col w-auto">
                                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                {paymentChannelColumns?.map((column, index) => (
                                                    <div
                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                        key={index}
                                                    >
                                                        {column?.Header}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="m-auto p-10">
                                                <p>No Data Found</p>
                                            </div>
                                            <div className="p-10 bg-purple-100"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 mt-10 justify-end bg-gray-100 fixed bottom-0 right-0">
                        <Buttons
                            name={mode == 'dealView' ? 'Close' : mode == 'view' ? 'Edit' : 'Cancel'}
                            type="submit"
                            buttonType="secondary-main-grey"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                console.log(mode);
                                mode == 'dealView'
                                    ? history.push(`/deal/products-edit/${dealId}`)
                                    : mode == 'view'
                                    ? history.push(`/products/pricing-edit/${parameters.id}`)
                                    : history.push('/products');
                            }}
                            other="ml-5"
                        />
                        {mode != 'dealView' ? (
                            <Buttons
                                name={
                                    mode == 'view' ? (
                                        'Close'
                                    ) : markedUpPercentageDataUpdateLoading ? (
                                        <NormalLoader />
                                    ) : (
                                        'Save'
                                    )
                                }
                                type={'submit'}
                                buttonType="primary"
                                id="update"
                                size="e-small"
                                onclick={() => {
                                    mode == 'view' ? handleOnClickUpdate() : handleClickSave();
                                }}
                                disabled={mode !== 'view' && !isEditing}
                            />
                        ) : null}
                    </div>
                    <div className="w-full">
                        <Confirmation
                            showConfirmationModal={showConfirmationModal}
                            setShowConfirmationModal={setShowConfirmationModal}
                            messageTitle={confirmationMessage.header}
                            message={confirmationMessage.body}
                            setYesBtnClick={handleConfirmation}
                            setNoBtnClick={() => setShowConfirmationModal(false)}
                        />

                        <ViewConversionRateModal
                            mode={mode}
                            setShowConversionModal={handleShowModal}
                            showConversionModal={showConversionRateModal}
                            partnerName={selectedPartnerName}
                            dataTableLoadingSkeleton={true}
                            handleModalSave={handleModalSave}
                            handleConversionRateReset={handleConversionRateReset}
                            handleEditableCellClick={handleEditableCellClick}
                            selectedPartnerUid={selectedPartnerUid}
                            itemId={itemID}
                        />

                        <WarningMessageModal
                            showWarningModal={showWarningModal}
                            setShowWarningModal={setShowWarningModal}
                            warningModalTitle="Alert"
                            warningModalBody={PARTNER_ITEM_GLOBAL_CURRENCY_SET_MESSAGE}
                            setProcessFinishedClicked={() => setShowConversionRateModal(true)}
                            isGlobalCurrencyUpdateEnabled={setItNowButtonEnabled}
                            setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                            closeModalsAfterSetItNowBtn={() => {
                                setShowWarningModal(false);
                            }}
                        />
                        <WarningMessageModal
                            showWarningModal={dealValidationShowWarningModal}
                            setShowWarningModal={setDealValidationShowWarningModal}
                            warningModalBody={dealValidationWarningModalBody}
                            warningModalTitle={dealValidationWarningModalTitle}
                        />

                        <GlobalCurrencyUpdateModal
                            messageTitle={'Global Currency Conversion'}
                            globalCurrencyUpdateModal={globalCurrencyUpdateModal}
                            setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                            data={selectedGlobalCurrency}
                            callAfterProcessFinished={() => {
                                refetchPartnerItemDetails({ itemId: itemID, partnerId: partnerID });
                                setMessage('Global level conversion updated');
                                setShowToast(true);
                                setShowWarningModal(false);
                                setSetItNowButtonEnabled(true);
                                setGlobalCurrencyUpdateModal(false);
                                setShowConversionRateModal(true);
                            }}
                            callAfterCloseBtnClicked={() => {
                                setShowWarningModal(true);
                            }}
                            hideCurrencyModalFromConfirmation={true}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
