import { PaymentMethod } from '../enums/payment';

export function calculateCostPrice(sourceCostPrice, currencyConversionRate) {
    return sourceCostPrice * currencyConversionRate;
}

export function preparePartnerCurrencyConversions(
    costPrice,
    currencyConversions,
    markedUpPercentageForCurrency,
    markedUpPercentage,
    markedUpPercentageForPsp,
) {
    return currencyConversions?.map((currencyConversion) => ({
        ...currencyConversion,
        targetPrice: (costPrice * currencyConversion?.conversionRate).toFixed(2),
        markedUpPriceForCurrency: roundHalfUp(
            costPrice * currencyConversion?.conversionRate +
                (costPrice * currencyConversion?.conversionRate * markedUpPercentageForCurrency) /
                    100,
            2,
        ),
        markedUpPrice: roundHalfUp(
            costPrice * currencyConversion?.conversionRate +
                (costPrice * currencyConversion?.conversionRate * markedUpPercentage) / 100,
            2,
        ),
        markedUpPriceForPsp: roundHalfUp(
            costPrice * currencyConversion?.conversionRate +
                (costPrice * currencyConversion?.conversionRate * markedUpPercentageForPsp) / 100,
            2,
        ),
    }));
}

export function roundHalfUp(number, decimalPlaces) {
    const factor = 10 ** decimalPlaces;
    const roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber.toFixed(2);
}

export function pointsRoundHalfUp(number, decimalPlaces) {
    const factor = 10 ** decimalPlaces;
    const roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber;
}

export function updatePartnerItemCurrencyConverions(conversions, index, rate) {
    const oldObj = conversions[index];

    if (oldObj) {
        Object.assign(conversions[index], { ...oldObj, conversionRate: rate });
    }

    return conversions;
}

export function updatePartnerItemFinalPrice(conversions, index, finalPriceOverriddenDetails) {
    const oldObj = conversions[index];

    if (oldObj && finalPriceOverriddenDetails?.paymentMethod === PaymentMethod.DCB) {
        Object.assign(conversions[index], {
            ...oldObj,
            finalPriceOverriddenDetails,
            finalPriceDCBOverridden: finalPriceOverriddenDetails?.finalPriceOverriddenDCB,
        });
    } else if (oldObj && finalPriceOverriddenDetails?.paymentMethod === PaymentMethod.PSP) {
        Object.assign(conversions[index], {
            ...oldObj,
            finalPriceOverriddenDetails,
            finalPricePSPOverridden: finalPriceOverriddenDetails?.finalPriceOverriddenPSP,
        });
    }

    return conversions;
}

export function getUpdatedOverridedConversions(
    existingConversions,
    newConversion,
    markedUpPercentagePSP,
    markedUpPercentageDCB,
    unitPrice,
) {
    const index = existingConversions?.findIndex(
        (conversion) =>
            conversion?.sourceCurrency === newConversion?.sourceCurrency &&
            conversion?.targetCurrency === newConversion?.targetCurrency,
    );

    if (index > -1) {
        Object.assign(existingConversions[index], {
            ...existingConversions[index],
            conversionRate: newConversion?.conversionRate,
            finalPriceOverriddenDetails: {
                ...newConversion?.finalPriceOverriddenDetails,
                finalPriceOverriddenPSP: newConversion?.finalPricePSPOverridden,
                finalPriceOverriddenDCB: newConversion?.finalPriceDCBOverridden,
                markedUpPriceDCB: newConversion?.markedUpPriceForCurrency,
                markedUpPricePSP: newConversion?.markedUpPriceForPsp,
                markedUpPercentagePSP: markedUpPercentagePSP,
                markedUpPercentageDCB: markedUpPercentageDCB,
                unitPrice: unitPrice,
            },
        });
    } else {
        const overrideConversionObject = {
            sourceCurrency: newConversion.sourceCurrency,
            targetCurrency: newConversion.targetCurrency,
            conversionRate: newConversion.conversionRate,
            finalPriceOverriddenDetails: {
                ...newConversion?.finalPriceOverriddenDetails,
                finalPriceOverriddenPSP: newConversion?.finalPricePSPOverridden,
                finalPriceOverriddenDCB: newConversion?.finalPriceDCBOverridden,
                markedUpPriceDCB: newConversion?.markedUpPriceForCurrency,
                markedUpPricePSP: newConversion?.markedUpPriceForPsp,
                markedUpPercentagePSP: markedUpPercentagePSP,
                markedUpPercentageDCB: markedUpPercentageDCB,
                unitPrice: unitPrice,
            },
        };

        existingConversions?.push(overrideConversionObject);
    }

    return existingConversions;
}

export function filterRelevantCurrencies(currencyConversions) {
    return currencyConversions?.filter(
        (currencyConversion) =>
            currencyConversion?.sourceCurrency !== currencyConversion?.targetCurrency,
    );
}

export function getFinalMarkedUpPrice(overridenPrice, calculatedPrice) {
    return overridenPrice
        ? overridenPrice
        : calculatedPrice
        ? parseFloat(calculatedPrice).toFixed(2)
        : 'N/A';
}

export function getFinalPointsValue(overridenPrice, calculatedPrice) {
    return overridenPrice ? overridenPrice : calculatedPrice ? calculatedPrice : 'N/A';
}

export function getMarkedUpPrice(costPrice, conversionRate, markedUpPercentage) {
    return roundHalfUp(
        costPrice * conversionRate + (costPrice * conversionRate * markedUpPercentage) / 100,
        2,
    );
}

const roundUpToSecondDigit = (value) => {
    if (value <= 1) {
        return 1;
    }

    if (value < 10) {
        // Round up to the nearest whole number (integer)
        return Math.ceil(value);
    }

    // For values 10 or greater, divide by 10, round up to the nearest whole number, then multiply by 10
    const roundedValue = Math.ceil(value / 10);
    return roundedValue * 10;
};

export const getPointsValue = (markedUpPrice, pointsConversionRate) => {
    return pointsRoundHalfUp(markedUpPrice * pointsConversionRate, 1);
};

export const getRoundedPointsValue = (markedUpPrice, pointsConversionRate) => {
    return pointsRoundHalfUp(roundUpToSecondDigit(markedUpPrice * pointsConversionRate), 2);
};
