/**
 * Props for the SelectLabel component.
 * @prop width - Specifies the width of the SelectLabel component. Accepts a value corresponding to Tailwind's width utility classes (e.g., 64 for w-64).
 * @prop options - An array of options to populate the dropdown. Each option should conform to the `OptionType` interface.
 * @prop handleSelectedValue - A callback function triggered on selecting a dropdown value. Receives the `React.ChangeEvent<HTMLSelectElement>` event as an argument.
 * @Prop disableOtherOptions has isEditing: we can pass a boolean to ensure that other select option are disabled when user is editing something related to current value. currentSelectedValue - if isEditing is true, it disables other option except selected one.
 * @Prop innerTextPadding - Adds padding to the labs selected value (inner text) can be used to control height of Label.
 */
interface PropType {
    width?: number;
    options: OptionType[];
    innerTextPadding?: number;
    handleSelectedValue: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    labelHeading: string;
    disableOtherOptions?: {
        isEditing: boolean;
        currentSelectedValue: string;
        showSaveChangesModal?: () => void;
    };
}

interface OptionType {
    id: string;
    value: string;
}

export const SelectLabel: React.FC<PropType> = ({
    width,
    options,
    handleSelectedValue,
    labelHeading,
    disableOtherOptions,
    innerTextPadding = 3,
}: PropType) => {
    const currentSelectedValue = disableOtherOptions?.currentSelectedValue;
    return (
        <>
            <div
                className={`flex flex-wrap flex-col absolute max-w-sm mx-auto w-auto  lg:w-${width}`}
            >
                <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                    {labelHeading}
                </div>
                <select
                    className={`border-2  bg-white border-gray-400 hover:border-purple-500 rounded-xl outline-none selectdropdownIconWithoutPadding p-${innerTextPadding}`}
                    id="add_partner"
                    value={currentSelectedValue}
                    onChange={handleSelectedValue}
                    onClick={(e) => {
                        if (disableOtherOptions?.isEditing) {
                            e.preventDefault();
                            (e.target as HTMLSelectElement).blur();
                            disableOtherOptions.showSaveChangesModal();
                        }
                    }}
                >
                    {options?.map((optionValue) => {
                        return (
                            <option
                                value={optionValue.id}
                                key={optionValue.id}
                                disabled={
                                    disableOtherOptions?.isEditing &&
                                    optionValue.value != disableOtherOptions?.currentSelectedValue
                                }
                            >
                                {optionValue.value}
                            </option>
                        );
                    })}
                </select>
            </div>
        </>
    );
};
