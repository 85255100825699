import { RoundOffBoundary, RoundOffPlace } from '../types/deals';
import { roundToLowerBoundary, roundToUpperBoundary } from './valueRoundHelper.helpers';

export const calculateDealPrice = (
    finalPrice: number,
    dealPercentage: number,
    finalPriceRoundOffDecimalPlace: number | string,
    finalPriceRoundOffBoundary: string | number,
) => {
    const dealPrice = finalPrice != 0 ? (finalPrice * (100 - dealPercentage)) / 100 : 0;

    let roundOffPlace = null;
    if (finalPriceRoundOffDecimalPlace != null && finalPriceRoundOffDecimalPlace != '') {
        if (finalPriceRoundOffDecimalPlace == RoundOffPlace[RoundOffPlace.WHOLE_NUMBER]) {
            roundOffPlace = 1;
        } else if (finalPriceRoundOffDecimalPlace == RoundOffPlace[RoundOffPlace.FIRST_DECIMAL]) {
            roundOffPlace = 10;
        } else if (finalPriceRoundOffDecimalPlace == RoundOffPlace[RoundOffPlace.SECOND_DECIMAL]) {
            roundOffPlace = 100;
        }
    }

    let roundedDealPrice = dealPrice;

    if (
        finalPriceRoundOffBoundary != null &&
        finalPriceRoundOffBoundary != '' &&
        roundOffPlace != null
    ) {
        if (finalPriceRoundOffBoundary == RoundOffBoundary[RoundOffBoundary.UPPER_BOUNDARY]) {
            roundedDealPrice = roundToUpperBoundary(dealPrice, roundOffPlace);
        } else if (
            finalPriceRoundOffBoundary == RoundOffBoundary[RoundOffBoundary.LOWER_BOUNDARY]
        ) {
            roundedDealPrice = roundToLowerBoundary(dealPrice, roundOffPlace);
        }
    }

    return roundedDealPrice;
};
