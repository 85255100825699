import React, { ReactElement, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Buttons } from '../../ui/atoms/Button';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_ORDER } from '../../../queries/OrdersQueries';
import { Modal } from 'semantic-ui-react';
import {
    CANCELLED_ORDERS,
    COMPLETE_ORDERS,
    PROCESSING_ORDERS,
    REFUNDED_ORDERS,
} from '../../../constants/order';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { FETCH_DEAL_BY_ID } from '../../../queries/DealQueries';

interface Props {
    showVerifyModal: boolean;
    setShowVerifyModal: (value: boolean) => void;
    setLoadingViewOne: (value: boolean) => void;
}

export default function ViewOneOrder({
    showVerifyModal,
    setShowVerifyModal,
    setLoadingViewOne,
}: Props): ReactElement {
    const currentOrderID = localStorage.getItem('orderId');
    const [dealName, setDealName] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [isDealPurchase, setIsDealPurchase] = useState(false);

    const [loadOrderData, { data: currentOrderDetails, loading }] = useLazyQuery(FETCH_ORDER, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setDealId(currentOrderDetails?.order?.dealId);
            if (currentOrderDetails?.order?.dealId != null) {
                setIsDealPurchase(true);
                loadDealData();
            } else {
                setDealName(null);
                setIsDealPurchase(false);
            }
        },
    });

    const [loadDealData, { data: dealData, loading: dealLoading }] = useLazyQuery(
        FETCH_DEAL_BY_ID,
        {
            variables: { dealId: dealId },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: () => {
                setDealName(dealData?.deal?.dealName);
            },
        },
    );

    useEffect(() => {
        if (currentOrderID) {
            loadOrderData({
                variables: {
                    orderId: currentOrderID,
                },
            });
        }
    }, [currentOrderID, loadOrderData]);

    useEffect(() => {
        if (loading) {
            setLoadingViewOne(true);
        }
    }, [loading, setLoadingViewOne]);

    return (
        <Modal
            open={showVerifyModal}
            onClose={() => {
                setShowVerifyModal(false);
            }}
        >
            <div
                className={`flex  py-5  rounded-t  ${
                    currentOrderDetails?.order?.orderStatus === PROCESSING_ORDERS
                        ? 'bg-yellow-100'
                        : currentOrderDetails?.order?.orderStatus === COMPLETE_ORDERS
                        ? 'bg-green-100'
                        : currentOrderDetails?.order?.orderStatus === CANCELLED_ORDERS
                        ? 'bg-red-100'
                        : currentOrderDetails?.order?.orderStatus === REFUNDED_ORDERS
                        ? 'bg-gray-100'
                        : null
                }`}
            >
                <div className="flex px-10">
                    <div className="text-xl font-poppins font-semibold flex">
                        Order {currentOrderDetails?.order?.transactionId}
                    </div>
                    <div
                        className={`text-xl font-poppins ml-10 font-semibold  flex  ${
                            currentOrderDetails?.order?.orderStatus === PROCESSING_ORDERS
                                ? 'text-yellow-500'
                                : currentOrderDetails?.order?.orderStatus === COMPLETE_ORDERS
                                ? 'text-green-500'
                                : currentOrderDetails?.order?.orderStatus === CANCELLED_ORDERS
                                ? 'text-red-500'
                                : currentOrderDetails?.order?.orderStatus === REFUNDED_ORDERS
                                ? 'text-gray-500'
                                : null
                        }`}
                    >
                        {currentOrderDetails?.order?.orderStatus}
                    </div>
                </div>
            </div>

            <div className="relative py-3 border-b-2">
                <div className="flex flex-row justify-between px-10">
                    <div>
                        <div className="font-poppins font-bold mb-2">User ID</div>
                        <div className="font-poppins text-gray-400 mb-11">
                            {currentOrderDetails?.order?.gamerId}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Display Name</div>
                        <div className="font-poppins text-gray-400 mb-11">
                            {currentOrderDetails?.order?.gamerName}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Gamer Email</div>
                        <div className="font-poppins text-gray-400 mb-11">
                            {currentOrderDetails?.order?.gamerEmail}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-10">
                    <div>
                        <div className="font-poppins font-bold mb-2">Date</div>
                        <div className="font-poppins text-gray-400 mb-3">
                            {currentOrderDetails?.order?.orderDate?.slice(0, 16) + '(UTC)'}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Purchase Channel</div>
                        <div className="font-poppins text-gray-400 mb-3">
                            {currentOrderDetails?.order?.purchasePaymentChannelName ||
                                currentOrderDetails?.order?.purchaseMedium}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Content Type</div>
                        <div className="font-poppins text-gray-400 mb-3">
                            {currentOrderDetails?.order?.contentType}
                        </div>
                    </div>
                    <div>
                        <div className="font-poppins font-bold mb-2">Purchase Currency</div>
                        <div className="font-poppins text-gray-400 mb-3">
                            {currentOrderDetails?.order?.purchasePaymentChannelName == 'POINTS' ||
                            currentOrderDetails?.order?.purchaseMedium == 'POINTS' ? (
                                'PTS'
                            ) : (
                                <div className="flex items-center">
                                    <div>{currentOrderDetails?.order?.targetCurrency}</div>
                                    <i
                                        className={`${currencyAndCountryCodes
                                            .find(
                                                (x) =>
                                                    x.currency ===
                                                    currentOrderDetails?.order?.targetCurrency,
                                            )
                                            ?.country.toLowerCase()} flag pl-1`}
                                    ></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative py-3 flex flex-col justify-between border-b-2 my-7">
                <div className="flex px-10 justify-between">
                    <div className="w-4/12">
                        <div className="font-poppins font-bold mb-4">Item Id</div>
                        <div className="font-poppins text-gray-400 mb-11 w-11/12">
                            {currentOrderDetails?.order?.item?.itemId}
                        </div>
                    </div>
                    <div className="w-4/12">
                        <div className="font-poppins font-bold mb-4">Item Name</div>
                        <div className="font-poppins text-gray-400 mb-11 w-11/12">
                            {currentOrderDetails?.order?.item?.itemName}
                        </div>
                    </div>

                    <div className="w-2/12">
                        <div className="font-poppins font-bold mb-4 text-center">Quantity</div>
                        <div className="font-poppins text-gray-400 mb-11 text-center">
                            {currentOrderDetails?.order?.quantity}
                        </div>
                    </div>
                    <div className="w-2/12" hidden={!isDealPurchase}>
                        <div className="font-poppins font-bold mb-4 text-center">Price</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentOrderDetails?.order?.totalPoints?.toFixed(2) ||
                                currentOrderDetails?.order?.markedUpPrice?.toFixed(2)}
                        </div>
                    </div>
                    <div className="w-2/12" hidden={!isDealPurchase}>
                        <div className="font-poppins font-bold mb-4 text-center">Deal</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentOrderDetails?.order?.dealPercentage}% {dealName}
                        </div>
                    </div>
                    <div className="w-2/12">
                        <div className="font-poppins font-bold mb-4 text-center">Total</div>
                        <div className="font-poppins text-gray-400 mb-3 text-center">
                            {currentOrderDetails?.order?.dealValue ||
                                currentOrderDetails?.order?.totalPoints?.toFixed(2) ||
                                currentOrderDetails?.order?.markedUpPrice?.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-end mx-4 my-10">
                <Buttons
                    name="Close"
                    type="submit"
                    buttonType="secondary-main-grey"
                    id="order-close-popup-button"
                    size="small"
                    onclick={() => {
                        setShowVerifyModal(false);
                    }}
                />
            </div>
        </Modal>
    );
}
